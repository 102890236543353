.u-display {
  &-block { display: block }
  &-flex { display: flex; }
  &-inline-block { display: inline-block; }
  &-inline { display: inline; }
  &-none { display: none; }
  &-table { display: table; }
}

.u-cf {
  @include clearfix();
}

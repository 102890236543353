.c-main-block__col {
  @include col(7/12);
  position: relative;
}

.c-main-block__content-col {
  @include col(5/12);
  position: relative;
}

.c-main-block__col--right {
  float: right;
}

.c-main-block {
  @include size(500px);
  float: right;
  @include bg();
  background-color: $dusty-gray;
  position: relative;
  box-shadow: -31.4951px 20.9967px 94.4852px 0 rgba(0, 0, 0, 0.25);
}

.c-main-block__main-title {
  position: absolute;
  top: 50%;
  right: -40px;
  font-family: $font-serif;
  line-height: 200px;
  font-size: 200px;
  font-weight: 300;
  letter-spacing: -0.02em;
  color: darken($dusty-gray, 10%);
  transform: translateY(-50%);
}

.c-main-block__subtitle {
  position: absolute;
  bottom: 12px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 22px;
  text-transform: uppercase;
  color: darken($dusty-gray, 10%);
  letter-spacing: 0.04em;
}

.c-main-block--olive {
  background-color: $olive;

  .c-main-block__main-title {
    color: darken($olive, 15%);
  }

  .c-main-block__subtitle {
    color: darken($olive, 15%);
  }
}

@include mq-max(1100px) {
  .c-main-block__main-title {
    right: 60px;
  }
}

@include mq-max(1023px) {
  .c-main-block {
    @include size(370px);
  }

  .c-main-block__main-title {
    font-size: 120px;
    line-height: 120px;
  }

  .c-main-block__subtitle {
    font-size: 18px;
  }
}

@include mq-max(767px) {
  .c-main-block__col--right {
    margin-bottom: 50px;
  }
}

@include mq-max(640px) {
  .c-main-block__content-col {
    padding-right: 50px;
  }
}

@include mq-max(560px) {
  .c-main-block__col, .c-main-block__col--right {
    width: 100%;
  }

  .c-main-block__subtitle {
    font-size: 14px;
  }

  .c-main-block {
    width: 100%;
    position: relative;
  }

  .c-main-block__content-col {
    width: 100%;
    padding: 0;
    margin-top: 40px;
  }

  .c-main-block__main-title {
    right: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/* Utility classes */

.u-button-content {
  padding: 15px 0;
}

.u-no-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.u-text-center {
  text-align: center !important;
}

.u-text-right {
  text-align: right !important;
}

.u-text-uppercase {
  text-transform: uppercase !important;
}

.u-ov-hidden {
  overflow: hidden;
}

.u-visual-hide {
  @include visual-hide();
}


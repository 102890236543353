.c-curiosity-modal {
  position: fixed;
  top: 0;
  left: 0;
  @include size(100%);
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.c-curiosity-modal__layer {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
  background-color: rgba($black, 0.7);
  opacity: 0;
  cursor: pointer;
  z-index: 10;
}

.c-curiosity-modal__body {
  @include align();
  width: 860px;
  margin: 0 auto;
  opacity: 0;
  padding: 40px;
  background-color: #EDE8DF;
  box-shadow: -31.5034px 21.0023px 94.5102px rgba(0, 0, 0, 0.25);
  z-index: 20;
}

.c-curiosity-modal__close-container {
  position: absolute;
  top: 20px;
  opacity: 0;
  right: 5%;
  z-index: 10;
}

.c-curiosity-modal__close {
  @include size(55px);
  border: 2px solid $dusty-gray;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    .c-curiosity-modal__close-circle {
      transform: translate(-50%, -50%) scale(1);
    }

    .c-curiosity-modal__close-text {
      color: $white;
    }
  }
}

.c-curiosity-modal__close-text {
  @include align();
  width: 100%;
  height: 22px;
  text-align: center;
  color: $dusty-gray;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.05em;
  transition: color 0.3s;
  z-index: 2;
}

.c-curiosity-modal__close-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  @include size(110%);
  border-radius: 50%;
  background-color: $dusty-gray;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.3s ease(snappy);
  z-index: 1;
}

.c-curiosity-modal__content {
  padding-top: 50px;
  opacity: 0;
  width: 100%;
}

.c-curiosity-modal__title {
  line-height: 25px;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #88725D;
  margin-bottom: 22px;
}

.c-curiosity-modal__text {
  line-height: 28px;
  font-size: 20px;
  letter-spacing: 0.05em;
  color: #C7B7A6;
}

@include mq-max(1023px) {
  .c-curiosity-modal__body {
    width: 95%;
    padding: 20px 12px;
  }

  .c-curiosity-modal__text {
    line-height: 23px;
    font-size: 14px;
  }

  .c-curiosity-modal__title {
    font-size: 16px;
    margin-bottom: 12px;
  }
}

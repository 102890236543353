.o-assistance-block {
  padding: 40px 0;
  position: relative;
  background-color: #EDE8DF;
  margin-top: 80px;
}

.o-assistance-block__phone-block-zone, .o-assistance-block__content-zone {
  @include col(1/2);
  position: relative;
  z-index: 10;
}

.o-assistance-block__content-zone {
  @include shift(1/2);
  padding-top: 30px;
}

.o-assistance-block__phone-block-zone {
  @include shift(-1/2);
}

.o-assistance-block__phone-block {
  @include size(450px);
  background-color: #C7B7A6;
  position: relative;
  top: 70px;
  box-shadow: -31px 21px 94px rgba(0, 0, 0, 0.25);
}

.o-assistance-block__ill {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 80%;
  height: 100%;
  pointer-events: none;
  user-select: none;
  z-index: 1;
  svg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 90%;
  }
}

.o-assistance-block__phone-block-content {
  @include align();
  width: 80%;
  text-align: center;
}

.o-assistance-block__phone-block-icon {
  margin-bottom: 16px;
  svg {
    display: block;
    margin: 0 auto;
    @include size(200px);
  }
}

.o-assistance-block__phone-block-subtitle {
  line-height: 32px;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-weight: bold;
  color: $brown;
  margin-bottom: 8px;
}

.o-assistance-block__phone-block-title {
  display: block;
  line-height: 58px;
  font-size: 60px;
  text-align: center;
  color: $brown;
}

@include mq-max(1023px) {
  .o-assistance-block__phone-block {
    @include size(350px);
  }

  .o-assistance-block__phone-block-title {
    line-height: 28px;
    font-size: 30px;
  }
}

@include mq-max(767px) {
  .o-assistance-block__content-zone, .o-assistance-block__phone-block-zone {
    width: 100%;
    @include unshift();
    position: relative;
  }

  .o-assistance-block__phone-block {
    top: 20px;
    width: 100%;
    margin-bottom: 40px;
    box-shadow: none;
  }

  .o-assistance-block {
    padding: 30px 0;
    margin-top: 0;
  }
}

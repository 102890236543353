.o-arrow-cta__container {
  margin-top: 28px;
}

.o-arrow-cta {
  display: inline-block;
  position: relative;
  cursor: pointer;

  &:hover {
    .o-arrow-cta__text {
      &:before {
        transform: scaleX(1);
        transform-origin: 0 100%;
      }
    }

    .o-arrow-cta__icon {
      transform: translateX(6px);
    }
  }
}

.o-arrow-cta__text {
  display: inline-block;
  font-size: 20px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-weight: bold;
  color: $brown;
  position: relative;
  z-index: 1;
  &:before {
    content: "";
    position: absolute;
    bottom: 3px;
    left: 15%;
    width: 100%;
    height: 14px;
    transform: scaleX(0);
    background-color: mix($earth, $white, 43%);
    transform-origin: 100% 100%;
    transition: transform 0.3s ease(snappy);
    z-index: -1;
  }
}

.o-arrow-cta__icon {
  display: inline-block;
  margin-left: 12px;
  position: relative;
  transition: transform 0.3s ease(snappy);
  z-index: 2;
  svg {
    width: 90px;
  }
}

.o-arrow-cta--light {
  .o-arrow-cta__text {
    color: $white;

    &:before {
      background-color: mix($dust, $white, 40%);
    }
  }

  .o-arrow-cta__icon {
    svg path {
      fill: $dust;
    }
  }
}

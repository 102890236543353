.o-quote {
  width: 960px;
  margin: 0 auto;
  position: relative;
  padding: 40px 0;
  text-align: center;
  margin: 80px auto;
  border: 0;
  quotes: "\201C""\201D""\2018""\2019";

  &:before {
    position: absolute;
    top: -40px;
    left: 0;
    color: $earth;
    content: open-quote;
    font-family: $font-serif;
    line-height: 1;
    font-size: 270px;
  }

  &:after {
    position: absolute;
    bottom: -60px;
    right: 0;
    color: $earth;
    content: close-quote;
    font-family: $font-serif;
    line-height: 199px;
    font-size: 270px;
  }
}

.o-quote__text {
  line-height: 48px;
  font-size: 34px;
  letter-spacing: 0.04em;
  color: #646464;
  width: 70%;
  margin: 0 auto;
}

.o-quote__author {
  line-height: 23px;
  font-size: 18px;
  letter-spacing: 0.04em;
  font-weight: bold;
  font-style: italic;
  margin-top: 8px;
  color: #9E8B8B;
}

@include mq-max(875px) {
  .o-quote {
    margin: 40px 0;
    padding: 40px 0;
    &:before, &:after {
      content: ''
    }
  }

  .o-quote__text {
    font-size: 26px;
    line-height: 36px;
  }
}

.c-jobs-modal {
  position: fixed;
  top: 0;
  left: 0;
  @include size(100%);
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.c-jobs-modal__bg, .c-jobs-modal__bg-layer {
  position: absolute;
  top: 100%;
  left: 0;
  @include size(100%);
}

.c-jobs-modal__bg {
  background-color: $white;
  z-index: 10;
}

.c-jobs-modal__bg-layer {
  background-color: $earth;
  z-index: 1;
}

.c-jobs-modal__close-container {
  position: absolute;
  top: 70px;
  opacity: 0;
  right: 5%;
  z-index: 10;
}

.c-jobs-modal__close {
  @include size(55px);
  border: 2px solid $dusty-gray;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    .c-jobs-modal__close-circle {
      transform: translate(-50%, -50%) scale(1);
    }

    .c-jobs-modal__close-text {
      color: $white;
    }
  }
}

.c-jobs-modal__close-text {
  @include align();
  width: 100%;
  height: 22px;
  text-align: center;
  color: $dusty-gray;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.05em;
  transition: color 0.3s;
  z-index: 2;
}

.c-jobs-modal__close-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  @include size(110%);
  border-radius: 50%;
  background-color: $dusty-gray;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.3s ease(snappy);
  z-index: 1;
}

.c-jobs-modal__content {
  @include align();
  width: 800px;
  opacity: 0;
  z-index: 3;
}

.c-jobs-modal-form__row {
  width: 100%;
  margin-bottom: 20px;
  @include clearfix();
}

.c-jobs-modal-form__col {
  &.x2 {
    @include col(1/2);
  }
}

.c-jobs-modal__main-title {
  line-height: 57px;
  font-size: 50px;
  text-align: center;
  margin-bottom: 46px;
  font-family: $font-serif;
  color: #88725D;
}

.c-jobs-modal__input {
  display: block;
  background: transparent;
  position: relative;
  width: 100%;
  border: none;
  border-bottom: 2px solid $brown;
  padding: 12px 20px;
  color: #646464;
  font-size: 16px;
  transition: all 0.3s;
  z-index: 1;

  @include placeholder() {
    text-transform: uppercase;
    font-size: 16px;
    color: #919191;
    font-weight: 600;
    letter-spacing: 0.05em;
  }

  &:focus {
    outline: none;
    border-color: darken($brown, 20%);
  }
}

.c-jobs-modal__input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  + label {
    border-bottom: 2px solid $brown;
    color: $brown;
    background-color: $white;
    width: 100%;
    text-transform: uppercase;
    font-size: 16px;
    color: #919191;
    font-weight: 600;
    padding: 12px 20px;
    letter-spacing: 0.05em;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    span {
      pointer-events: none;
    }
  }
}

.c-jobs-modal-form__cta {
  float: right;
}

.c-jobs-modal__response-title {
  font-size: 40px;
  font-weight: bold;
  font-family: $font-serif;
  text-align: center;
  color: $brown;
}

.c-jobs-modal__response-text {
  font-size: 18px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #646464;
}

.c-jobs-modal__loading-overlay, .c-jobs-modal__response-overlay {
  @include size(100%);
  position: absolute;
  top: 0;
  left: 0;
  background-color: $white;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: all 0.3s;
}

.is-jobs-loading {
  .c-jobs-modal__loading-overlay {
    opacity: 1;
    visibility: visible;
    z-index: 10;
  }
}

.is-jobs-success, .is-jobs-error {
  .c-jobs-modal__response-overlay {
    opacity: 1;
    visibility: visible;
    z-index: 11;
  }
}

@include mq-max(860px) {
  .c-jobs-modal__content {
    width: 600px;
  }

  .c-jobs-modal-form__col {
    margin-bottom: 12px;
    &.x2 {
      width: 100%;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@include mq-max(660px) {
  .c-jobs-modal__content {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .c-jobs-modal__step-control {
    width: 100%;
  }

  .c-jobs-modal__main-title {
    line-height: 20px;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .c-jobs-modal__input {
    font-size: 14px;
    padding: 8px 14px;
    @include placeholder() {
      font-size: 14px;
    }
  }

  .c-jobs-modal-form__cta {
    float: none;
  }
}

@include mq-max(600px) {
  .c-jobs-modal__close-container {
    top: 5%;
  }

  .c-jobs-modal__main-step-container {
    margin-top: 25px;
  }

  .c-jobs-modal__textarea {
    min-height: 110px;
  }

  .c-jobs-modal__main-step {
    min-height: 170px;
  }

  .c-jobs-modal__main-submit {
    .c-button-round {
      @include size(75px);
    }
  }

  .c-jobs-modal__input {
    font-size: 16px;
  }

  .c-jobs-modal__textarea {
    font-size: 16px;
  }
}

@include mq-max(480px) {
  .c-jobs-modal__main-step-title {
    font-size: 25px;
    line-height: 35px;
  }

  .c-jobs-modal__step {
    margin-right: 24px;
  }

  .c-jobs-modal__main-step-choice {
    width: 100%;
    text-align: left;
    padding-left: 32%;
  }
}

// Media Query Mixins
@mixin mq($minWidth, $maxWidth) {
  @media only screen and (min-width: em($minWidth)) and (max-width: em($maxWidth)) {
    @content;
  }
}

@mixin mq-max($maxWidth) {
  @media only screen and (max-width: em($maxWidth)) {
    @content;
  }
}

@mixin mq-min($minWidth) {
  @media only screen and (min-width: em($minWidth)) {
    @content;
  }
}

@mixin mq-h($minHeight, $maxHeight) {
  @media only screen and (min-height: em($minHeight)) and (max-height: em($maxHeight)) {
    @content;
  }
}

@mixin mq-max-h($maxHeight) {
  @media only screen and (max-height: em($maxHeight)) {
    @content;
  }
}

@mixin mq-min-h($minHeight) {
  @media only screen and (min-height: em($minHeight)) {
    @content;
  }
}

// HiDPI mixin
@mixin mq-hidpi($ratio: 2) {
  @media only screen and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (min--moz-device-pixel-ratio: $ratio),
  only screen and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (min-resolution: round($ratio * 96dpi)),
  only screen and (min-resolution: $ratio * 1dppx) {
    @content;
  }
}

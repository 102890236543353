.c-org-modal {
  position: fixed;
  top: 0;
  left: 0;
  @include size(100%);
  visibility: hidden;
  z-index: -1;
}

.c-org-modal__layer {
  position: absolute;
  top: 0;
  left: -100%;
  @include size(100%);
}

.c-org-modal__layer--first {
  background-color: $white;
}

.c-org-modal__layer--last {
  background-color: $red;
}

.c-org-modal__close-container {
  position: absolute;
  top: 50px;
  right: 7%;
  opacity: 0;
  z-index: 12;
}

.c-org-modal__close {
  @include size(55px);
  border: 2px solid $brown;
  border-radius: 50%;
  background-color: $white;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    .c-org-modal__close-circle {
      transform: translate(-50%, -50%) scale(1);
    }

    .c-org-modal__close-text {
      color: $white;
    }
  }
}

.c-org-modal__close-text {
  @include align();
  width: 100%;
  height: 22px;
  text-align: center;
  color: $brown;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.05em;
  transition: color 0.3s;
  z-index: 2;
}

.c-org-modal__close-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  @include size(110%);
  border-radius: 50%;
  background-color: $brown;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.3s ease(snappy);
  z-index: 1;
}

.c-org-modal__container {
  @include size(95%, 85%);
  position: relative;
  top: 10%;
  margin: 0 auto;
  z-index: 10;
}

.c-org-bubble {
  position: absolute;
  @include size(140px);
  border-radius: 50%;
  background-color: #E0D8D0;
  opacity: 0;
  transform: scale(0);
  cursor: pointer;
  box-shadow: -15px 20px 35px rgba(0, 0, 0, 0.2);

  &:hover {
    .c-org-bubble__after {
      transform: scale(1);
      opacity: 1;
    }
  }

  &.is-ready {
    transition: all 0.3s;
  }

  &.is-open {
    @include size(300px);
    z-index: 101 !important;
    .c-org-bubble__text {
      opacity: 0;
    }
    .c-org-bubble__expanded {
      transform: scale(1);
      opacity: 1;
    }

    .c-org-bubble__main-title, .c-org-bubble__title, .c-org-bubble__claim {
      opacity: 1;
    }
  }
}

.c-org-bubble__after {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
  background-color: $olive;
  border-radius: 50%;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s ease(snappy);
  z-index: 1;
}

.c-org-bubble__expanded {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
  background-color: $olive;
  border-radius: 50%;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s ease(snappy);
  z-index: 2;
}

.c-org-bubble--center {
  top: calc(50% - 110px);
  left: calc(50% - 110px);
  background-color: $red;
  width: 220px !important;
  height: 220px !important;
}

.c-org-bubble__content, .c-org-bubble__expanded-content {
  @include align();
  width: 60%;
  text-align: center;
  z-index: 2;
}

.c-org-bubble__expanded-content {
  width: 83%;
}

.c-org-bubble__text, .c-org-bubble__main-title {
  color: $white;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  transition: all 0.3s;
}

.c-org-bubble__main-title {
  opacity: 0;
}

.c-org-bubble__title {
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  font-weight: bold;
  color: #FFFFFF;
  margin-top: 12px;
  opacity: 0;
  transition: all 0.3s;
}

.c-org-bubble__claim {
  line-height: 18px;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  opacity: 0;
  transition: all 0.3s;
}

.c-org-bubble--ass {
  bottom: 0;
  right: 18%;
}

.c-org-bubble--com {
  right: 2.5%;
  bottom: 19%;
}

.c-org-bubble--mkt {
  right: 25%;
  bottom: 27%;
}

.c-org-bubble--dsll {
  top: 41%;
  right: 26%;
}

.c-org-bubble--rdd {
  right: 3%;
  top: 36%;
}

.c-org-bubble--aq {
  right: 15%;
  top: 24%;
}

.c-org-bubble--mag {
  top: 9%;
  right: 0;
}

.c-org-bubble--ocs {
  top: 1%;
  right: 38%;
}

.c-org-bubble--haccp {
  top: 7%;
  right: 44%;
}

.c-org-bubble--hcpp {
  top: 26%;
  left: 13%;
}

.c-org-bubble--bar {
  bottom: 2%;
  left: 48%;
}

.c-org-bubble--ard {
  left: 24%;
  bottom: 0%;
}

.c-org-bubble--cgv {
  left: 24%;
  bottom: 48%;
}

.c-org-bubble--acq {
  left: 6%;
  bottom: 26%;
}

.c-org-bubble--dsa {
  bottom: 40%;
  left: 24%;
}

.c-org-bubble--rsppra {
  left: 5%;
  bottom: 38%;
}

.c-org-bubble--teamhccp {
  top: 18%;
  left: 31%;
}

.c-org-bubble--rs {
  top: 1%;
  left: 45%;
}

.c-org-bubble--amm {
  top: 0;
  left: 18%;

  .c-org-bubble__text {
    overflow-wrap: break-word;
  }
}

.c-org-bubble--com {
  .c-org-bubble__text {
    overflow-wrap: break-word;
  }
}

@include mq-max(767px) {
  .c-org-bubble {
    @include size(95px);

    .c-org-bubble__text {
      overflow-wrap: break-word;
    }
  }

  .c-org-bubble--center {
    top: calc(50% - 70px);
    left: calc(50% - 70px);
    width: 140px !important;
    height: 140px !important;
    z-index: 100;
  }

  .c-org-bubble__text, .c-org-bubble__main-title {
    font-size: 12px;
  }

  .c-org-modal__close-container {
    top: 25px;
  }

  .c-org-bubble--ocs {
    right: 22%;
  }

  .c-org-bubble--amm {
    left: 9%;
  }

  .c-org-bubble--cgv {
    left: 10%;
    bottom: 53%;
  }

  .c-org-bubble--ass {
    right: 8%;
  }

  .c-org-bubble--ard {
    left: 15%;
  }
}

@include mq-max(540px) {
  .c-org-bubble--amm {
    left: 5%;
  }

  .c-org-bubble--rs {
    top: -2%;
    left: 31%;
  }

  .c-org-bubble--hcpp {
    top: 23%;
    left: 4%;
  }

  .c-org-bubble--teamhccp {
    top: 23%;
    left: 25%;
  }

  .c-org-bubble__text {
    font-size: 14px;
  }

  .c-org-bubble--aq {
    right: 0%;
    top: 33%;
  }

  .c-org-bubble--teamhccp {
    top: 18%;
    left: 29%;
  }

  .c-org-bubble--haccp {
    top: 20%;
    right: 38%;
  }

  .c-org-bubble--rdd {
    right: 0%;
    top: 45%;
  }

  .c-org-bubble--rsppra {
    left: 0%;
    bottom: 43%;
  }

  .c-org-bubble--acq {
    left: 0;
    bottom: 64%;
  }

  .c-org-bubble--dsa {
    bottom: 29%;
    left: 16%;
  }

  .c-org-bubble--com {
    right: 0;
    bottom: 21%;
  }

  .c-org-bubble--mkt {
    right: 31%;
    bottom: 12%;
  }

  .c-org-bubble--ard {
    left: 7%;
    bottom: 0;
  }

  .c-org-bubble--dsll {
    top: 62%;
    right: 26%;
  }

  .c-org-bubble--ass {
    bottom: -3%;
    right: 0;
  }

  .c-org-bubble--bar {
    bottom: 17%;
    left: 32%;
  }

  .c-org-bubble--mkt {
    right: 32%;
    bottom: -1%;
  }

  .c-org-bubble--cgv {
    left: 4%;
    bottom: 30%;
  }

  .c-org-bubble__content, .c-org-bubble__expanded-content {
    width: 80%;
  }

  .c-org-bubble__text {
    font-size: 12px;
  }

  .c-org-bubble {
    &.is-open {
      top: 50%;
      left: 50%;
      z-index: 101 !important;
      transform: translate(-50%, -50%) !important;
    }
  }
}

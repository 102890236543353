.o-curiosity-point {
  @include size(30px);
  border: 7px solid $red;
  border-radius: 50%;
  cursor: pointer;
}

.o-curiosity-point--home-coffee {
  position: absolute;
  top: 130px;
  right: 52%;
  z-index: 10;
}

.o-curiosity-point--home-2-coffee {
  position: absolute;
  top: 39%;
  right: 0;
  z-index: 10;
}

.o-curiosity-point--chi-siamo-coffee {
  position: absolute;
  top: 30%;
  left: 62%;
  z-index: 10;
}

.o-curiosity-point--azienda-coffee {
  position: absolute;
  bottom: 0;
  left: 49%;
  z-index: 10;
}

.o-curiosity-point--azienda-2-coffee {
  position: absolute;
  bottom: 0;
  left: 49%;
  z-index: 10;
}

.o-curiosity-point--sede-coffee {
  position: absolute;
  top: 33%;
  left: 42%;
  z-index: 10;
}

.o-curiosity-point--soluzioni-coffee {
  position: absolute;
  top: 19%;
  left: 15%;
  z-index: 10;
}
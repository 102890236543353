.c-hero {
  width: 100%;
  height: 79vh;
  margin-top: 83px;
  position: relative;
  transition: height 0.3s;
}

.c-hero__mask {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
  overflow: hidden;
  z-index: 1;
}

.c-hero__image {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
  @include bg();
  background-color: $bonjour;
  z-index: 1;
}

.c-hero__float-content {
  position: absolute;
  bottom: -40px;
  right: 10%;
  padding: 45px;
  background-color: $dusty-gray;
  max-width: 480px;
  transition: transform 0.3s ease(snappy);
  backface-visibility: hidden;
  z-index: 2;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    background-color: $white;
    transform: scaleX(0);
    transition: transform 0.3s ease(snappy);
    transform-origin: 100% 100%;
  }

  &:hover {
    transform: scale(1.03) skewX(-2deg);

    &:after {
      transform: scaleX(1);
      transform-origin: 0 100%;
    }

    .c-hero__float-content-cta {
      &:before, &:after {
        transform: scale(1);
      }

      &:after {
        transition-delay: 0.25s;
      }

      svg path {
        fill: $white;
      }
    }
  }
}

.c-hero__float-content-shadow {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
  box-shadow: -31.4951px 20.9967px 94.4852px 0 rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.c-hero__float-content--pink {
  background-color: $pink;

  .c-hero__float-content-cta {
    svg path {
      fill: $pink;
    }
  }

  .c-hero__float-content-cta {
    &:before {
      background-color: $pink;
    }
    &:after {
      background-color: darken($pink, 10%);
    }
  }

  &:hover {
    .c-hero__float-content-title, .c-hero__float-content-text {
      color: $pink;
    }
  }
}

.c-hero__float-content--brown {
  background-color: $brown;

  .c-hero__float-content-cta {
    svg path {
      fill: $brown;
    }
  }

  .c-hero__float-content-cta {
    &:before {
      background-color: $brown;
    }
    &:after {
      background-color: darken($brown, 10%);
    }
  }

  &:hover {
    .c-hero__float-content-title, .c-hero__float-content-text {
      color: $brown;
    }
  }
}

.c-hero__float-content-title {
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 0.04em;
  margin-bottom: 22px;
  color: $white;
  position: relative;
  transition: color 0.3s;
  z-index: 2;
  font-weight: normal;
}

.c-hero__float-content-title--small {
  font-size: 40px;
  line-height: 40px;
}

.c-hero__float-content-text {
  line-height: 28px;
  font-size: 22px;
  letter-spacing: 0.02em;
  color: $white;
  position: relative;
  transition: color 0.3s;
  z-index: 2;
}

.c-hero__float-content-text--small {
  line-height: 30px;
  font-size: 18px;
}

.c-hero__float-content-cta {
  float: right;
  @include size(40px);
  background-color: $white;
  border-radius: 50%;
  position: absolute;
  overflow: hidden;
  transition: background-color 0.35s;
  z-index: 2;
  bottom: 25px;
  right: 25px;

  &:before, &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.3s ease(snappy) 0s;
    z-index: 1;
  }

  svg {
    @include align();
    @include size(14px);
    z-index: 2;
    path {
      transition: all 0.3s;
    }
  }
}

.c-hero__bg {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
  @include bg();
  background-color: $bonjour;
  z-index: 1;
}

.c-hero__bg--red {
  background-color: $red;
}

.c-hero__bg--orange {
  background-color: #F7BD79;
}

.c-hero__bg--pink {
  background-color: $pink;
}

.c-hero__bg--green {
  background-color: $green;
}

.c-hero__bg--brown {
  background-color: $brown;
}

.c-hero__bg-light-brown {
  background-color: #A4917F;
}

.c-hero__bg--terra {
  background-color: #9B655E;
}

.c-hero__bg--olive {
  background-color: $olive;
}

.c-hero__bg--aqua {
  background-color: #78CFEB;
}

.c-hero__bg-content {
  width: 100%;
  position: relative;
  padding-top: 14vh;
  z-index: 3;
}

.c-hero__main-zone {
  @include col(5/12);
}

.c-hero__img-zone {
  @include col(7/12);
  position: relative;
}

.c-hero__img {
  width: 80%;
  height: 70vh;
  float: right;
  position: relative;
  @include bg();
  background-color: $dust;
  box-shadow: -31.5034px 21.0023px 94.5102px 0 rgba(0, 0, 0, 0.25);
}

.c-hero__project-logo {
  @include size(120px);
  position: absolute;
  top: 30px;
  right: 30px;
}

.c-hero__claim {
  line-height: 30px;
  font-size: 20px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-weight: bold;
  color: $fog;
}

.c-hero__title {
  font-family: $font-serif;
  line-height: 58px;
  font-size: 60px;
  color: $white;
  font-weight: normal;

  &--projects {
    font-size: 45px;
    line-height: 45px;
  }
}

.c-hero__main-subtext {
  margin-top: 52px;
  width: 80%;

  &--projects {
    margin-top: 40px;
  }
}

.c-hero-carousel {
  @include size(100%);
  position: relative;
}

.c-hero-carousel__slide {
  @include size(100%);
  position: relative;
}

.c-hero-carousel__slide-image {
  @include size(100%);
  @include bg();
  background-color: #ccc;
}

.c-hero__carousel-side {
  position: absolute;
  top: 0;
  @include size(50%, 100%);
  cursor: none;
  z-index: 10;
}

.c-hero__carousel-side--left {
  left: 0;
  cursor: url('data:image/svg+xml;utf8,<svg width="54" height="56" viewBox="0 0 54 56" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 37.387h38.668V0" transform="rotate(134.922 21.746 25.232)" stroke="#fff" stroke-width="1.05"/><path d="M53.594 27.947h-52.5" stroke="#fff" stroke-width="1.05"/></svg>'), auto;
}

.c-hero__carousel-side--right {
  left: 50%;
  cursor: url('data:image/svg+xml;utf8,<svg width="55" height="56" viewBox="0 0 55 56" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 37.387h38.668V0" transform="rotate(-45.078 34.48 14.107)" stroke="#fff" stroke-width="1.05"/><path d="M53.274 27.946H.774" stroke="#fff" stroke-width="1.05"/></svg>'), auto;
}

.c-hero-float-carousel {
  position: absolute;
  bottom: -40px;
  // right: 10%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0;
  width: 320px;
  height: 290px;
  max-width: 480px;
  transition: transform 0.3s ease(snappy);
  backface-visibility: hidden;
  z-index: 2;
}

.c-hero-float__slider, .c-hero-float__slide {
  width: 100%;
  height: 100%;
  .flickity-viewport {
    height: 100%;
  }
}

.c-hero-float__slider {
  transition: transform 0.3s ease(snappy);
  &:hover {
    transform: scale(1.03) skewX(-2deg);
  }

  .flickity-viewport {
    z-index: 2;
  }
}

.c-hero-float__slide {
  padding: 32px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    background-color: $white;
    transform: scaleX(0);
    transition: transform 0.3s ease(snappy);
    transform-origin: 100% 100%;
  }

  &:hover {
    &:after {
      transform: scaleX(1);
      transform-origin: 0 100%;
    }
    .c-hero__float-content-cta {
      &:before, &:after {
        transform: scale(1);
      }
    }
  }
}

.c-hero-float__slide--pink {
  background-color: $pink;
  .c-hero__float-content-cta {
    svg path {
      fill: $pink;
    }
  }

  .c-hero__float-content-cta {
    &:before {
      background-color: $pink;
    }
    &:after {
      background-color: darken($pink, 10%);
    }
  }
  &:hover {
    .c-hero__float-content-title, .c-hero__float-content-text {
      color: $pink;
    }
    svg path {
      fill: $white;
    }
  }
}

.c-hero-float__slide--orange {
  background-color: $orange;
  .c-hero__float-content-cta {
    svg path {
      fill: $orange;
    }
  }

  .c-hero__float-content-cta {
    &:before {
      background-color: $orange;
    }
    &:after {
      background-color: darken($orange, 10%);
    }
  }
  &:hover {
    .c-hero__float-content-title, .c-hero__float-content-text {
      color: $orange;
    }
    svg path {
      fill: $white;
    }
  }
}

.c-hero-float__slide--brown {
  background-color: $brown;
  .c-hero__float-content-cta {
    svg path {
      fill: $brown;
    }
  }

  .c-hero__float-content-cta {
    &:before {
      background-color: $brown;
    }
    &:after {
      background-color: darken($brown, 10%);
    }
  }
  &:hover {
    .c-hero__float-content-title, .c-hero__float-content-text {
      color: $brown;
    }
    svg path {
      fill: $white;
    }
  }
}

.c-hero-float__slide--green {
  background-color: $olive;
  .c-hero__float-content-cta {
    svg path {
      fill: $olive;
    }
  }

  .c-hero__float-content-cta {
    &:before {
      background-color: $olive;
    }
    &:after {
      background-color: darken($olive, 10%);
    }
  }
  &:hover {
    .c-hero__float-content-title, .c-hero__float-content-text {
      color: $olive;
    }
    svg path {
      fill: $white;
    }
  }
}

.c-hero__scroll-down-container {
  position: absolute;
  bottom: -35px;
  left: 2.5%;
  z-index: 99;
  transition: opacity 0.3s;
}

.c-hero__scroll-down {
  @include size(70px);
  border-radius: 50%;
  background-color: $white;
  text-align: center;
  box-shadow: -31.4951px 20.9967px 94.4852px 0 rgba(0, 0, 0, 0.25);
  transition: transform 0.3s ease(snappy);
  cursor: pointer;

  &:before, &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.3s ease(snappy) 0s;
    z-index: 1;
  }

  &:before {
    background-color: $brown;
  }

  &:after {
    background-color: darken($brown, 10%);
    transition-delay: 0.18s;
  }

  &:hover {
    transform: translateY(4px);
    &:before, &:after {
      transform: scale(1);
    }
    .c-hero__scroll-down-icon {
      transform: translate(-50%, -45%);
      svg path {
        fill: $white;
      }
    }
  }
}

.c-hero__scroll-down-icon {
  @include align();
  width: 80%;
  transition: transform 0.3s ease(snappy) 0.15s;
  z-index: 2;
  svg {
    @include size(30px);
    position: relative;
    top: 5px;
    path {
      transition: all 0.3s;
    }
  }
}

@include mq-max(1023px) {
  .c-hero__carousel-side {
    display: none;
  }
}

@include mq-max(997px) {
  .c-hero__title {
    font-size: 50px;
    line-height: 48px;
  }

  .c-hero__bg-content {
    padding-top: 5vh;
  }

  .c-hero__main-zone {
    width: 100%;
  }

  .c-hero__img-zone {
    width: 100%;
  }

  .c-hero__title {
    margin-bottom: 50px;
  }

  .c-hero__img {
    width: 70%;
    height: 50vh;
  }
}

@include mq-max(767px) {
  .c-hero {
    height: 69vh;
    position: relative;
    
  }

  .c-hero__img {
    width: 100%;
  }
  .c-hero__scroll-down-container {
    display: none;
  }

  .c-hero__float-content-title {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 16px;
  }

  .c-hero__float-content-text {
    line-height: 20px;
    font-size: 16px;
  }

  .c-hero-float-carousel {
    position: absolute;
    bottom: -40px;
    right: 10%;
    padding: 0;
    width: 70%;
    height: 280px;
    max-width: 480px;
    transition: transform 0.3s cubic-bezier(0.694, 0.048, 0.335, 1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 2;
  }

  .c-hero__title {
    font-size: 32px;
    line-height: 34px;
  }

  .c-hero__float-content {
    padding: 25px;
    &:hover {
      transform: none;
    }
  }

  .c-hero__float-content-title {
    font-size: 26px;
    line-height: 26px;
  }

  // .c-hero__img-zone {
  //   position: absolute;
  //   bottom: -80px;
  //   left: 10%;
  //   width: 80%;
  //   z-index: 1;
  // }

  .c-hero__bg-content {
    height: 100%;
  }

  .c-hero__main-zone {
    padding-bottom: 50px;
  }

  .c-hero__img-zone {
    position: relative;
    bottom: 0;
    left: 0;
  }

  .c-hero__img-zone--contacts {
    bottom: 0;
  }

  .c-hero--mobile-no-height {
    height: auto;
  }

  .c-hero--contacts {
    height: 67vh;

    .c-hero__title {
      margin-bottom: 20px;
    }

    .c-contacts__recap-title {
      margin-top: 25px !important;
    }
  }
}

@include mq-max(500px) {
  // .c-hero__main-zone {
  //   padding-bottom: 20vh;
  // }

  .c-hero__img {
    width: 100%;
    height: 40vh;
  }
}

/* BASE */

* {
  &, &:before, &:after { box-sizing: border-box; }
}

html, body {
  width: 100%;
  @if settings('sticky-footer') == false {
    height: 100%;
  }
  margin: 0;
  padding: 0;
}

// Sets the font-size of the project as a percentage. Making it easy to
// scale up/down the whole project for specific media queries
html {
  position: relative;
  font-size: ($base-font-size / 16px) * 100%;
  @if settings('sticky-footer') {
    min-height: 100%;
  }
}

body {
  background: $body-bg;
  color: $text-color;
  font-family: $font-stack;
   @include setLeading();
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden;
  @if settings('use-font-smooth') {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @if settings('sticky-footer') {
    margin: 0 0 $footer-height;
  }
}

// Selection colors
::selection {
  background: $selection-bg;
  color: $selection-color;
  text-shadow: none;
}

::-webkit-selection{
  background: $selection-bg;
  color: $selection-color;
  text-shadow: none;
}

img::selection {
  background: transparent;
}

main, section {
  width: 100%;
  display: block;
  position: relative;
  @include clearfix();
}

// CSS to JS Breakpoint Bridge
@if settings('set-mq-signature') {
  body:after {
    content: 'mobile';
    position: absolute;
    top: 0;
    left: 0;
    z-index: z(behind) - 2;
    visibility: hidden;
    width: 1px;
    height: 1px;
    overflow: hidden;
    display: block;
    @each $mq-key, $mq-value in $mq-breakpoints {
      @include mq-min($mq-value) {
        content: '#{$mq-key}';
      }
    }
  }
}

@include mq-max(767px) {
  main {
    overflow-x: hidden;
  }
}

.c-simple-carousel {
  width: 100%;
  position: relative;
  margin-top: 100px;
}

.c-simple-carousel__slide {
  width: 33.33333333%;
}

.c-simple-carousel__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 25px 12px;
  cursor: pointer;
  border: 2px solid rgba(136, 114, 93, 0.2);
  z-index: 10;

  svg {
    @include size(55px);
    position: relative;
    z-index: 10;
    path {
      stroke: $brown;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    background-color: $white;
    transform: scaleX(0);
    transition: transform 0.3s ease(snappy);
    transform-origin: 100% 100%;
  }

  &:hover {
    &:after {
      transform: scaleX(1);
      transform-origin: 0 100%;
    }
  }
}

.c-simple-carousel__arrow--prev {
  left: -100px;
}

.c-simple-carousel__arrow--next {
  right: -100px;
}

.c-simple-carousel__image {
  display: block;
  margin: 0 auto;
  max-height: 80px;
}

.c-simple-carousel__text {
  display: block;
  text-align: center;
  margin-top: 20px;
  color: rgba(100, 100, 100, 0.7);
}
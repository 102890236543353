.c-project-block__col {
  @include col(1/2, $gutter: 7);
}

.c-project-block__third {
  @include col(1/3, $cycle: 3);
  margin-bottom: 80px;
}

.c-project-block {
  width: 100%;
  position: relative;
}

.c-project-block__bg {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
  background-color: $dusty-gray;
  z-index: 1;
}

.c-project-block__image-zone {
  position: relative;
}

.c-project-block__image {
  @include size(80%, 400px);
  position: relative;
  top: -40px;
  left: 20%;
  background: #C4C4C4;
  box-shadow: -31.5034px 21.0023px 94.5102px rgba(0, 0, 0, 0.25);
  z-index: 2;
  @include bg;
}

.c-project-block__content-zone {
  width: 80%;
  position: relative;
  left: 20%;
  margin-top: 35px;
}

.c-project-block__title {
  line-height: 34px;
  font-size: 20px;
  letter-spacing: 0.05em;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 16px;
  color: #88725D;
}

.c-project-block__text {
  line-height: 30px;
  font-size: 18px;
  letter-spacing: 0.04em;
  color: #646464;
}

.c-project-block__cta {
  display: block;
  margin-top: 30px;
  &:hover {
    .c-project-block__cta-icon, .c-project-block__cta-text {
      transform: translateX(6px);
    }

    .c-project-block__cta-icon {
      transition-delay: 0.125s;
    }
  }
}

.c-project-block__cta-text {
  display: inline-block;
  font-size: 16px;
  letter-spacing: 0.04em;
  text-decoration: underline;
  text-transform: uppercase;
  font-weight: bold;
  color: #88725D;
  transition: transform 0.3s ease(snappy);
}

.c-project-block__cta-icon {
  display: inline-block;
  margin-left: 22px;
  @include size(40px);
  border-radius: 50%;
  background-color: $dusty-gray;
  position: relative;
  top: 12px;
  text-align: center;
  transition: transform 0.3s ease(snappy) 0s;
  svg {
    @include align();
  }
}

.c-projct-block__preview-image {
  @include size(300px);
  @include bg();
  background-color: #ccc;
  margin-bottom: 32px;
}

.c-project-block--right {
  .c-project-block__image {
    left: 0;
  }
  .c-project-block__content-zone {
    left: 0;
  }
}

.c-project-block--green {
  .c-project-block__bg, .c-project-block__cta-icon {
    background-color: #51BD89;
  }
}

.c-project-block--orange {
  .c-project-block__bg, .c-project-block__cta-icon {
    background-color: #F7BD79;
  }
}

.c-project-block--aqua {
  .c-project-block__bg, .c-project-block__cta-icon {
    background-color: #78CFEB;
  }
}

.c-project-block--terra {
  .c-project-block__bg, .c-project-block__cta-icon {
    background-color: #9B655E;
  }
}

.c-project-block--red {
  .c-project-block__bg, .c-project-block__cta-icon {
    background-color: $red;
  }
}


.c-project-preview-section {
  margin: 20px 0 60px 0;
  padding-bottom: 0;
}

.c-projects__navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #EDE8DF;
  box-shadow: -31.4951px 20.9967px 94.4852px 0 rgba(0, 0, 0, 0.25);
  z-index: 100;
}

.c-projects__navigation-button {
  float: left;
  width: 50%;
  padding: 20px 0;
  background-color: #EDE8DF;
  cursor: pointer;
}

.c-projects__navigation-button--left {
  position: relative;
  padding-left: 5%;
}

.c-projects__navigation-button--right {
  position: relative;
  padding-right: 5%;

  .c-projects__navigation-button-content {
    float: right;
    text-align: right;
  }
}

.c-projects__navigation-button-icon {
  display: inline-block;
  margin: 0 4px;
  position: relative;
  top: 10px;
  svg {
    @include size(40px);
  }
}

.c-projects__navigation-button-text {
  display: inline-block;
  margin: 0 4px;
  line-height: 30px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #88725D;
}

@include mq-max(980px) {
  .c-project-block__third {
    margin-bottom: 60px;
  }
  .c-projct-block__preview-image {
    @include size(250px);
    margin-bottom: 18px;
  }

  .c-project-block__title {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
  }

  .c-project-block__cta {
    margin-top: 10px;
  }

  .c-project-block__cta-icon {
    @include size(30px);
    margin-left: 16px;
  }

  .c-project-block__text {
    line-height: 24px;
    font-size: 16px;
  }
}

@include mq-max(790px) {
  .c-project-block__third {
    @include col(1/2, $uncycle: 3, $cycle: 2);
  }

  .c-project-block__content-zone {
    width: 90%;
    margin: 35px auto 0 auto;
    left: 0;
  }

  .c-project-block__col {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .c-project-preview-section {
    margin-bottom: 0;
  }
}

@include mq-max(570px) {
  .c-project-block__col {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 80px;
  }

  .c-project-block__third {
    width: 100%;
  }

  .c-projct-block__preview-image {
    width: 100%;
    height: 0;
    padding-bottom: 70%;
  }
}

.c-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $alabaster;
  padding: 10px 0;
  z-index: 999;

  .o-container {
    position: relative;
    z-index: 2;
  }

  &.is-scroll {
    .c-nav__logo {
      height: 44px;
      img {
        width: 50px;
      }
      svg {
        @include size(50px, 37px);
      }
    }

    .c-nav__main-links {
      transform: translateY(8px);
    }

    .c-nav__actions {
      transform: translateY(8px);
    }

    .c-nav__logo-letters {
      opacity: 0;
    }
  }

  @include mq-max(1023px) {
    &.is-scroll {
      .c-nav__shadow {
        opacity: 1;
      }
    }
  }
}

.c-nav__shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 20px 40px 0 rgba($black, 0.11);
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1;
}

.c-nav__logo {
  float: left;
  img {
    width: 72px;
    transition: all 0.3s;
  }
  svg {
    @include size(72px, 55px);
    transition: all 0.3s;
    path {
      transition: all 0.3s;
    }
  }
}

.c-nav__main-links {
  list-style: none;
  padding: 0;
  margin: 0;
  float: left;
  transform: translateY(21px);
  transition: transform 0.3s ease(snappy);
  margin-left: 40px;
}

.c-nav__main-items {
  display: inline-block;
  margin-right: 20px;
  position: relative;
  &:last-child {
    margin-right: 0;
  }
}

.c-nav__main-link {
  font-size: 14px;
  letter-spacing: 0.05em;
  color: $brown;
  text-transform: uppercase;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 100%;
    height: 6px;
    transform: scaleX(0);
    background-color: mix($earth, $white, 43%);
    transform-origin: 100% 100%;
    transition: transform 0.3s ease(snappy);
    z-index: -1;
  }

  &:hover {
    &:before {
      transform: scaleX(1);
      transform-origin: 0 100%;
    }
  }
}

.c-nav__main-link-icon {
  display: inline-block;
  margin-left: 4px;
  color: $brown;
}

.c-nav__main-link-submenu-area {
  position: absolute;
  top: 20px;
  left: 0;
  padding-top: 20px;
  visibility: hidden;
  transition: all 0.3s;
  opacity: 0;
  z-index: -1;
  &:hover {
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }
}

.c-nav__main-link-submenu {
  list-style: none;
  margin: 0;
  width: 150px;
  padding: 16px;
  background-color: $white;
  box-shadow: 0 20px 40px 0 rgba($black, 0.11);
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: -10px;
    left: 20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 10px 5px;
    border-color: transparent transparent $white transparent;
  }
}

.c-nav__main-link--has-submenu {
  &:hover {
    + .c-nav__main-link-submenu-area {
      opacity: 1;
      z-index: 1;
      visibility: visible;
    }
  }
}

.c-nav__main-link-submenu-item {
  display: block;
  margin-bottom: 2px;
  &:last-child {
    margin-bottom: 0;
  }
}

.c-nav__main-link-submenu-link {
  font-size: 14px;
  padding: 4px 0;
  color: $brown;
  position: relative;
  z-index: 10;
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px;
    transform: scaleX(0);
    background-color: mix($earth, $white, 43%);
    transform-origin: 100% 100%;
    transition: transform 0.3s ease(snappy);
    z-index: -1;
  }

  &:hover {
    &:before {
      transform: scaleX(1);
      transform-origin: 0 100%;
    }
  }
}

.c-nav__actions {
  float: right;
  position: relative;
  transform: translateY(21px);
  transition: transform 0.3s ease(snappy);
  margin-left: 40px;
}

.c-nav__action {
  display: inline-block;
  margin-right: 22px;
  &:last-child {
    margin-right: 0;
  }
}

.c-nav__action--mobile {
  display: none;
}

// .c-nav__toggle {
//   @include size(55px);
//   border-radius: 50%;
//   border: 1px solid $dusty-gray;
//   position: relative;
//   cursor: pointer;
//   overflow: hidden;

//   &:hover {
//     .c-nav__toggle-text-initial, .c-nav__toggle-text-reveal {
//       transform: translateY(-22px);
//     }

//     .c-nav__toggle-circle {
//       transform: translate(-50%, -50%) scale(1);
//     }

//     .c-nav__toggle-text-reveal {
//       color: $white;
//     }
//   }
// }

// .c-nav__toggle-circle {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   @include size(110%);
//   border-radius: 50%;
//   background-color: $dusty-gray;
//   transform: translate(-50%, -50%) scale(0);
//   transition: transform 0.3s ease(snappy);
//   z-index: 1;
// }

// .c-nav__toggle-text-container {
//   @include align();
//   width: 100%;
//   height: 22px;
//   text-align: center;
//   color: $brown;
//   overflow: hidden;
//   z-index: 2;
// }

// .c-nav__toggle-text-initial, .c-nav__toggle-text-reveal {
//   font-size: 13px;
//   font-weight: bold;
//   letter-spacing: 0.05em;
//   position: absolute;
//   top: 0;
//   left: 0;
//   @include size(100%);
//   transition: transform 0.3s ease(snappy), color 0.3s ease;
// }

// .c-nav__toggle-text-reveal {
//   top: 100%;
// }

.c-nav__action-bubble {
  @include size(35px);
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #80BC00;
  cursor: pointer;
  position: relative;
  top: -8px;
  transition: border-color 0.3s;
  svg {
    @include size(15px);
    @include align();
    z-index: 2;

    path {
      fill: #80BC00;
      transition: all 0.3s;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    @include size(110%);
    border-radius: 50%;
    background-color: #80BC00;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.3s ease(snappy);
    z-index: 1;
  }

  &:hover {
    .c-nav-toggle__bar {
      background-color: $white;
    }
    svg path {
      fill: $white;
    }
    &:after {
      transform: translate(-50%, -50%) scale(1);
    }
  }
  &--active {
    .c-nav-toggle__bar {
      background-color: $white;
    }
    svg path {
      fill: $white;
    }
    &:after {
      transform: translate(-50%, -50%) scale(1);
      background-color: $dusty-gray!important;
    }
  }

}

.c-nav__action-bubble--green {
  &:after {
    background-color: #80BC00;
  }
  &:hover {
    border-color: #80BC00;
  }
}

.c-nav__action-bubble--menu {
  background-color: #80BC00;
  border: none;
  .c-nav-toggle__bar {
    background-color: $white;
  }
}

.c-nav-menu {
  position: fixed;
  top: 0;
  left: 0;
  @include size(100%);
  visibility: hidden;
  z-index: -1;
  z-index: 999;
}

.c-nav-menu__bg {
  position: absolute;
  top: 100%;
  left: 0;
  @include size(100%);
  background-color: darken($brown, 25%);
  pointer-events: none;
  z-index: 2;
}

.c-nav-menu__image {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
  opacity: 0;
  @include bg();
  pointer-events: none;
  z-index: 1;
}

.c-nav-menu__side-content {
  position: absolute;
  top: 50%;
  right: 15%;
  transform: translateY(-50%);
  width: 200px;
  z-index: 2;
}

.c-nav-menu__side-address {
  font-size: 14px;
  margin-bottom: 28px;
  color: rgba($white, 0.7);
}

.c-nav-menu__side-link {
  display: inline-block;
  font-size: 16px;
  color: $white;
  font-weight: 900;
  font-family: $font-serif;
  position: relative;
  margin-bottom: 16px;
  letter-spacing: 1px;

  &:before {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 4px;
    transform: scaleX(0);
    background-color: $earth;
    transform-origin: 100% 100%;
    transition: transform 0.3s ease(snappy);
  }

  &:hover {
    &:before {
      transform: scaleX(1);
      transform-origin: 0 100%;
    }
  }
}

.c-nav-menu__side-social {
  list-style: none;
  padding: 0;
  margin: 20px 0 0 0;
}

.c-nav-menu__side-social-item {
  display: inline-block;
  margin-right: 16px;
  overflow: hidden;
  &:last-child {
    margin-right: 0;
  }
}

.c-nav-menu__side-social-link {
  display: block;
  @include size(18px);
  svg {
    @include size(18px);
    path {
      fill: $white;
      transition: all 0.3s;
    }
  }

  &:hover {
    svg path {
      fill: $earth;
    }
  }
}

.c-nav-menu__close-container {
  position: absolute;
  top: 70px;
  right: 15%;
  z-index: 2;
}

.c-nav-menu__close {
  @include size(55px);
  border: 2px solid $white;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    .c-nav__close-circle {
      transform: translate(-50%, -50%) scale(1);
    }

    .c-nav-menu__close-text {
      color: $brown;
    }
  }
}

.c-nav-menu__close-text {
  @include align();
  width: 100%;
  height: 22px;
  text-align: center;
  color: $white;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.05em;
  transition: color 0.3s;
  z-index: 2;
}

.c-nav__close-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  @include size(110%);
  border-radius: 50%;
  background-color: $white;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.3s ease(snappy);
  z-index: 1;
}

.c-nav-menu__list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translateY(-50%);
  width: 240px;
  z-index: 2;
}

.c-nav-menu__item {
  display: block;
  overflow: hidden;
  position: relative;
}

.c-nav-menu__link {
  display: inline-block;
  font-size: 30px;
  letter-spacing: 0.05em;
  color: $white;
  font-weight: bold;
  cursor: pointer;
  padding: 4px 0;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 100%;
    height: 8px;
    transform: scaleX(0);
    background-color: $earth;
    transform-origin: 100% 100%;
    transition: transform 0.3s ease(snappy);
  }

  &:hover {
    &:before {
      transform: scaleX(1);
      transform-origin: 0 100%;
    }
  }
}

.c-nav-menu__link--has-submenu {
  padding-right: 50px;
  display: block;
  &:before {
    width: 60%;
  }

  &:hover {
    .c-nav-menu__link-sub-icon {
      transform: rotate(180deg);
    }
    + .c-nav__submenu {
      visibility: visible;
      opacity: 1;
      z-index: 9999;

      .c-nav__submenu-link {
        transform: translateY(0%);
      }
    }
  }

  &.is-submenu-open {
    .c-nav-menu__link-sub-icon {
      transform: rotate(45deg);
    }
    &:before {
      transform: scaleX(1);
      transform-origin: 0 100%;
    }
  }
}

.c-nav-menu__link-sub-icon {
  position: absolute;
  top: 4px;
  right: 30px;
  transition: transform 0.3s ease(snappy);

  svg {
    @include size(30px);
    path {
      fill: $white;
    }
  }
}

.c-nav__submenu {
  position: absolute;
  top: 0;
  right: -96%;
  list-style: none;
  margin: 0;
  padding: 0;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s;

  &:hover {
    visibility: visible;
    opacity: 1;
    z-index: 9999;

    .c-nav__submenu-link {
      transform: translateY(0%);
    }
  }
}

.c-nav__submenu-item {
  display: block;
  overflow: hidden;
  position: relative;
  &:nth-child(2) {
    .c-nav__submenu-link {
      transition-delay: 0.1s;
    }
  }
  &:nth-child(3) {
    .c-nav__submenu-link {
      transition-delay: 0.15s;
    }
  }
  &:nth-child(4) {
    .c-nav__submenu-link {
      transition-delay: 0.2s;
    }
  }
}

.c-nav__submenu-link {
  display: inline-block;
  font-size: 30px;
  letter-spacing: 0.05em;
  color: $white;
  font-weight: bold;
  cursor: pointer;
  padding: 4px 0;
  position: relative;
  transform: translateY(100%);
  transition: transform 0.42s ease(snappy);
  &:before {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 100%;
    height: 8px;
    transform: scaleX(0);
    background-color: $earth;
    transform-origin: 100% 100%;
    transition: transform 0.3s ease(snappy);
  }

  &:hover {
    &:before {
      transform: scaleX(1);
      transform-origin: 0 100%;
    }
  }
}

.c-nav-toggle__bar {
  position: absolute;
  right: 7px;
  height: 1px;
  background-color: $brown;
  z-index: 10;
  &:first-child {
    width: 60%;
    top: 10px;
  }
  &:nth-child(2) {
    width: 60%;
    top: 17px;
  }
  &:nth-child(3) {
    width: 60%;
    bottom: 10px;
  }
}

.c-nav-mobile {
  display: none;
}

@include mq-max(1200px) {
  .c-nav__main-items {
    margin-right: 12px;
  }
  .c-nav__main-link {
    font-size: 12px;
  }
}

@include mq-max(1023px) {
  .c-nav__main-links {
    display: none;
  }

  .c-nav__action--mobile {
    display: inline-block;
  }

  .c-nav-mobile {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    @include size(100%);
    background-color: $brown;
    transform: translateX(-100%);
    transition: transform 0.4s ease(snappy);
    z-index: -1;
  }

  .c-nav-mobile__close {
    position: absolute;
    top: 25px;
    right: 25px;
    @include size(40px);
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid $white;
    z-index: 10;
    &:hover {
      background-color: $white;
      .c-nav-mobile__close-bar {
        background-color: #80BC00;
      }
    }
  }

  .c-nav-mobile__close-bar {
    position: absolute;
    width: 70%;
    height: 1px;
    top: 18px;
    left: 6px;
    background-color: $white;
    &:first-child {
      transform: rotate(-45deg);
    }
    &:last-child {
      transform: rotate(45deg);
    }
  }

  .c-nav-mobile__links {
    @include align();
    list-style: none;
    margin: 0;
    padding: 0;
    width: 70%;
    transition: all 0.35s ease;
    z-index: 10;
  }

  .c-nav__mobile-item {
    display: block;
    margin: 8px 0;
  }

  .c-nav__mobile-link {
    display: inline-block;
    position: relative;
    padding: 6px 0;
    font-size: 20px;
    cursor: pointer;
    color: $white;
    font-weight: bold;
  }

  .c-nav-mobile__sub-close {
    position: absolute;
    top: 32px;
    left: 25px;
    color: $white;
    font-size: 22px;
    @include size(40px);
    opacity: 0;
    cursor: pointer;
    transition: all 0.3s;
    z-index: -1;
    svg {
      @include size(30px);
    }
  }

  .c-nav-mobile__sub-links {
    @include align();
    list-style: none;
    padding: 0;
    margin: 0;
    width: 70%;
    transform: translate(50%, -50%);
    opacity: 0;
    z-index: -1;
    transition: all 0.35s ease;
  }

  .c-nav-mobile__social {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    list-style: none;
    margin: 0;
    padding: 0;
    z-index: 10;
  }

  .c-nav-mobile__social-item {
    display: inline-block;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }

  .c-nav-mobile__bg {
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    @include bg();
    pointer-events: none;
    z-index: 1;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      @include size(100%);
      background-color: rgba(#80BC00, 0.95);
    }
  }

  .is-sub-mobile-open {
    .c-nav-mobile__sub-links {
      transform: translate(-50%, -50%);
      opacity: 1;
      z-index: 10;
    }

    .c-nav-mobile__links {
      opacity: 0;
      transform: translate(-150%, -50%);
    }

    .c-nav-mobile__sub-close {
      opacity: 1;
      z-index: 100;
    }
  }

  .is-mobile-menu-open {
    .c-nav-mobile {
      transform: translateX(0%);
    }
  }
}

@include mq-max(767px) {
  .c-nav-menu__side-content {
    display: none;
  }

  .c-nav-menu__list {
    width: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .c-nav__submenu {
    position: relative;
    top: auto;
    right: auto;
    display: none;

    &.is-submenu-open {
      display: block;
    }
  }

  .c-nav-menu__close-container {
    top: 20px;
    right: 5%;
  }

  .c-nav__submenu-link, .c-nav-menu__link {
    font-size: 20px;
    padding: 3px 0;
  }

  .c-nav-menu__link-sub-icon {
    right: -20px;
  }

  .c-nav__action {
    margin-right: 20px;
  }
}

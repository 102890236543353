.c-image-carousel {
  width: 100%;
  position: relative;
  margin-top: 150px;
  margin-bottom: 150px;
  @include clearfix();
}

.c-image-carousel--no-m {
  margin: 0;
}

.c-image-carousel__main-content {
  width: 70%;
  background-color: $earth;
  padding: 80px 0;
  min-height: 380px;
  position: relative;
  z-index: 10;
}

.c-image-carousel__main-title {
  line-height: 50px;
  font-size: 50px;
  color: #88725D;
  width: 50%;
  font-family: $font-serif;
  display: block;
  padding-right: 40px;
  margin: 0 auto;
}

.c-image-carousel__slider {
  position: absolute;
  bottom: -70px;
  right: 10%;
  @include size(500px, 350px);
  box-shadow: -31.5034px 21.0023px 94.5102px 0 rgba(0, 0, 0, 0.25);
  z-index: 100;
}

.c-image-carousel__slider--full {
  @include size(100%, 420px);
  bottom: auto;
  right: 0;
  position: relative;
}

.c-image-carousel__slide {
  @include size(100%);
  position: relative;
  overflow: hidden;
}

.c-image-carousel__slide-image {
  @include size(100%);
  @include bg();
  background-color: $earth;
}

.c-image-carousel__navigation {
  position: absolute;
  top: -40px;
  left: 67%;
  width: 200px;
  overflow: hidden;
  border: 2px solid rgba(136, 114, 93, 0.2);
  z-index: 2;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    background-color: $white;
    transform: scaleX(0);
    transition: transform 0.3s ease(snappy);
    transform-origin: 100% 100%;
  }

  &:hover {
    &:after {
      transform: scaleX(1);
      transform-origin: 0 100%;
    }
  }
}

.c-image-carousel__navigation-button {
  float: right;
  height: 80px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 10px;
  transition: all 0.3s ease(snappy);
  backface-visibility: hidden;
  z-index: 1;

  &:last-child {
    margin-left: 40px;
  }

  svg {
    @include size(45px);
    path {
      stroke: $brown;
    }
  }

  &:hover {
    transform: scale(1.1);
  }
}

.c-image-carousel__navigation--story {
  left: -40px;
  top: -70px;
  border: 2px solid rgba($red, 0.2);

  .c-image-carousel__navigation-button {
    svg {
      @include size(45px);
      path {
        stroke: $red;
      }
    }
  }
}

.c-image-carousel__navigation--project {
  left: -40px;
  top: -70px;
  border: 2px solid rgba($orange, 0.2);

  .c-image-carousel__navigation-button {
    svg {
      @include size(45px);
      path {
        stroke: $orange;
      }
    }
  }
}

.c-image-carousel__side-text {
  position: absolute;
  bottom: -200px;
  left: 15%;
  width: 300px;
  z-index: 12;
}

@include mq-max(1100px) {
  .c-image-carousel__main-title {
    font-size: 40px;
    line-height: 40px;
    width: 60%;
    margin-left: 30px;
  }
}

@include mq-max(1023px) {
  .c-image-carousel__slider {
    bottom: -30px;
    right: 5%;
    width: 400px;
    height: 250px;
  }
}

@include mq-max(767px) {
  .c-image-carousel {
    margin-top: 80px;
    margin-bottom: -180px;
  }

  .c-image-carousel__main-content {
    width: 100%;
    padding-bottom: 200px;
  }

  .c-image-carousel__slider {
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
    width: 90%;
    top: -178px;
    margin: 0 auto;
    height: 350px;
  }

  .c-image-carousel__side-text {
    display: none;
  }

  .c-image-carousel__navigation {
    top: 70%;
    left: 10%;
    z-index: 101;
    &:after {
      transform: scaleX(1);
    }
  }

  .c-image-carousel__main-title {
    width: 90%;
    margin: 0 auto;
  }
}

@include mq-max(540px) {
  .c-image-carousel__slider {
    height: 290px;
  }

  .c-image-carousel__navigation {
    top: 68%;
  }
}

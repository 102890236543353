.c-anim-illustration__round {
  @include size(400px);
  background-color: $brown;
  border-radius: 50%;
  position: relative;
  top: -140px;
  left: -100px;
}

.c-anim-illustration {
  @include size(340px);
  @include bg();
  background-size: contain;
}

.c-anim-illustration--home {
  margin-bottom: 60px;
  position: relative;
}

.is-site-darken {
  .c-anim-illustration__round {
    background-color: $dk-brown;
  }
}

@include mq-max(875px) {
  .c-anim-illustration {
    width: 356px;
    height: 356px;
  }

  .c-anim-illustration--home {
    @include size(300px);
  }
}

@include mq-max(767px) {
  .c-anim-illustration__round {
    @include size(250px);
    top: 0; left: 0;
    margin: auto;
    position: absolute;
    top: -100px;
    left: 50%;
    transform: translateX(-50%);
  }

  .c-anim-illustration {
    width: 268px;
    height: 223px;
  }

  .c-anim-illustration--home {
    @include size(223px);
  }

  .c-anim-illustration--home {
    position: absolute;
    top: -150px;
    left: -100px;
  }
}

@include mq-max(500px) {
  .c-anim-illustration--home {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
	position: relative;
    top: 0;
    margin: 0;
    @include size(180px);
  }
}

.c-cvideo-section {
  padding: 60px 0;
}

.c-cvideo__image-zone, .c-cvideo__quote-zone {
  @include span(1/2);
}

.c-cvideo__image {
  @include size(100%, 550px);
  @include bg();
  background-color: #ccc;
}

.c-cvideo__quote {
  position: relative;
  quotes: "\201C""\201D""\2018""\2019";

  &:before {
    display: block;
    color: $earth;
    content: open-quote;
    font-family: $font-serif;
    line-height: 0.4;
    position: relative;
    top: 60px;
    font-size: 220px;
  }
}

.c-cvideo__quote-zone {
  padding: 40px 22px 0 22px;
}

.c-cvideo__quote-text {
  line-height: 38px;
  font-size: 25px;
  letter-spacing: 0.05em;
  color: #88725D;
}

.c-cvideo__quote-author {
  line-height: 44px;
  font-size: 28px;
  font-style: italic;
  margin-top: 20px;
  font-weight: bold;
  letter-spacing: 0.04em;
  color: #646464;
}

.c-cvideo__video-bg-container {
  padding: 70px 45px;
  background-color: $earth;
  @include clearfix();
}

.c-cvideo__video-bg-container--yellow {
  background-color: #FDE2AD;
}

.c-cvideo__video-bg-container--orange {
  background-color: #E9A658;
}

.c-cvideo__video-bg-container--red {
  background-color: #F69179;

  .o-section-small-title {
    color: $white !important;
  }
}

.c-cvideo__video-bg-container--green {
  background-color: #C8D1C9;

  .o-section-small-title {
    color: $white !important;
  }
}

.c-cvideo__video-bg-container--blue {
  background-color: $pastel-blue;

  .o-section-small-title {
    color: $white !important;
  }
}

.c-cvideo__video-content-zone {
  @include col(7/12);
}

.c-cvideo__video-media-zone {
  @include col(5/12);
  position: relative;
}

.c-cvideo__video {
  position: absolute;
  top: -70px;
  left: 50px;
  cursor: pointer;
  @include size(400px, 250px);
  @include bg();
  background-color: #ccc;
  box-shadow: -31.4951px 20.9967px 94.4852px 0 rgba(0, 0, 0, 0.25);
  z-index: 10;

  &:hover {
    .c-cvideo__video-button {
      opacity: 0.4;
    }
  }
}

.c-cvideo__video-media-zone--left {
  .c-cvideo__video {
    left: auto;
    right: 70px;
  }
}

.c-cvideo__video-button {
  @include size(70px);
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  @include align();
  border: 2px solid $white;
  z-index: 10;
  transition: opacity 0.3s;
  svg {
    display: block;
    margin: 0 auto;
    @include size(25px);
    path {
      fill: $white;
    }
  }
}

.c-cvideo__video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
  background: linear-gradient(180deg, #333333 0%, rgba(51, 51, 51, 0.5) 100%);
  z-index: 5;
}

.c-cvideo-section__big-text {
  font-size: 340px;
  line-height: 340px;
  color: rgba(233, 166, 88, 0.4);
  display: block;
  margin: 0 auto;
  font-family: $font-family-serif;
  padding: 40px 0;
  position: relative;
  left: 80px;
}

.c-community-dw {
  padding-top: 145px;
}

.c-community-dw__bg-zone {
  @include span(7/12);
  position: relative;
}

.c-community-dw__bg {
  width: 100%;
  height: 400px;
  background-color: rgba(217, 181, 160, 0.61);
}

.c-community-dw__image {
  @include size(350px);
  position: absolute;
  top: -40px;
  left: 50%;
  margin: 0 auto;
  background: #646464;
  transform: translateX(-50%);
  box-shadow: -32.0276px 21.3517px 96.0828px rgba(0, 0, 0, 0.25);
  @include bg;
}

.c-community-dw__content-zone {
  @include span(5/12);
  position: relative;
  top: 50px;
  left: -65px;
}

.c-community-dw__cta {
  position: absolute;
  top: -165px;
  right: 20px;
}

.c-community-dw__button {
  transform: rotate(130deg);
}

@include mq-max(960px) {
  .c-community-dw__image {
    @include size(300px);
  }

  .c-community-dw__bg-zone, .c-community-dw__content-zone {
    @include col(1/2);
  }

  .c-community-dw__content-zone {
    padding-left: 20px;
    padding-right: 20px;
    top: auto;
    left: auto;
  }
}

@include mq-max(767px) {
  .c-community-dw__bg-zone, .c-community-dw__content-zone {
    width: 100%;
    margin: 0;
  }
  .c-cvideo__image-zone, .c-cvideo__quote-zone {
    @include span(1);
  }
  .c-community-dw__content-zone {
    margin-top: 28px;
  }

  .c-community-dw__cta {
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
  }

  .c-community-dw__bg {
    height: 320px;
  }
}

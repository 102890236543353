// SHAME ON YOU BAD DEVELOPER! That's THE place you were looking for.

@include mq-max(767px) {
  .no-mobile {
    display: none !important; 
  }
  #scelta-naturale {
    .c-section__qt {
      margin: 40px 0;
    }
  }
}
#scelta-naturale {
  .c-section__qt {
    margin-top: 40px;
  }
}
#bilancio-sociale {
  padding-bottom: 0;
  position: relative;
  z-index: 2;
  .o-container {
    position: relative;
    color: #fff;
    &:before {
      content: "";
      position: absolute;
      right: 0;
      width: 85%;
      background-color: #51BD89;
      height: 100%;
      z-index: -1;
      top: 0;
      display: block;
    }
    .o-section-main-title, .o-section-text {
      color: #fff;
    }
    .o-csr-info-col {
      padding: 100px 0;
      padding-right: 148px;
    }
    .o-csr-image {
      margin-top: 200px;
    }
    .o-section-main-divider {
      background-color: #fff;
    }
  }
}
#video {
  pointer-events: none;

  .background-video {
    background-position: top center;
    background-repeat: no-repeat;
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
  }
  
  video,
  source {
    bottom: 0;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
  }
  
  .loaded .ytplayer-container {
    display: block;
  }
  
  .loaded .placeholder-image {
    opacity: 0;
  }
  
  .ytplayer-container {
    bottom: 0;
    height: 100%;
    left: 0;
    min-width: 100%;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
  }
  
  .placeholder-image {
    height: 100%;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    position: fixed;
    top: 0;
    z-index: 1;
  }
  
  .ytplayer-shield {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }
  
  .ytplayer-player {
    position: absolute;
  }
}

@include mq-max(1024px) {
  #bilancio-sociale .o-container .o-csr-info-col {
    padding: 100px;
  }
  #bilancio-sociale .o-container:before {
    width: 100%;
  }
}
@include mq-max(768px) {
  #bilancio-sociale .o-container .o-csr-info-col {
    padding: 70px;
  }
  #bilancio-sociale .o-container:before {
    width: 100%;
  }
  #bilancio-sociale {
    margin-bottom: 60px;
  }
}
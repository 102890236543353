.c-loader {
  position: fixed;
  top: 0;
  left: 0;
  @include size(100%);
  overflow: hidden;
  background-color: $white;
  z-index: 9999999;
}

.c-loader__content {
  @include align();
}

.c-loader__logo {
  margin-bottom: 35px;
  svg {
    width: 150px;
    display: block;
    margin: 0 auto;
  }
  img {
    width: 150px;
    display: block;
    margin: 0 auto;
  }
}

.c-loader__overlay {
  position: absolute;
  top: 0;
  left: -100%;
  @include size(100%);
  background-color: rgba(217, 181, 160, 0.61);
  z-index: 10;
}

$base-line-height: 55px;
$white: rgb(255,255,255);
$off-white: rgba($white, 0.2);
$spin-duration: 0.5s;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  margin: 0 auto;
  border-radius: 50%;
  width: $base-line-height;
  height: $base-line-height;
  border: 0.45rem solid rgba($red, 0.5);
  border-top-color: $red;
  animation: spin $spin-duration infinite linear;
}


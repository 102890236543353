.c-social-wall__row {
  width: 100%;
  margin-bottom: 160px;
  @include clearfix();
}

.c-social-wall__row--no-space {
  margin-bottom: 0;
}

.c-social-block {
  @include span(1/4);
  min-height: 130px;

  &:hover {
    .c-social-block__image {
      transform: scale(1.08);
    }
  }
}

.c-social-block__space {
  @include span(1/4);
}

.c-social-block__image-mask {
  @include size(100%);
  overflow: hidden;
  margin-bottom: 22px;
  box-shadow: -32.0276px 21.3517px 96.0828px rgba(0, 0, 0, 0.25);
}

.c-social-block__image {
  @include size(100%);
  @include bg();
  background-color: #ccc;
  transition: transform 0.3s ease(snappy);
}

.c-social-block__text-content {
  float: left;
  width: 80%;
}

.c-social-block__text {
  line-height: 22px;
  font-size: 17px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #646464;
  font-weight: bold;
  padding-right: 6px;
  padding-left: 6px;
}

.c-social-block__text-icon {
  float: left;
  width: 20%;
}

.c-social-block__icon {
  @include size(30px);
  border-radius: 50%;
  background-color: $brown;
  margin: 0 auto;
  position: relative;
  top: 6px;
  svg {
    @include align();
    @include size(15px);
  }
}

.c-social-blank-col {
  @include span(1/2);
}

.c-social-block--xl {
  @include span(1/2);

  .c-social-block__text {
    padding-left: 16px;
  }
}

@include mq-max(800px) {
  .c-social-blank-col {
    display: none; // tmp
  }
  .c-social-block__space {
    display: none;
  }
  .c-social-block {
    @include span(1/2);
  }
  .c-social-wall__row--no-space {
    margin-bottom: 160px;
  }
  .c-social-block__text {
    line-height: 20px;
    font-size: 13px;
  }
  .c-social-block__text-icon {
    display: none;
  }
  .c-social-block__text-content {
    width: 100%;
  }
}

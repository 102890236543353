.c-not-found {
  width: 100%;
  min-height: 100vh;
  background-color: #EDE8DF;
  position: relative;
}

.c-not-found__content {
  @include align();
  top: 55%;
  max-width: 960px;
  margin: 0 auto;
}

.c-not-found__main-title {
  line-height: 125px;
  font-size: 120px;
  margin-bottom: 30px;
  text-align: center;
  color: rgba(136, 114, 93, 0.22);
}

.c-not-found__main-subtitle {
  line-height: 35px;
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
  font-style: italic;
  margin-bottom: 8px;
  color: rgba(136, 114, 93, 0.25);
}

.c-not-found__subtitle {
  line-height: 22px;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.05em;
  font-weight: bold;
  text-transform: uppercase;
  color: rgba(100, 100, 100, 0.9);
}

.c-not-found__image {
  @include size(330px, 200px);
  @include bg();
  margin: 0 auto;
  position: relative;
  top: -60px;
  left: 60px;
}

.c-not-found__text {
  line-height: 22px;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #88725D;
  position: relative;
  width: 80%;
  margin: 0 auto;
  top: -40px;
}

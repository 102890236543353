// .c-solution-block {
//   position: relative;
//   margin: 40px 0 70px 0;
// }

// .c-solution-block__bg {
//   width: 80%;
//   float: right;
//   height: 500px;
//   background-color: $earth;
// }

// .c-solution-block__image-zone {
//   position: absolute;
//   bottom: -60px;
//   right: 40px;
//   box-shadow: -31.5034px 21.0023px 94.5102px 0 rgba(0, 0, 0, 0.25);
// }

// .c-solution-block__image {
//   @include size(600px, 450px);
//   @include bg();
//   background-color: #ccc;
// }

// .c-solution-block__content-zone {
//   position: absolute;
//   top: 50%;
//   left: 100px;
//   transform: translateY(-50%);
//   z-index: 10;
// }

// .c-solution-block--orange {
//   .c-solution-block__bg {
//     background-color: #E9A658;
//   }

//   .c-solution-block__title, .c-solution-block__subtitle {
//     color: #C48945;
//   }
// }

.c-solution-block__slider {
  position: relative;
  margin: 40px 0 70px 0;
  height: 600px;
}

.c-solution-block__bg {
  position: absolute;
  top: 0;
  right: 0;
  @include size(85%, 500px);
  background-color: $earth;
  will-change: transform;
  z-index: 3;
}

.c-solution-block__bg-mask {
  @include size(200%);
  position: relative;
  overflow: hidden;
}

.c-solution-block__bg-el {
  @include size(50%);
  float: left;
  position: relative;
}

.c-solution-block__slide {
  width: 100%;
  height: 500px;
  position: relative;
}

.c-solution-block__content-zone {
  position: absolute;
  top: 50%;
  left: 100px;
  transform: translateY(-50%);
  z-index: 10;
}

.c-solution-block__image-zone {
  position: absolute;
  bottom: 60px;
  right: 40px;
  box-shadow: -31.5034px 21.0023px 94.5102px 0 rgba(0, 0, 0, 0.25);
  z-index: 10;
}

.c-solution-block__image {
  @include size(550px, 420px);
  @include bg();
  background-color: #ccc;
}

.c-solution-block__image-slider {
  @include size(550px, 420px);
  position: relative;
  z-index: 10;
}

.c-solution-block__title {
  line-height: 127px;
  font-size: 100px;
  font-family: $font-serif;
  color: $brown;
}

.c-solution-block__subtitle {
  line-height: 41px;
  font-size: 40px;
  font-family: $font-serif;
  color: $brown;
}

.c-solution-block__text {
  margin-top: 45px;
  width: 400px;
  line-height: 30px;
  font-size: 18px;
  letter-spacing: 0.05em;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 22px;
  color: #646464;
}

.c-solution-block__navigation {
  position: absolute;
  top: -60px;
  left: -8%;
  width: 200px;
  overflow: hidden;
  border: 2px solid rgba($white, 0.3);
  z-index: 2;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    background-color: $white;
    transform: scaleX(0);
    transition: transform 0.3s ease(snappy);
    transform-origin: 100% 100%;
  }

  &:hover {
    &:after {
      transform: scaleX(1);
      transform-origin: 0 100%;
    }

    .c-solution-block__nav-button {
      svg path {
        stroke: $brown;
      }
    }
  }
}

.c-solution-block__nav-button {
  float: right;
  height: 70px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 10px;
  transition: all 0.3s ease(snappy);
  backface-visibility: hidden;
  z-index: 1;

  &:last-child {
    margin-left: 40px;
  }

  svg {
    @include size(38px);
    path {
      stroke: $white;
      transition: all 0.3s;
    }
  }

  &:hover {
    transform: scale(1.1);
  }
}

@include mq-max(1100px) {
  .c-solution-block__title {
    line-height: 90px;
    font-size: 80px;
  }

  .c-solution-block__subtitle {
    line-height: 31px;
    font-size: 30px;
  }

  .c-solution-block__content-zone {
    left: 40px;
  }

  .c-solution-block__text {
    margin-top: 35px;
    width: 300px;
    line-height: 26px;
    font-size: 16px;
  }

  .c-solution-block__image-zone {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
  }
}

@include mq-max(960px) {
  .c-solution-block__image-slider, .c-solution-block__image {
    @include size(450px, 320px);
  }

  .c-solution-block__bg {
    width: 100%;
    height: 100%;
  }
}

@include mq-max(840px) {
  .c-solution-block__slider {
    height: 400px;
  }
  .c-solution-block__content-zone, .c-solution-block__image-zone {
    position: relative;
    transform: none;
    top: auto;
    left: auto;
    bottom: auto;
    width: 100%;
    float: left;
  }

  .c-solution-block__content-zone {
    top: 40px;
    left: 30px;
  }

  .c-solution-block__image-zone {
    top: 100px;
    position: absolute;
  }

  .c-solution-block__image-zone {
    float: right;
    width: 53%;
  }

  .c-solution-block__navigation {
    top: auto;
    bottom: -30px;
    left: -15%;
    z-index: 1000;
    &:after {
      transform: scaleX(1);
    }
  }

  .c-solution-block__nav-button {
    svg path {
      stroke: $brown;
    }
  }

  .c-solution-block__text {
    display: none;
  }

  .c-solution-block__title {
    line-height: 60px;
    font-size: 50px;
  }

  .c-solution-block__subtitle {
    line-height: 21px;
    font-size: 20px;
  }
}

@include mq-max(767px) {
  .c-solution-block__image-zone {
    top: 100px;
    position: relative;
  }
}

@include mq-max(540px) {
  .c-solution-block__image-slider, .c-solution-block__image {
    @include size(300px, 230px);
  }

  .c-solution-block__image-zone {
    width: 300px;
  }
}

@include mq-max(420px) {
  .c-solution-block__image-zone {
    right: 20px;
  }
}


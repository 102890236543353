.c-product-block {
  position: relative;
  margin-bottom: 80px;
}

.c-product-block__bg {
  color: $white;
  width: 90%;
  padding: 50px 0;
  position: relative;
  float: right;
}

.c-product-block__bg--blue {
  background-color: #007BB5;

  .c-product-block__icon {
    background-color: mix(#007BB5, $white, 63%);
  }
}

.c-product-block__bg--green {
  background-color: #88BF7A;
  .c-product-block__icon {
    background-color: mix(#88BF7A, $white, 63%);
  }
}

.c-product-block__bg--brown {
  background-color: #96643F;
  .c-product-block__icon {
    background-color: mix(#96643F, $white, 63%);
  }
}

.c-product-block__bg--navy {
  background-color: #385B7A;

  .c-product-block__icon {
    background-color: mix(#385B7A, $white, 63%);
  }
}

.c-product-block__bg--red {
  background-color: #E04C4C;
  .c-product-block__icon {
    background-color: mix(#E04C4C, $white, 63%);
  }
}

.c-product-block__bg--aqua {
  background-color: #78CFEB;
  .c-product-block__icon {
    background-color: mix(#78CFEB, $white, 63%);
  }
}

.c-product-block__image-zone {
  @include col(4/12);
}

.c-product-block__content-zone {
  @include col(8/12);
}

.c-product-block__image {
  width: 110%;
  height: 400px;
  @include bg();
  background-color: #ccc;
  position: relative;
  top: 70px;
  left: -10%;
  box-shadow: -31.5034px 21.0023px 94.5102px rgba(0, 0, 0, 0.25);
}

.c-product-block__title {
  @include span(8/12);
  @include shift(4/12);
  line-height: 110px;
  font-size: 105px;
  font-family: $font-serif;
  margin-bottom: 22px;
  letter-spacing: 0.04em;
}

.c-product-block__text {
  line-height: 30px;
  font-size: 20px;
  letter-spacing: 0.05em;
  padding-right: 60px;
}

.c-product-block__icon {
  @include size(420px);
  border-radius: 50%;
  position: relative;
  top: 70px;
  left: -10%;
  background-color: #ccc;
  box-shadow: -31.5034px 21.0023px 94.5102px rgba(0, 0, 0, 0.25);
}

.c-product-block__icon-content {
  @include align();
  width: 80%;
  text-align: center;
  svg, img {
    display: block;
    @include size(250px);
    margin: 0 auto;
  }
}

.c-products__index-block-icon {
  @include align();
  width: 65%;
  svg {
    display: block;
    @include size(130px);
    margin: 0 auto;
  }
}

.c-product-block--left {
  .c-product-block__title {
    @include unshift();
    position: relative;
    left: 4%;
  }

  .c-product-block__bg {
    float: left;
  }

  .c-product-block__content-zone {
    left: -30.916667%;
    position: relative;
  }

  .c-product-block__image-zone {
    @include shift(8/12);
  }

  .c-product-block__image {
    left: 20.5%;
  }
}

.c-products__index {
  padding: 40px 0;
}

.c-product__index {
  @include col(1/5);
  cursor: pointer;
  text-align: center;
  &:hover {
    .c-products__index-block {
      transform: scale(1.1);
    }
    .c-products__index-block-shadow {
      opacity: 1;
    }
    .c-products__index-title {
      transform: translateY(4px);
      &:before {
        transform: scaleX(1);
        transform-origin: 0 100%;
      }
    }
  }
}

.c-products__index-block {
  @include size(200px);
  margin: 0 auto 22px auto;
  position: relative;
  background-color: #ccc;
  transition: transform 0.3s ease(snappy);
}

.c-products__index-block-shadow {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
  box-shadow: 0 20px 43px 0 rgba(0, 0, 0, 0.11);
  opacity: 0;
  transition: opacity 0.6s;
}

.c-products__index-title {
  display: inline-block;
  line-height: 31px;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-weight: bold;
  position: relative;
  color: #646464;
  transition: transform 0.3s ease(snappy);

  &:before {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 100%;
    height: 8px;
    transform: scaleX(0);
    background-color: mix($earth, $white, 43%);
    transform-origin: 100% 100%;
    transition: transform 0.3s ease(snappy);
    z-index: -1;
  }
}

@include mq-max(1250px) {
  .c-product-block__icon {
    @include size(350px);
  }
  .c-product-block__icon-content {
    svg, img {
      @include size(200px);
    }
  }
}

@include mq-max(1150px) {
  .c-product__index {
    @include col(1/3, $cycle: 3);
    margin-bottom: 15px;
  }

  .c-product-block__icon {
    @include size(300px);
  }
}

@include mq-max(960px) {
  .c-product-block:nth-child(2n) {
    .c-product-block__icon {
      left: -55px;
    }
  }
}

@include mq-max(860px) {
  .c-product-block__title {
    width: 100%;
    float: none;
    position: static;
    padding-left: 5%;
    padding-right: 5%;
  }
  .c-product-block__image-zone {
    width: auto;
    position: absolute;
    bottom: -135px;
    left: -55px;
    margin: 0;
  }
  .c-product-block--left .c-product-block__image-zone {
    width: auto;
    position: absolute;
    bottom: -135px;
    right: -55px;
    margin: 0;
    left: auto;
  }
  .c-product-block__content-zone {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 80px;
  }
  .c-product-block--left .c-product-block__content-zone {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    left: 0;
    margin-bottom: 80px;
  }
  .c-product-block__icon {
    top: 0;
    left: 0;
  }
  .c-product-block:nth-child(2n) {
    .c-product-block__icon {
      left: 0;
    }
  }
  .c-product-block__icon {
    @include size(200px);
  }
  .c-product-block__icon-content {
    svg, img {
      @include size(120px);
    }
  }
}

@include mq-max(680px) {
  .c-product__index {
    @include col(1/2, $uncycle: 3, $cycle: 2);
  }

  .c-product-block__title {
    line-height: 65px;
    font-size: 75px;
  }
}

@include mq-max(460px) {
  .c-products__index-block {
    @include size(150px);
    margin-bottom: 3px;
  }

  .c-products__index-block-icon {
    svg {
      @include size(80px);
    }
  }

  .c-product-block__title {
    line-height: 55px;
    font-size: 55px;
  }
  .c-product-block__text {
    padding-right: 5%;
  }
}

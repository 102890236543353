.c-round-slider {
  position: relative;

  .flickity-viewport {
    z-index: 3;
  }
}

.c-round-slider__section {
  padding-bottom: 160px;
}

.c-round-slider__navigation {
  position: absolute;
  top: -40px;
  left: 45%;
  width: 200px;
  overflow: hidden;
  border: 2px solid rgba(#51BD89, 0.2);
  z-index: 2;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    background-color: $white;
    transform: scaleX(0);
    transition: transform 0.3s ease(snappy);
    transform-origin: 100% 100%;
  }

  &:hover {
    &:after {
      transform: scaleX(1);
      transform-origin: 0 100%;
    }
  }
}

.c-round-slider__navigation-button {
  float: right;
  height: 80px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 10px;
  transition: all 0.3s ease(snappy);
  backface-visibility: hidden;
  z-index: 1;

  &:last-child {
    margin-left: 40px;
  }

  svg {
    @include size(45px);
  }

  &:hover {
    transform: scale(1.1);
  }
}

.c-round-slide {
  width: 100%;
  position: relative;
  z-index: 3;
}

.c-round-slider__ball {
  @include size(250px);
  border-radius: 50%;
  float: left;
  background-color: $brown;
  position: relative;
  left: 90px;
}

.c-round-slider__ball--red {
  background-color: #DE5757;
}

.c-round-slider__ball--green {
  background-color: #31B6BE;
}

.c-round-slider__text {
  @include align();
  width: 80%;
  text-align: center;
  line-height: 28px;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  font-weight: bold;
  text-transform: uppercase;
  color: $white;
}

@include mq-max(767px) {
  .c-round-slider {
    margin-top: 120px;
  }
  .c-round-slider__section {
    padding-bottom: 0;
  }
  .c-round-slider__navigation {
    left: 35%;
  }
  .c-round-slider__ball {
    left: auto;
  }
  .c-round-slider__text {
    line-height: 26px;
    font-size: 16px;
  }
}

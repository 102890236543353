.c-footer {
  padding: 100px 0;
}

.c-footer__box {
  @include col(1/3);
  background-color: #eee;
  position: relative;
  padding: 40px 25px;
  cursor: pointer;
  transition: transform 0.3s ease(snappy);
  &:hover {
    transform: scale(1.03);
    .c-footer__box-shadow {
      opacity: 1;
    }

    .c-footer__overlay {
      transform: scaleX(1);
      transform-origin: 0 100%;
    }

    .c-footer__box-text, .c-footer__box-claim {
      color: $brown;
    }

    .c-footer__box-icon {
      transform: rotate(4deg);
      svg {
        path {
          fill: $brown;
        }
      }
    }
  }
}

.c-footer__overlay {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
  background-color: $earth;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.3s ease(snappy);
  z-index: 1;
}

.c-footer__box--green {
  .c-footer__overlay {
    background-color: $vista-green;
  }

  &:hover {
    .c-footer__box-text, .c-footer__box-claim {
      color: darken($vista-green, 30%);
    }
    .c-footer__box-icon {
      transform: rotate(4deg);
      svg {
        path {
          fill: darken($vista-green, 30%);
        }
      }
    }
  }
}

.c-footer__box--red {
  .c-footer__overlay {
    background-color: #E76A5C;
  }

  &:hover {
    .c-footer__box-text, .c-footer__box-claim {
      color: darken(#E76A5C, 30%);
    }
    .c-footer__box-icon {
      transform: rotate(4deg);
      svg {
        path {
          fill: darken(#E76A5C, 30%);
        }
      }
    }
  }
}

.c-footer__box-shadow {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
  box-shadow: 0 20px 43px 0 rgba(0, 0, 0, 0.11);
  opacity: 0;
  transition: opacity 0.6s;
}

.c-footer__box-claim {
  font-size: 18px;
  line-height: 25px;
  font-weight: bold;
  text-transform: uppercase;
  color: #646464;
  position: relative;
  transition: color 0.3s;
  z-index: 2;
}

.c-footer__box-body {
  margin-top: 22px;
  text-align: center;
  position: relative;
  z-index: 2;
}

.c-footer__box-icon {
  margin-bottom: 8px;
  transition: transform 0.25s ease(snappy);
  svg {
    @include size(60px);
    path {
      transition: all 0.4s;
    }
  }
}

.c-footer__box-text {
  line-height: 25px;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  width: 80%;
  margin: 0 auto;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(100, 100, 100, 0.7);
  transition: color 0.3s;
}

.c-footer__body {
  margin-top: 70px;
}

.c-footer__main-col {
  @include col(1/2);
}

.c-footer__credits {
  font-size: 14px;
  color: #646464;
  letter-spacing: 0.05em;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 59px;
}

.c-footer__credits-logo {
  display: inline-block;
  margin-left: 12px;
  img {
    position: relative;
    top: -5px;
	width: 100px;
  }
}

.c-footer__text {
  line-height: 34px;
  font-size: 18px;
  letter-spacing: 0.05em;
  color: #646464;
  margin-bottom: 33px;
  &:last-child {
    margin-bottom: 0;
  }
  a {
    color: #646464;
  }
}

.c-footer__col {
  @include col(1/2);
}

.c-footer__links {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 32px;
  margin-bottom: 24px;
  @include clearfix();
}

.c-footer__item {
  @include col(1/2, $cycle: 2);
}

.c-footer__link {
  display: inline-block;
  position: relative;
  line-height: 34px;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #646464;
  &:before {
    content: "";
    position: absolute;
    bottom: 3px;
    left: 0%;
    width: 90%;
    height: 14px;
    transform: scaleX(0);
    background-color: mix($earth, $white, 43%);
    transform-origin: 100% 100%;
    transition: transform 0.3s ease(snappy);
    z-index: -1;
  }

  &:hover {
    &:before {
      transform: scaleX(1);
      transform-origin: 0 100%;
    }
  }
}

.c-footer__sub-link {
  display: block;
  position: relative;
  font-size: 18px;
  letter-spacing: 0.05em;
  color: #646464;
  margin-bottom: 4px;
  &:before {
    content: "";
    position: absolute;
    bottom: 3px;
    left: 0%;
    width: 40%;
    height: 9px;
    transform: scaleX(0);
    background-color: mix($earth, $white, 43%);
    transform-origin: 100% 100%;
    transition: transform 0.3s ease(snappy);
    z-index: -1;
  }

  &:hover {
    &:before {
      transform: scaleX(1);
      transform-origin: 0 100%;
    }
  }
}

.c-footer__assistance-button {
  float: right;
  text-align: right;
  margin-top: 32px;
}

.c-footer__assistance-button-text {
  display: inline-block;
  font-size: 25px;
  position: relative;
  top: -16px;
  padding-left: 12px;
  text-align: right;
  letter-spacing: 0.05em;
  color: #646464;
}

.c-footer__social-links {
  float: right;
  list-style: none;
  padding: 0;
  margin: 0;
}

.c-footer__social-item {
  display: inline-block;
  margin-left: 16px;
}

.c-footer__social-link {
  display: block;
  @include size(55px);
  border-radius: 50%;
  position: relative;
  border: 1px solid #80BC00;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    @include size(100%);
    transform: scale(0);
    background-color: #80BC00;
    transition: transform 0.3s ease(snappy);
  }

  &:hover {
    &:after {
      transform: scale(1);
    }
    .c-footer__social-icon {
      svg path {
        fill: $white;
      }
    }
  }
}

.c-footer__social-icon {
  display: block;
  @include align();
  @include size(20px);
  z-index: 1;
  svg {
    display: block;
    @include size(20px);
    path {
      fill: #80BC00;
      transition: all 0.3s;
    }
  }
}

.c-footer__mobile-credits {
  display: none;
  font-size: 14px;
  color: #646464;
  letter-spacing: 0.05em;
  font-weight: bold;
  text-transform: uppercase;
}

.c-footer__mobile-credits-logo {
  display: inline-block;
  margin-left: 12px;
  img {
    position: relative;
    top: -5px;
    width: 100px;
  }
}


@include mq-max(767px) {
  .c-footer__credits {
    display: none;
  }

  .c-footer__assistance-button {
    float: left;
    text-align: left;
    margin-top: 0;
  }

  .c-footer {
    padding-top: 60px;
  }

  .c-footer__box-claim {
    text-align: center;
    font-size: 16px;
    line-height: 23px;
  }

  .c-footer__box, .c-footer__main-col, .c-footer__col {
    width: 100%;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .c-footer__mobile-credits {
    display: block;
    text-align: center;
    margin-bottom: 32px;
  }

  .c-footer__text {
    line-height: 32px;
    font-size: 20px;
  }
}

.ie-modal__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($earth, 0.6);
  display: none;
  z-index: 999999999999;
}

.ie-modal {
  @include align();
  width: 450px;
  margin: 0 auto;
  background-color: $white;
  padding: 20px;
  color: $black;
  z-index: 10;

  a {
    color: $dust;
  }
}

.is-ie {
  .ie-modal__container {
    display: block;
  }
}
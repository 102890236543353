

.c-projects-slider__container {
  margin-top: 100px;
}

.c-projects-slider {
  width: 960px;
  margin: 0 auto;
  position: relative;
}

.c-projects-slide {
  width: 100%;
  height: 460px;
  position: relative;
}

.c-projects-slider__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 100%;
  background-color: #C7B7A7;
  will-change: transform;
  overflow: hidden;
  z-index: 3;

}

.c-projects-slider__cta {
  position: absolute;
  bottom: -30px;
  right: 45px;
  z-index: 100;
}

.c-projects-slider__bg-layer {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
  background-color: #F7BD79;
  &.c-projects-slider--green {
    background-color: #51BD89;
  }
  &.c-projects-slider--orange {
    background-color: #F7BD79;
  }
  &.c-projects-slider--aqua {
    background-color: #C8F2F8;
  }
  &.c-projects-slider--terra {
    background-color: #9B655E;
  }
}

.c-projects-slider__bg-layer--next {
  left: 100%;
}

.c-projects-slider__content {
  @include align();
  left: 40%;
  z-index: 10;
}

.c-projects-slider__main-title {
  line-height: 1;
  font-size: 70px;
  font-family: $font-serif;
  font-weight: 400;
  margin-bottom: 22px;
  position: relative;
  color: $white;
}

.c-projects-slider__main-text {
  line-height: 28px;
  font-size: 16px;
  width: 400px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 22px;
  color: #fff;
}

.c-projects-slider__nav {
  position: absolute;
  top: -40px;
  right: -5%;
  width: 200px;
  overflow: hidden;
  border: 2px solid rgba(136, 114, 93, 0.2);
  z-index: 2;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    transform: scaleX(0);
    transition: transform 0.3s cubic-bezier(0.694, 0.048, 0.335, 1);
    transform-origin: 100% 100%;
  }

  &:hover {
    &:after {
      transform: scaleX(1);
      transform-origin: 0 100%;
    }
  }
}

.c-projects-slider__nav-button {
  float: right;
  height: 80px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 10px;
  transition: all 0.3s ease(snappy);
  backface-visibility: hidden;
  z-index: 1;
  &:last-child {
    margin-left: 40px;
  }
  svg {
    @include size(45px);
    path {
      stroke: #88725D;
    }
  }

  &:hover {
    transform: scale(1.1);
  }
}

@include mq-max(1100px) {
  .c-projects-slider__bg {
    z-index: 0;
  }
  .c-projects-slider__nav {
    z-index: 1;
    right: 130px;
    background-color: white;
  }
}


@include mq-max(997px) {
  .c-projects-slider__cta {
    right: 82px;
  }
}

@include mq-max(920px) {
  .c-projects-slider__bg {
    width: 80%;
  }

  .c-projects-slider__nav {
    right: 238px;
  }

  .c-projects-slider__cta {
    right: 140px;
  }
}

@include mq-max(860px) {
  .c-projects-slider__cta {
    right: 180px;
  }
}

@include mq-max(820px) {
  .c-projects-slider {
    width: 90%;
  }

  .c-projects-slider__bg {
    width: 100%;
  }

  .c-projects-slider__cta {
    right: -35px;
  }

  .c-projects-slider__nav {
    right: 40px;
  }
}

@include mq-max(768px) {
  .c-projects-slider__cta {
    right: 0;
  }
}

@include mq-max(630px) {
  .c-projects-slider__content {
    width: 90%;
    left: 50%;
  }

  .c-projects-slider__main-title {
    font-size: 55px;
  }

  .c-projects-slider__main-text {
    width: auto;
  }
}

@include mq-max(430px) {
  .c-projects-slider__main-title {
    font-size: 35px;
  }
}

.o-csr-section {
  padding-top: 120px;
}

.o-csr-section__bg {
  width: 80%;
  background-color: $olive;
  padding: 40px 0 40px 40px;
  position: relative;
  margin-bottom: 100px;
}

.o-csr-section__head {
  position: absolute;
  top: -110px;
  right: -80px;
}

.o-csr-section__title {
  line-height: 60px;
  font-size: 70px;
  text-align: right;
  font-family: $font-serif;
  color: #8D9C40;

  span {
    display: inline-block;
    position: relative;
    &:last-child {
      font-size: 140px;
      left: 30px;
    }
  }
}

.o-csr-section__title-letter {
  display: inline-block;
  position: relative;
  left: -42.5%;
}

.o-csr-section__subtitle {
  line-height: 37px;
  font-size: 22px;
  text-align: center;
  letter-spacing: 0.05em;
  font-weight: bold;
  padding-top: 10px;
  text-transform: uppercase;
  color: #8D9C40;
}

.o-csr-section__content-zone {
  @include span(5/12);
}

.o-csr-section__image-zone {
  @include span(7/12);
  position: relative;
}

.o-csr-section__image {
  width: 512px;
  height: 338px;
  @include bg();
  background-color: #ccc;
  box-shadow: -30px 20px 90px rgba(0, 0, 0, 0.25);
  position: relative;
  top: 175px;
  left: 120px;
}

.o-csr-section__claim {
  margin-bottom: 22px;
}

.o-csr-section__text {
  color: $white;
}

.o-csr-bilancio__img {
  display: block;
  margin: 0 auto;
}

.o-csr-anima {
  padding: 80px 0;
  background-color: #D5D9DB;
  margin: 140px 0 40px 0;
}

.o-csr-anima__image {
  @include size(460px);
  @include bg();
  background-color: #ccc;
  position: relative;
  top: -140px;
  box-shadow: -31.5034px 21.0023px 94.5102px rgba(0, 0, 0, 0.25);
}

.o-csr-anima__logo {
  display: block;
  margin: 0 auto;
  position: relative;
  top: -20px;
}
.o-csr-bilancio {
  padding-top: 0;
  .msc__cta-text {
    color: #9F8D7D;
  }
  &__img {
    padding-top: 100px;
    padding-bottom: 150px;
  }
  .c-box-cta__button-text-csr {
    font-size: 14px;
    bottom: 0px;
    position: relative;
    text-align: left;
    left: 0;
    transform: none;
    text-decoration: none;
    padding-top: 20px;
    padding-bottom: 10px;

    ul {
      margin-left: 0;
      padding-left: 0;
      list-style: none;
      li {
        display: inline-block;
        position: relative;

       &:last-child {
         a {
          border-right: 0;
         }
       }
      }
      a {
        text-decoration: underline;
        border-right: 1px solid #88725D;
        padding-right: 10px;
        margin-right: 10px;
      }
    }
  }
  .msc__cta-button {
    right: -100px;
    z-index: 10;
    bottom: auto;
    top: 110px;
    @include mq-max(1440px) {
      right: -20px;
    }
  }
  .msc__cta-text-bilancio {
    font-size: 45px;
    color: #9F8D7D;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: #9F8D7D;
      left:0;
    }
  }
  .msc__cta-text {
    display: block;
    font-size: 58px;
    line-height: 120%;
    @include mq-max(1280px) {
      font-size: 45px;
    }
  }
  
}

.o-csr-bilancio .o-container {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    width: 90%;
    background-color: #EDE8DF;
    z-index: -1;
    display: block;
    top: -100px;
    height: calc(100% + 150px);
  }
}

@include mq-max(1024px) {
  .o-csr-section__image {
    top: 260px;
  }
 
}

@include mq-max(960px) {
  .o-csr-anima__image {
    @include size(360px);
  }
  .o-csr-section__content-zone, .o-csr-section__image-zone {
    @include col(1/2);
  }

  .o-csr-section__image {
    @include size(400px, 300px);
  }

  .o-csr-section__title {
    line-height: 47px;
    font-size: 50px;
  }

  .o-csr-section__title span:last-child {
    font-size: 120px;
    left: 35px;
  }
}

@include mq-max(800px) {
  .o-csr-section__head {
    position: relative;
    top: auto;
    right: auto;
    text-align: center;
  }

  .o-csr-section__title {
    line-height: 50px;
    font-size: 50px;
    text-align: center;
    span {
      font-size: 80px !important;
      line-height: 42px;
      left: auto !important;
      top: -30px;
    }
  }

  .o-csr-section__subtitle {
    line-height: 26px;
    font-size: 18px;
    padding-top: 0;
  }

  .o-csr-section__title-letter {
    display: none !important;
  }

  .o-csr-section__bg {
    width: 100%;
  }

  .o-csr-section__content-zone, .o-csr-section__image-zone {
    width: 100%;
    margin: 0;
  }

  .o-csr-section__content-zone {
    padding: 30px 20px 30px 0;
  }

  .o-csr-anima__image {
    top: auto;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .o-csr-section__image {
    width: 80%;
    top: auto;
    left: auto;
    float: right;
  }

  .o-csr-image-col {
    display: none;
  }

  .o-csr-info-col {
    width: 100%;
  }
}

@include mq-max(800px) {
  .o-csr-section__bg {
    margin-bottom: 0;
  }
}

@include mq-max(767px) {
  .o-csr-bilancio {
    padding-top: 0;
    padding-bottom: 100px;
    background-color: #EDE8DF;
    .o-container:before {
      top: 0;
    }
    .msc__cta {
      padding: 0;
      top: 60px;
    }

    .msc__cta-button {
      top: 90px;
      right: -20px;
    }
    .o-container {
      padding: 40px 0;
    }
    .msc__cta-text {
      font-size: 75px;
      line-height: 65px;
    }
  }
 
  
  .o-csr-anima {
    margin-bottom: 0;
  }
}



@include mq-max(600px) {
  .o-csr-section__image {
    width: 90%;
  }
}

@include mq-max(540px) {
  .o-csr-bilancio {
    .msc__cta-button {
      bottom: -20px;
      right: 0;
    }
    .msc__cta-text {
      font-size: 36px;
      line-height: 46px;
    }
  }
}

@include mq-max(420px) {
  .o-csr-section__image {
    width: 100%;
  }
}


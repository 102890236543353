.o-cookie-bar {
  position: fixed;
  bottom: 20px;
  left: 0;
  width: 100%;
  transition: all 0.3s;
  z-index: 999999;
}

.o-cookie-bar__main {
  width: 960px;
  margin: 0 auto;
  padding: 22px 30px;
  background: $bonjour;
  box-shadow: 0 20px 40px 0 rgba($black, 0.11);
  @include clearfix();
}

.o-cookie-bar__main-text {
  float: left;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: $dust;
}

.o-cookie-bar__main-right {
  float: right;
}

.o-cookie-bar__main-link {
  display: inline-block;
  font-size: 14px;
  text-decoration: underline;
  letter-spacing: 0.05em;
  color: $dust;
  margin-right: 8px;
}

.o-cookie-bar__main-cta {
  display: inline-block;
  cursor: pointer;
  background-color: $dusty-gray;
  padding: 10px 32px;
  text-align: center;
  backface-visibility: hidden;
  transition: background-color 0.3s;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: $dust;
    .o-cookie-bar__main-cta-text {
      transform: scale(1.06);
    }
  }
}

.o-cookie-bar__main-cta-text {
  text-transform: uppercase;
  text-decoration: underline;
  color: $white;
  letter-spacing: 0.05em;
  font-size: 14px;
  font-weight: bold;
  transition: transform 0.3s ease(snappy);
}

.is-cookie-accept {
  .o-cookie-bar {
    opacity: 0;
    transform: translateY(20px);
    z-index: -1;
  }
}

@include mq-max(1023px) {
  .o-cookie-bar__main {
    width: 95%;
  }

  .o-cookie-bar__main-text, .o-cookie-bar__main-link {
    font-size: 12px;
  }
}

@include mq-max(767px) {
  .o-cookie-bar__main {
    padding: 15px 12px;
  }
  .o-cookie-bar__main-text, .o-cookie-bar__main-right {
    display: block;
    width: 100%;
    text-align: center;
  }

  .o-cookie-bar__main-text {
    line-height: 22px;
    margin-bottom: 8px;
  }
}

.o-contact-block {
  width: 70%;
  float: right;
  border: 10px solid $brown;
  padding: 80px 40px;
  position: relative;
}

.o-contact-block__claim {
  line-height: 27px;
  font-size: 18px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $brown;
  font-weight: bold;
}

.c-contact-block__body {
  margin-top: 24px;
}

.o-contact-block__title-zone, .o-contact-block__cta-zone {
  @include col(1/2);
}

.o-contact-block__title {
  line-height: 47px;
  font-size: 45px;
  letter-spacing: 0.04em;
  color: #646464;
}

.o-contact-block__text {
  line-height: 37px;
  font-size: 22.0466px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-weight: bold;
  color: #646464;
}

.o-contact-block__cta {
  margin-top: 16px;
  svg {
    path {
      fill: $brown;
    }
  }
}

.o-contact-block__info-cta {
  position: absolute;
  bottom: -60px;
  left: -150px;
  @include size(200px);
  background-color: $orange;
  border-radius: 50%;
}

.o-contact-block__info-cta-content {
  @include align();
  width: 70%;
  text-align: center;
}

.o-contact-block__info-cta-icon {
  margin-bottom: 4px;
  svg {
    @include size(45px);
  }
}

.o-contact-block__info-cta-title {
  line-height: 20px;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-weight: bold;
  color: #EDE8DF;
}

.o-contact-block__info-button {
  position: absolute;
  bottom: -45px;
  right: -40px;
  opacity: 0.8;
  z-index: 10;
}

@include mq-max(960px) {
  .o-contact-block {
    width: 100%;
    padding: 40px 20px;
    padding-bottom: 60px;
    padding-top: 20px;
  }

  .o-contact-block__info-cta {
    display: block;
    position: relative;
    bottom: auto;
    left: auto;
    width: 170px;
    height: 170px;
    margin: 20px auto 0 auto;
  }

  .o-contact-block__info-cta {
    z-index: 1;
  }

  .o-contact-block__info-button {
    z-index: -1;
  }
}

@include mq-max(767px) {
  .o-contact-block__title-zone, .o-contact-block__cta-zone {
    width: 100%;
    margin: 12px 0;
  }

  .o-contact-block__title {
    line-height: 32px;
    font-size: 30px;
  }

  .c-contact-block__body {
    margin-top: 12px;
  }

  .o-contact-block__info-cta {
    @include size(130px);
    margin-top: 0;
  }

  .o-contact-block__info-cta-title {
    line-height: 14px;
    font-size: 12px;
  }

  .o-contact-block__text {
    line-height: 25px;
    font-size: 18px;
  }

  .o-contact-block__info-cta-icon svg {
    @include size(35px);
  }
}

.c-contacts-modal {
  position: fixed;
  top: 0;
  left: 0;
  @include size(100%);
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.c-contacts-modal__bg, .c-contacts-modal__bg-layer {
  position: absolute;
  top: 100%;
  left: 0;
  @include size(100%);
}

.c-contacts-modal__bg {
  background-color: $white;
  z-index: 10;
}

.c-contacts-modal__bg-layer {
  background-color: $earth;
  z-index: 1;
}

.c-contacts-modal__close-container {
  position: absolute;
  top: 70px;
  opacity: 0;
  right: 5%;
  z-index: 10;
}

.c-contacts-modal__close {
  @include size(55px);
  border: 2px solid $dusty-gray;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    .c-contacts-modal__close-circle {
      transform: translate(-50%, -50%) scale(1);
    }

    .c-contacts-modal__close-text {
      color: $white;
    }
  }
}

.c-contacts-modal__close-text {
  @include align();
  width: 100%;
  height: 22px;
  text-align: center;
  color: $dusty-gray;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.05em;
  transition: color 0.3s;
  z-index: 2;
}

.c-contacts-modal__close-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  @include size(110%);
  border-radius: 50%;
  background-color: $dusty-gray;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.3s ease(snappy);
  z-index: 1;
}

.c-contacts-modal__content {
  @include align();
  width: 800px;
  opacity: 0;
  z-index: 3;
}

.c-contacts-modal__step-control {
  position: relative;
  text-align: center;
  width: 600px;
  margin: 0 auto;

  &:after {
    content: "";
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #CBBBAC;
  }
}

.c-contacts-modal__step {
  display: inline-block;
  @include size(50px);
  border-radius: 50%;
  border: 1px solid #CBBBAC;
  position: relative;
  text-align: center;
  background-color: $white;
  margin-right: 50px;
  cursor: pointer;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    background-color: #cbbbac;
    transform: scale(0);
    border-radius: 50%;
    transition: transform 0.3s ease(snappy);
    z-index: 1;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover, &.is-active {
    .c-contacts-modal__step-number {
      color: $brown;
    }
    &:after {
      transform: scale(1);
    }
  }

  &[disabled] {
    cursor: not-allowed;
    .c-contacts-modal__step-number {
      opacity: 0.6;
    }
    &:after {
      display: none;
    }
    &:hover {
      .c-contacts-modal__step-number {
        color: #cbbbac;
      }
    }
  }
}

.c-contacts-modal__step-number {
  @include align();
  font-size: 28px;
  letter-spacing: 0.05em;
  color: #CBBBAC;
  transition: color 0.3s;
  z-index: 2;
}

.c-contacts-modal__main-step-container {
  position: relative;
  margin-top: 50px;
}

.c-contacts-modal__main-results-layer {
  position: fixed;
  top: -100%;
  left: 0;
  @include size(100%);
  overflow-y: auto;
  background-color: $white;
  transition: transform 0.43s ease(snappy);
  z-index: 1000;
}

.c-contacts-modal__main-results-layer-content {
  @include align();
  width: 700px;
  text-align: center;
  margin: 0 auto;
}

.c-contacts-modal__main-results-layer-title {
  line-height: 57px;
  font-size: 50px;
  font-family: $font-serif;
  color: $brown;
  margin-bottom: 12px;
}

.c-contacts-modal__main-results-layer-text {
  font-size: 18px;
  letter-spacing: 0.05em;
  color: $brown;
}

.c-contacts-modal__main-step {
  display: none;
  min-height: 200px;
  &.is-active {
    display: block;
  }
}

.c-contacts-modal__main-step-title {
  line-height: 57px;
  font-size: 50px;
  text-align: center;
  font-family: $font-serif;
  color: #88725D;
}

.c-contacts-modal__main-step-actions {
  width: 100%;
  margin-top: 30px;
  @include clearfix();
}

.c-contacts-modal__main-step-choice {
  @include col(1/3);
  text-align: center;
}

.c-contacts-modal__radio {
  display: none;
}

.c-contacts-modal__radio + .c-contacts-modal__radio-label {
  font-size: 22px;
  color: #646464;
  cursor: pointer;
}

.c-contacts-modal__radio + .c-contacts-modal__radio-label span {
  display: inline-block;
  @include size(25px);
  margin: -1px 8px 0 0;
  border: 1px solid #CFC7BF;
  overflow: hidden;
  vertical-align: middle;
  cursor: pointer;
  border-radius:  50%;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    @include size(110%);
    transform: translate(-50%, -50%) scale(0);
    background-color: $brown;
    transition: transform 0.3s ease(snappy);
  }
}

.c-contacts-modal__radio + .c-contacts-modal__radio-label span {
  background-color: $white;
  cursor: pointer;
}

.c-contacts-modal__radio:checked + .c-contacts-modal__radio-label span{
  border-color: $brown;
  &:after {
    transform: translate(-50%, -50%) scale(1);
  }
}

.c-contacts-modal__radio + .c-contacts-modal__radio-label span,
.c-contacts-modal__radio:checked + .c-contacts-modal__radio-label span {
  transition:background-color 0.4s linear;
}

.c-contacts-modal__input {
  display: block;
  background: transparent;
  position: relative;
  width: 100%;
  border: none;
  border-bottom: 2px solid $brown;
  padding: 12px 18px 12px 18px;
  color: #646464;
  font-size: 24px;
  transition: all 0.3s;
  z-index: 1;

  @include placeholder() {
    color: $brown;
    text-transform: uppercase;
    font-size: 24px;
    color: #919191;
    font-weight: 600;
    letter-spacing: 0.05em;
  }

  &:focus {
    outline: none;
    border-color: darken($brown, 20%);
  }
}

.c-contacts-modal__textarea {
  display: block;
  background: transparent;
  position: relative;
  width: 100%;
  border: none;
  border-bottom: 2px solid $brown;
  padding: 12px 18px 12px 18px;
  color: #646464;
  font-size: 24px;
  min-height: 130px;
  transition: all 0.3s;
  z-index: 1;

  @include placeholder() {
    color: $brown;
    text-transform: uppercase;
    font-size: 24px;
    color: #919191;
    font-weight: 600;
    letter-spacing: 0.05em;
  }

  &:focus {
    outline: none;
    border-color: darken($brown, 20%);
  }
}

.c-contacts-modal__main-controls {
  margin-top: 30px;
  @include clearfix();
}

.c-contacts-modal__main-submit {
  text-align: center;
}

.c-contacts-modal__back {
  cursor: pointer;
  bottom: -35px;
}

.c-contacts-modal__main-results-layer-icon {
  @include size(70px);
  border-radius: 50%;
  background-color: $brown;
  margin: 0 auto 24px auto;
  position: relative;
  svg {
    @include align();
    @include size(30px);
    path {
      fill: $white;
    }
  }
  &.is-error {
    .c-contacts-modal__main-results-layer-icon-error {
      display: block;
    }
    .c-contacts-modal__main-results-layer-icon-success {
      display: none;
    }
  }

  &.is-success {
    .c-contacts-modal__main-results-layer-icon-error {
      display: none;
    }
    .c-contacts-modal__main-results-layer-icon-success {
      display: block;
    }
  }
}

.is-contacts-response {
  .c-contacts-modal__main-results-layer {
    transform: translateY(100%);
  }
}

.c-contacts-modal__error {
  font-size: 16px;
  color: #D50000;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-top: 32px;
  margin-bottom: 22px;
  text-align: center;
  display: none;
  &.is-message-error {
    display: block;
  }
}

@include mq-max(860px) {
  .c-contacts-modal__content {
    width: 600px;
  }
}

@include mq-max(660px) {
  .c-contacts-modal__content {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .c-contacts-modal__step-control {
    width: 100%;
  }
}

@include mq-max(600px) {
  .c-contacts-modal__close-container {
    top: 5%;
  }

  .c-contacts-modal__main-step-container {
    margin-top: 25px;
  }

  .c-contacts-modal__textarea {
    min-height: 110px;
  }

  .c-contacts-modal__main-step {
    min-height: 170px;
  }

  .c-contacts-modal__main-submit {
    .c-button-round {
      @include size(75px);
    }
  }

  .c-contacts-modal__input {
    font-size: 16px;
  }

  .c-contacts-modal__textarea {
    font-size: 16px;
  }
}

@include mq-max(480px) {
  .c-contacts-modal__main-step-title {
    font-size: 25px;
    line-height: 35px;
  }

  .c-contacts-modal__step {
    margin-right: 24px;
  }

  .c-contacts-modal__main-step-choice {
    width: 100%;
    text-align: left;
    padding-left: 32%;
  }
}

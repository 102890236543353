.c-quiz {
  position: relative;
  margin: 40px 0;
  left: 50px;
}

.c-quiz__question-container {
  @include span(6/12);
  height: 400px;
  position: relative;
  background-color: #5A9CB8;
}

.c-quiz__question {
  padding: 40px 120px 0 40px;
}

.c-quiz__question-claim {
  line-height: 24px;
  font-size: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-weight: bold;
  color: #eee;
  margin-bottom: 24px;
}

.c-quiz__question-text {
  line-height: 35px;
  font-size: 24px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
}

.c-quiz__question-cta {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 30px;
  background-color: #4B839C;
  font-size: 24px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  cursor: pointer;
  color: #eee;
}

.c-quiz__answers-container {
  @include span(6/12);
  height: 400px;
  background-color: #AAD2E3;
  position: relative;
  top: 50px;
  left: -100px;
}

.c-quiz__answers-img-preview {
  @include size(80%);
  @include align();
  @include bg();
}

.c-quiz__answers {
  @include align();
  margin: 0;
  list-style: none;
  padding: 0;
  width: 85%;
}

.c-quiz-answer{
  position: relative;
  display: block;
  margin-bottom: 22px;
  cursor: pointer;
  @include clearfix();
  &:last-child {
    margin-bottom: 0;
  }

  &.is-active {
    .c-quiz-answer__radio {
      &:after {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }
}

.c-quiz-answer--sm {
  .c-quiz-answer__radio-text {
    font-size: 70px;
    position: relative;
    left: 15px;
  }

  .c-quiz-answer__radio {
    top: 38px;
  }
}

.c-quiz-answer__radio-container {
  float: left;
  width: 8%;
}

.c-quiz-answer__radio-text-container {
  float: left;
  width: 92%;
}

.c-quiz-answer__radio {
  @include size(22px);
  border-radius: 50%;
  background-color: $white;
  margin-right: 12px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    @include size(80%);
    transform: translate(-50%, -50%) scale(0);
    background-color: #4B839C;
    transition: transform 0.3s ease(snappy);
  }
}

.c-quiz-answer__radio-text {
  font-size: 20px;
  color: $white;
  position: relative;
  top: -4px;
}

.c-quiz__submit-answer {
  position: absolute;
  bottom: -30px;
  right: -30px;
}

.c-quiz__right-answer, .c-quiz__reveal-answer {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
  padding: 32px 32px 0 32px;
}

.c-quiz__right-answer-title, .c-quiz__reveal-answer-title {
  line-height: 50px;
  font-size: 40px;
  font-family: $font-serif;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  margin-bottom: 12px;
}

.c-quiz__right-answer-text, .c-quiz__reveal-answer-text {
  font-size: 18px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
}

.c-quiz__right-answer-cta, .c-quiz__reveal-answer-cta {
  display: block;
  margin-top: 24px;

  &:hover {
    .c-quiz__right-answer-cta-text, .c-quiz__reveal-answer-cta-text, .c-quiz__right-answer-cta-icon, .c-quiz__reveal-answer-cta-icon {
      transform: translateX(5px);
    }
  }
}

.c-quiz__right-answer-cta-text, .c-quiz__reveal-answer-cta-text {
  display: inline-block;
  line-height: 22px;
  font-size: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $white;
  font-weight: bold;
  margin-right: 14px;
  transition: transform 0.3s ease(snappy);
}

.c-quiz__right-answer-cta-icon, .c-quiz__reveal-answer-cta-icon {
  display: inline-block;
  @include size(29px);
  background-color: #5A9CB8;
  border-radius: 50%;
  position: relative;
  top: 9px;
  transition: transform 0.3s ease(snappy) 0.1s;

  svg {
    @include size(15px);
    display: block;
    margin: 0 auto;
    position: relative;
    top: 6px;
  }
}

.c-quiz__right-answer-final-cta, .c-quiz_error-answer-final-cta, .c-quiz__reveal-answer-final-cta {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 30px;
  background-color: #5A9CB8;
  font-size: 24px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  cursor: pointer;
  color: #eee;
  z-index: 3;
}

.c-quiz__error-answer {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  @include size(100%);
}

.c-quiz__error-answer-overlay {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
  background-color: rgba(217, 181, 160, 0.61);
  z-index: 1;
}

.c-quiz__error-answer-text {
  @include align();
  width: 80%;
  text-align: center;
  color: $white;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: bold;
  z-index: 2;
}

@include mq-max(992px) {
  .c-quiz__question-container {
    height: 500px;
  }

  .c-quiz__answers-container {
    height: 500px;
  }
}

@include mq-max(870px) {
  // .c-quiz {
  //   left: 35px;
  // }

  // .c-quiz__question-container {
  //   width: 100%;
  //   height: auto;
  // }

  // .c-quiz__question-text {
  //   margin-bottom: 100px;
  //   font-size: 16px;
  //   line-height: 22px;
  // }

  // .c-quiz__answers-container {
  //   width: 95%;
  //   top: 0;
  //   left: -45px;
  //   height: 460px;
  // }

  .c-quiz {
    left: 0;
  }

  .c-quiz__question-container {
    width: 100%;
  }

  .c-quiz__answers-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    
  }

  .c-quiz__answers-img-preview {
    display: none;
  }

  .c-quiz__answers-container {
    height: 0;
    transition: height 0.3s;

    &.visible {
      height: 500px;
    }
  }

  .c-quiz__question {
    padding-right: 40px;
  }

  .c-quiz__right-answer-text {
    font-size: 16px;
  }

  .c-quiz__right-answer-title {
    font-size: 30px;
    line-height: 40px;
  }

  .c-quiz__question-text {
    font-size: 20px;
    line-height: 28px;
  }

  .c-quiz__submit-answer {
    right: 0;
  }
}

@include mq-max(767px) {
  .c-quiz {
    margin: 0;
  }
}
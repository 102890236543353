.c-playlist-modal {
  position: fixed;
  top: 0;
  left: 0;
  @include size(100%);
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.c-playlist-modal__bg {
  position: absolute;
  top: -100%;
  left: 0;
  @include size(100%);
  background-color: $white;
}

.c-playlist-modal__stripe {
  position: absolute;
  top: -100%;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  height: 100%;
  background-color: #FDE2AD;
  z-index: 1;
}

.c-playlist-modal__content {
  width: 95%;
  max-width: 860px;
  margin: 0 auto;
  min-height: 100%;
  position: relative;
  z-index: 5;
}

.c-playlist-modal__content-container {
  width: 100%;
  @include clearfix();
}

.c-playlist-modal__close {
  position: absolute;
  top: 40px;
  right: 40px;
  @include size(55px);
  cursor: pointer;
  opacity: 0;
  border-radius: 50%;
  border: 1px solid $brown;
  text-align: center;
  z-index: 10;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    @include size(110%);
    border-radius: 50%;
    background-color: $dusty-gray;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.3s ease(snappy);
    z-index: 1;
  }

  &:hover {
    &:after {
      transform: translate(-50%, -50%) scale(1);
    }

    .c-playlist-modal__close-bar {
      background-color: $white;
    }
  }
}

.c-playlist-modal__close-bar {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  height: 1px;
  background-color: $dusty-gray;
  transition: background-color 0.3s;
  z-index: 2;

  &:first-of-type {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:last-of-type {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.c-playlist-modal__player {
  width: 90%;
  margin: 30px auto;
}

.c-playlist-modal__player-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.c-playlist-modal__playlist {
  width: 90%;
  margin: 12px auto;
  position: relative;
}

.c-playlist-modal__playlist-slider {
  width: 100%;
}

.c-playlist-modal__playlist-slide {
  @include col(1/3);
}

.c-playlist-modal__playlist-video-preview {
  position: relative;
  cursor: pointer;
}

.c-playlist-modal__playlist-video-preview-image {
  width: 100%;
  height: 150px;
  cursor: pointer;
  @include bg();
  background-color: #ccc;
}

.c-playlist-modal__playlist-video-preview-text {
  font-size: 14px;
  color: $brown;
  font-weight: bold;
  text-align: center;
  margin-top: 12px;
  max-width: 90%;
  @include text-overflow();
}

.c-playlist-modal__playlist-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  @include size(42px);
  border-radius: 50%;
  cursor: pointer;
  background-color: #C7B7A6;
  svg {
    @include size(20px);
    @include align();
    z-index: 2;
  }

  &:before, &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.3s ease(snappy) 0s;
    z-index: 1;
  }

  &:before {
    background-color: $white;
  }

  &:after {
    background-color: $brown;
  }

  &:hover {
    &:before, &:after {
      transform: scale(1);
    }

    &:after {
      transition-delay: 0.2s;
    }
  }
}

.c-playlist-modal__playlist-nav--prev {
  left: -70px;
}

.c-playlist-modal__playlist-nav--next {
  right: -70px;
}

@include mq-min-h(801px) {
  .c-playlist-modal__content-container {
    @include align();
    width: 100%;
  }

  .c-playlist-modal__content {
    max-width: 1024px;
  }
}

@include mq-min(2000px) {
  .c-playlist-modal__content {
    max-width: 1400px;
  }

  .c-playlist-modal__playlist-video-preview-image {
    height: 220px;
  }
}

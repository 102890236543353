.c-linkedin-section {
  padding: 120px 0;
  background-color: $pastel-blue;
  position: relative;
}

.c-linkedin-section__big-text {
  position: absolute;
  bottom: 0;
  left: 0;
  @include size(100%);
  pointer-events: none;
  user-select: none;
  z-index: 1;

  svg {
    @include size(90%);
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.c-linkedin-section__content {
  position: relative;
  z-index: 10;
}

.c-linkedin-section__col {
  float: left;
  width: 400px;
  position: relative;
}

.c-linkedin-section__col-content {
  padding-left: 40px;
}

.c-linkedin-section__block {
  @include size(400px);
  @include bg();
  background-color: #ccc;
  position: relative;
  box-shadow: -31.4676px 20.9784px 94.4028px rgba(0, 0, 0, 0.25);
}

.c-linkedin-section__block-text {
  line-height: 22px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  position: absolute;
  bottom: -80px;
  left: 0;
  width: 100%;
  margin-top: 22px;
  color: #646464;
}

.c-linkedin-section__title {
  line-height: 61px;
  font-size: 47.2014px;
  letter-spacing: 0.05em;
  font-family: $font-serif;
  color: $white;
  margin-top: 22px;
}

.c-linkedin-section__text {
  color: $white;
  line-height: 30px;
  font-size: 18px;
  letter-spacing: 0.04em;
  position: relative;
  top: 130px;
  left: -50px;
}

@include mq-max(1130px) {
  .c-linkedin-section__text {
    left: 0;
    padding-right: 25px;
  }
}

@include mq-max(842px) {
  .c-linkedin-section__col {
    width: 350px;
  }
  .c-linkedin-section__block {
    width: 350px;
    height: 350px;
  }

  .c-linkedin-section__col--mobile-plain {
    width: 100%;
    float: none;

    .c-linkedin-section__text {
      top: 0;
    }
  }

  .c-linkedin-section__col-content {
    padding-left: 0;
  }

  .c-linkedin-section__title {
    line-height: 40px;
    font-size: 30px;
  }

  .c-linkedin-section__text {
    font-size: 16px;
    top: 0;
    margin-bottom: 30px;
  }

  .c-linkedin-section__col--mobile-hide {
    display: none;
  }

  .c-linkedin-section__block-text {
    display: none;
  }
}

@include mq-max(737px) {
  .c-linkedin-section {
    padding: 60px 0;
  }
  .c-linkedin-section__col {
    width: 100%;
  }
  .c-linkedin-section__block {
    width: 100%;
    height: 0;
    padding-bottom: 70%;
  }
}

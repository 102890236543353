.c-subnav {
  position: fixed;
  top: 64px;
  left: 0;
  width: 100%;
  padding: 12px 0;
  z-index: 998;
  .o-container {
    position: relative;
    z-index: 2;
  }

  &.is-scroll {
    .c-subnav__shadow {
      opacity: 1;
    }

    .c-subnav__support, .c-breadcrumb {
      opacity: 1;
      transform: translateY(0);
    }

    .c-subnav__bg {
      opacity: 1;
    }
  }
}

.c-subnav__bg {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
  background-color: #F3F1EC;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1;
}

.c-subnav__shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 20px 40px 0 rgba($black, 0.11);
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1;
}

.c-subnav__support {
  float: left;
  margin: 0;
  padding: 0;
  list-style: none;
  transition: all 0.3s;
  opacity: 0;
  transform: translateY(-12px);
}

.c-subnav__support-item {
  display: inline-block;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
}

.c-subnav__support-link {
  display: inline-block;
  position: relative;
  font-size: 14px;
  letter-spacing: 1px;
  color: $dust;
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px;
    transform: scaleX(0);
    background-color: mix($earth, $white, 43%);
    transform-origin: 100% 100%;
    transition: transform 0.3s ease(snappy);
    z-index: -1;
  }

  &:hover {
    &:before {
      transform: scaleX(1);
      transform-origin: 0 100%;
    }
  }
}

.c-subnav__content {
  width: 100%;
  @include clearfix();
  margin-left: 90px;
}

@include mq-max(1023px) {
  .c-subnav {
    display: none;
  }
}

.c-tabs {
  width: 100%;
  position: relative;
}

.c-tabs__head {
  width: 80%;
  float: right;
  border-bottom: 1px solid $brown;
  position: relative;
}

.c-tabs__menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.c-tabs__item {
  display: inline-block;
  margin: 0;
  text-align: center;
  padding: 12px 20px;
  font-size: 18px;
  text-transform: uppercase;
  color: $earth;
  font-weight: bold;
  letter-spacing: 0.05em;
  cursor: pointer;
  transition: color 0.3s;

  &.is-active, &:hover {
    color: $brown;
  }
}

.c-tabs__body {
  position: relative;
  width: 100%;
  padding: 70px 40px;
  padding-bottom: 0;
  min-height: 300px;
  overflow: hidden;
}

.c-tab {
  width: 100%;
  display: none;
  float: left;
  @include clearfix();

  &.is-active {
    display: block;
  }
}

.c-tab__col {
  @include col(1/3);
}

.c-tab__image {
  @include size(350px);
  @include bg();
  background-color: #ccc;
}

.c-tab__image--trans {
  background-color: transparent;
  background-size: contain;
}

@include mq-max(1180px) {
  .c-tab__image {
    width: 280px;
    height: 280px;
  }
}

@include mq-max(1023px) {
  .c-tab__image {
    width: 280px;
    height: 280px;
  }
}

@include mq-max(997px) {
  .c-tab__col {
    @include col(1/2);
  }
  .c-tab__col:first-child {
    display: none;
  }
}

@include mq-max(829px) {
  .c-tabs__head {
    width: 90%;
  }
}

@include mq-max(767px) {
  .c-tabs__head {
    width: 50%;
  }
  .c-tabs__item {
    display: block;
    text-align: right;
  }

  .c-tab__col {
    width: 100%;
  }
}

@include mq-max(570px) {
  .c-tabs__head {
    width: 80%;
  }
}

.c-policy {
  padding-top: 120px;
}

.c-policy__sidebar {
  @include span(4/12);
  padding-right: 22px;
}

.c-policy__main {
  @include span(8/12);
}

.c-policy__sidebar-links {
  list-style: none;
  margin: 0;
  padding: 0;
}

.c-policy__sidebar-item {
  display: block;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
}

.c-policy__sidebar-link {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: $dust;
  letter-spacing: 0.05em;
}

.c-policy__text-title {
  margin-bottom: 32px;
  line-height: 26px;
  font-size: 16px;
  color: rgba(100, 100, 100, 0.9);
  letter-spacing: 0.05em;
  font-weight: bold;
  margin-top: 16px;
}

.c-policy__text-title--up {
  text-transform: uppercase;
}

.c-policy__text {
  line-height: 26px;
  font-size: 16px;
  color: rgba(100, 100, 100, 0.9);
  letter-spacing: 0.05em;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
}

.c-policy__main-title {
  line-height: 45px;
  font-size: 45px;
  font-family: $font-serif;
  color: #88725D;
  margin-bottom: 42px;
}

.c-policy__mobile-toggle {
  display: none;
}

.c-policy__mobile-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  @include size(100%);
  background-color: rgba($black, 0.45);
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  z-index: -1;
}

.c-policy__sidebar-close {
  display: none;
}

@include mq-max(960px) {
  .c-policy__sidebar-link {
    font-size: 12px;
  }

  .c-policy__text {
    line-height: 22px;
    font-size: 14px;
  }
}

@include mq-max(767px) {
  .c-policy__mobile-toggle {
    display: block;
    margin: 20px 0;
  }

  .c-policy__sidebar-close {
    display: block;
    position: absolute;
    top: 12px;
    right: 12px;
    @include size(50px);
    border-radius: 50%;
    border: 1px solid $brown;
    color: $brown;
    text-align: center;
  }

  .c-policy__sidebar-close-text {
    @include align();
    font-size: 12px;
    color: $brown;
    text-align: center;
    font-weight: bold;
    letter-spacing: 0.05em;
  }

  .c-policy__mobile-overlay {
    display: block;
  }

  .c-policy__sidebar {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: $white;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    padding-right: 0;
    float: none;
    transition: all 0.3s ease(snappy);
    z-index: 1001;
  }

  .c-policy__sidebar-links {
    padding: 50px 20px 20px 20px;
  }

  .c-policy__main {
    width: 100%;
  }

  .is-policy-open {
    .c-policy__sidebar {
      transform: translateX(100%);
    }

    .c-policy__mobile-overlay {
      visibility: visible;
      opacity: 1;
      z-index: 1000;
    }
  }
}

.msc__bg {
  float: left;
  width: 55%;
  height: 550px;
  background-color: #E6E2C4;
  position: relative;
}

.msc__bg--brown {
  background-color: $earth;
}

.msc__bg-image {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  margin: 0 auto;
}

.msc__content {
  float: left;
  width: 45%;
  position: relative;
  top: 50px;
  left: -80px;
}

.msc__small-title {
  margin-top: 20px;
  width: 80%;
}

.msc__text {
  width: 95%;
}

.msc__big-image {
  float: left;
  width: 40%;
  height: 450px;
  @include bg();
  background-color: #ccc;
  position: relative;
  box-shadow: -31.5034px 21.0023px 94.5102px 0 rgba(0, 0, 0, 0.25);
  top: -200px;
}

.msc__cta {
  float: right;
  width: 60%;
  position: relative;
  top: 100px;
  padding-left: 12%;
}

.msc__cta--csr {
  top: -90px;

  .msc__cta-button {
    right: auto;
    left: 260px;
  }
}

.msc__cta--no {
  width: 80%;
  float: none;
}

.msc__cta--org {
  .msc__cta-button {
    right: auto;
    left: -100px;
    bottom: -100px;

    .c-box-cta__button-text {
      bottom: -38px;
    }
  }
}

.msc__cta-text {
  line-height: 90px;
  font-size: 90px;
  color: #E4E4E4;
  font-family: $font-serif;
  position: relative;
  display: inline-block;

  small {
    font-family: $font-family-sans-serif;
    font-size: 20px;
    color: $brown;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    font-style: italic;
    font-weight: lighter;

    &.short{
      font-size: 16px;
      width: 80%;
      margin-top: 10px;
    }
  }
}

.msc__cta-button {
  display: inline-block;
  position: absolute;
  bottom: -70px;
  right: 0;
  opacity: 0.8;
}

.msc__cta--full {
  width: 100%;
}

.msc__cta--contacts {
  float: none;
  top: auto;
  width: 100%;
  text-align: center;

  .msc__cta-text {
    display: block;
  }

  .msc__cta-button {
    display: block;
    position: relative;
    bottom: auto;
    margin-top: 20px;
    right: auto;
  }
}

@include mq-min(1440px) {
  .msc__big-image {
    left: -40px;
  }
}

@include mq-max(1100px) {
  .msc__cta-text {
    font-size: 75px;
    line-height: 80px;
  }
}

@include mq-max(1023px) {
  .msc__bg-image {
    display: none;
  }

  .msc__bg {
    width: 100%;
    position: absolute;
    top: 0; left: 0;
    height: 100%;
    z-index: -1;
  }

  .msc__content {
    float: none;
    width: 100%;
    top: auto;
    left: auto;
    margin-left: 5%;
    margin-right: 5%;
    padding: 40px 0;
  }

  .msc__small-title {
    width: 50%;
  }

  .msc__text {
    width: 50%;
  }

  .msc__main-title {
    width: 80%;
  }

  .msc__big-image {
    position: absolute;
    float: none;
    top: -240px; right: 0;
    height: 350px;
  }

  .msc__cta {
    float: none;
    margin-bottom: 100px;
    top: 40px;
  }

  .msc__cta--org {
    .msc__cta-button {
      right: 20px;
      left: auto;
      bottom: -50px;
    }
  }
}

@include mq-max(997px) {
  .msc__cta {
    padding: 0;
    margin-left: 6%;
  }

  .msc__cta-text {
    font-size: 75px;
    line-height: 65px;
  }

  .msc__cta-button {
    .c-button-round {
      width: 80px;
      height: 80px;
    }
    .c-box-cta__button-text {
      line-height: 18px;
      font-size: 14px;
      bottom: -50px;
    }
  }

  .msc__cta--org {
    .msc__cta-button {
      right: 80px;
      left: auto;
      bottom: -50px;
    }
  }
}

@include mq-max(930px) {
  .msc__cta-text {
    font-size: 60px;

    small {
      font-size: 16px;
    }
  }
}

@include mq-max(800px) {
  .msc__big-image {
    height: 300px;
  }
}

@include mq-max(767px) {
  .msc__cta--org {
    .msc__cta-button {
      right: auto;
      left: 350px;
      bottom: -50px;
    }
  }

  .msc__cta--csr {
    top: 100px;
    margin: auto;
    margin-bottom: 100px;
  }
}

@include mq-max(700px) {
  .msc__small-title {
    width: 80%;
  }
}

@include mq-max(633px) {
  .msc__big-image {
    display: none;
  }
  .msc__big-image-mobile {
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 70%;
    @include bg;
    z-index: 1;
  }
  .msc__text {
    width: 80%;
  }
  .msc__cta {
    margin: auto;
    padding: 40px 0;
    top: 0;
  }

  .msc__cta--org {
    margin-left: 5%;
    .msc__cta-button {
      right: -100px;
      left: auto;
      bottom: -100px;
    }
  }

  .msc__cta--csr {
    top: 100px;
    margin: auto;
    margin-bottom: 100px;
  }
}

@include mq-max(540px) {
  .msc__cta-text {
    font-size: 36px;
    line-height: 36px;
  }

  .msc__cta-button {
    bottom: -35px;
    .c-button-round {
      width: 50px;
      height: 50px;
    }
    .c-box-cta__button-text {
      display: none;
    }
    .c-box-cta__button-text-csr {
      display: block;
      line-height: 9px;
      font-size: 14px;
      bottom: -85px;
      width: 200%;
      a {
        display: block;
        margin-bottom: 7px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .msc__cta--org {
    .msc__cta-button {
      right: -55px;
      left: auto;
      bottom: 12px;
    }
  }
}

@include mq-max(470px) {
  .msc__cta--csr {
    .msc__cta-button {
      left: 200px;
    }
  }
}

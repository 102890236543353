.c-newsletter-form {
  width: 100%;
  display: block;
}

.c-newsletter-form__input-container {
  width: 100%;
  display: block;
  position: relative;
  backface-visibility: hidden;
  overflow: hidden;
  margin-bottom: 8px;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    @include size(100%);
    background-color: #f1f1f1;
    transform: translateY(100%);
    transition: transform 0.3s ease(snappy);
  }

  &:hover {
    &:after {
      transform: translateY(0%);
    }
  }
}

.c-newsletter-form__input {
  display: block;
  background: transparent;
  position: relative;
  width: 100%;
  border: none;
  border-bottom: 2px solid $brown;
  padding: 12px 32px 12px 40px;
  color: #646464;
  font-size: 16px;
  transition: all 0.3s;
  z-index: 1;

  @include placeholder() {
    color: $brown;
    text-transform: uppercase;
    font-size: 16px;
    color: #919191;
    font-weight: 600;
    letter-spacing: 0.05em;
  }

  &:focus {
    outline: none;
    border-color: darken($brown, 20%);
    + .c-newsletter-form__input-icon {
      color: rgba(100, 100, 100, 1);
    }

    ~ .c-newsletter-form__submit {
      transform: rotate(8deg);
      svg {
        path {
          fill: darken($brown, 20%);
        }
      }
    }
  }
}

.c-newsletter-form__input-icon {
  position: absolute;
  top: 0;
  left: 4px;
  font-size: 30px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(100, 100, 100, 0.7);
  transition: all 0.3s ease;
  z-index: 1;
}

.c-newsletter-form__submit {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: transparent;
  transform: rotate(4deg);
  height: 50px;
  transition: transform 0.3s ease(snappy);
  z-index: 1;
  svg {
    path {
      fill: $brown;
      transition: all 0.3s;
    }
  }

  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }
}

.c-newsletter-form__check-control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-top: 15px;
  cursor: pointer;
  color: $dust;
}

.c-newsletter-form__check {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.c-newsletter-form__check-indicator {
  position: absolute;
  top: 0;
  left: 0;
  @include size(18px);
  border: 1px solid #979797;
  transition: border-color 0.3s;
  &:after {
    content: "";
    @include size(10px);
    @include align();
    background-color: $brown;
    opacity: 0;
    transition: opacity 0.3s;
  }
}

.c-newsletter-form__check-text {
  font-size: 12px;
  color: $dust;
  transition: color 0.3s ease;
  a {
    color: $dust;
    text-decoration: underline;
  }
}

.c-newsletter-form__check-color-border {
  border: 1px solid #a7a7a7;
}

.c-newsletter-form__check:checked {
  + .c-newsletter-form__check-indicator {
    border-color: $black;
    &:after {
      opacity: 1;
    }
  }

  ~ .c-newsletter-form__check-text {
    color: $black;
  }
}

.c-newsletter-form__error-message {
  font-size: 12px;
  color: #D50000;
  margin: 4px 0;
  display: none;
  &.is-error {
    display: block;
  }
}

.c-newsletter-form__overlay, .c-newsletter-form__success {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
  background-color: $white;
  opacity: 0;
  transition: all 0.3s;
  z-index: -1;
}

.c-newsletter-form__success-content {
  @include align();
  font-size: 16px;
  color: $green;
  letter-spacing: 0.03em;
  text-align: center;
  font-weight: bold;
  width: 85%;
  line-height: 16px;
  z-index: 10;
}

.c-newsletter-form__success.is-success {
  opacity: 1;
  z-index: 100;
}

.c-newsletter-form.is-loading {
  .c-newsletter-form__overlay {
    opacity: 1;
    z-index: 100;
  }
}

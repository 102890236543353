.o-stats {
  display: block;
  @include size(315px);
  border-radius: 50%;
  border: 10px solid $moon-blue;
  position: relative;
}

.o-stats-section-bottom {
  padding-bottom: 40px;
  position: relative;
  top: -40px;
}

.o-stats__content {
  @include align();
  width: 90%;
  text-align: center;
}

.o-stats__title {
  font-family: $font-serif;
  font-size: 70px;
  line-height: 70px;
  color: $moon-blue;
  margin-bottom: 12px;
}

.o-stats__subtitle {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.05em;
  color: $moon-blue;
}

.o-stats--float {
  position: relative;
  top: 50px;
  left: 40px;
  margin-top: 50px;
}

.o-stats--center {
  margin: 0 auto;
}

@include mq-max(997px) {
  .o-stats {
    @include size(250px);
  }

  .o-stats__title {
    font-size: 55px;
    line-height: 55px;
  }

  .o-stats__subtitle {
    font-size: 18px;
    max-width: 80%;
    margin: auto;
  }
}


@include mq-max(767px) {
  .o-stats {
    @include size(180px);
  }

  .o-stats-section-bottom {
    .o-small-coffee-ill {
      display: none;
    }

    .o-stats {
      float: right;
      top: -40px;
      right: 40px;
    }
  }

  .o-stats__title {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 5px;
  }

  .o-stats__subtitle {
    font-size: 18px;
    max-width: 80%;
    margin: auto;
  }
  
  .o-stats--float {
    position: absolute;
    margin: 0;
    top: 90px;
    left: auto;
    right: 5%;
  }
}

@include mq-max(633px) {
  .o-stats-section-bottom {
    .o-small-coffee-ill {
      display: block;
    }
    .o-stats {
      top: 0;
      right: 40px;
    }
  }
}

@include mq-max(520px) {
  .o-stats {
    @include size(180px);
  }
}

@include mq-max(400px) {
  .o-stats {
    @include size(160px);
  }

  .o-stats__title {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 3px;
  }

  .o-stats__subtitle {
    font-size: 16px;
    max-width: 80%;
    margin: auto;
  }
}
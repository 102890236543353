.c-button {
  display: inline-block;
  background: transparent;
  border: none;
  padding: 12px 30px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  transition: color 0.3s;
  &:focus {
    outline: none;
  }
}

.c-button--fill {
  background-color: $brown;
  color: $white;
  font-size: 14px;
  padding: 16px 32px;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    transform: translateX(-100%);
    background-color: darken($brown, 5%);
    transition: transform 0.32s ease(snappy);
    z-index: 1;
  }
  &:hover {
    &:after {
      transform: translateX(0%);
    }
    .c-button__text, .c-button__icon {
      transform: translateX(4px);
    }
  }
}

.c-button--fill-red {
  background-color: $red;
  &:after {
    background-color: darken($red, 5%);
  }
}

.c-button--fill-green {
  background-color: $vista-green;
  &:after {
    background-color: darken($vista-green, 5%);
  }
}

.c-button__text {
  position: relative;
  display: inline-block;
  transition: transform 0.3s ease(snappy);
  z-index: 2;
}

.c-button__icon {
  display: inline-block;
  position: relative;
  margin-left: 12px;
  transition: transform 0.3s ease(snappy) 0.1s;
  z-index: 2;
  svg {
    @include size(13px);
    path {
      fill: $white;
    }
  }
}

.c-button--stroke {
  color: $brown;
  border: 1px solid $brown;
  background-color: $white;
  &:before, &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    @include size(100%);
    transform: translateY(100%);
    transition: transform 0.32s ease(snappy);
  }

  &:before {
    background-color: $earth;
    z-index: 1;
  }

  &:after {
    background-color: $brown;
    transition-delay: 0.2s;
    z-index: 2;
  }

  &:hover {
    color: $white;
    &:before, &:after {
      transform: translateY(0%)
    }
  }

  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.c-button__content {
  position: relative;
  z-index: 10;
}

.c-button--stroke-red {
  color: $red;
  border: 1px solid $red;
  &:before {
    background-color: mix($red, $white, 50%);
  }

  &:after {
    background-color: $red;
  }
}

.c-button-round {
  display: inline-block;
  color: $white;
  cursor: pointer;
  position: relative;
  @include size(100px);
  border: none;
  border-radius: 50%;
  background-color: $brown;
  position: relative;
  text-align: center;
  svg {
    @include size(25px);
    @include align();
    z-index: 2;
  }

  &:before, &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.3s ease(snappy) 0s;
    z-index: 1;
  }

  &:before {
    background-color: $white;
  }

  &:after {
    background-color: darken($brown, 15%);
  }

  &:hover {
    &:before, &:after {
      transform: scale(1);
    }

    &:after {
      transition-delay: 0.2s;
    }
  }

  &:focus {
    outline: none;
  }

  &.disabled {
    background-color: #ccc;

    &:hover {
      &:before, &:after {
        transform: scale(0);
      }
    }
  }
}

.c-button-round--orange {
  background-color: $orange;

  &:after {
    background-color: darken($orange, 15%);
  }
}

.c-button-round--green {
  background-color: rgba(81, 189, 137, 0.7);

  &:after {
    background-color: darken(rgb(81, 189, 137), 15%);
  }
}

.c-button-round--white {
  background-color: $white;
  &:before {
    background-color: $brown;
  }
  &:after {
    background-color: $white;
  }

  svg {
    path {
      fill: $brown;
    }
  }
}

.c-button-round--shadow {
  box-shadow: -31.4951px 20.9967px 94.4852px 0 rgba(0, 0, 0, 0.25);
}

.c-button-round--olive {
  background-color: $olive;

  &:after {
    background-color: darken($olive, 15%);
  }
}

.c-button-round--red {
  background-color: $red;

  &:after {
    background-color: darken($red, 15%);
  }
}

.c-button-round--dark-green {
  background-color: $green;

  &:after {
    background-color: darken($green, 15%);
  }
}

.c-button-round--blue {
  background-color: #5A9CB8;

  &:after {
    background-color: darken(#5A9CB8, 15%);
  }
}

.c-button-round--small {
  @include size(50px);
}

.c-assistance-modal {
  position: fixed;
  top: 0;
  left: 0;
  @include size(100%);
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.c-assistance-modal__bg, .c-assistance-modal__bg-layer {
  position: absolute;
  top: 100%;
  left: 0;
  @include size(100%);
}

.c-assistance-modal__bg {
  background-color: #EDE8DF;
  z-index: 10;
}

.c-assistance-modal__bg-layer {
  background-color: $earth;
  z-index: 1;
}

.c-assistance-modal__content {
  @include align();
  width: 40%;
  text-align: center;
  opacity: 0;
  z-index: 2;
}

.c-assistance-modal__icon {
  position: absolute;
  top: 35%;
  left: 0;
  opacity: 0;
  @include size(450px);
  pointer-events: none;
  user-select: none;
  transform: translate(-14px, -14px);
  z-index: 1;
  svg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    path {
      fill: #E4DFD6;
    }
  }
}

.c-assistance-modal__close-container {
  position: absolute;
  top: 70px;
  opacity: 0;
  right: 5%;
  z-index: 2;
}

.c-assistance-modal__close {
  @include size(55px);
  border: 2px solid $dusty-gray;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    .c-assistance-modal__close-circle {
      transform: translate(-50%, -50%) scale(1);
    }

    .c-assistance-modal__close-text {
      color: $white;
    }
  }
}

.c-assistance-modal__close-text {
  @include align();
  width: 100%;
  height: 22px;
  text-align: center;
  color: $dusty-gray;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.05em;
  transition: color 0.3s;
  z-index: 2;
}

.c-assistance-modal__close-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  @include size(110%);
  border-radius: 50%;
  background-color: $dusty-gray;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.3s ease(snappy);
  z-index: 1;
}

.c-assistance-modal__title {
  line-height: 51px;
  font-size: 70px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  color: #646464;
  margin-bottom: 32px;
}

.c-assistance-modal__text {
  line-height: 27px;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-weight: bold;
  color: #646464;
}

.c-assistance-modal__call {
  margin-top: 54px;
}

.c-assistance-modal__call-claim {
  line-height: 32px;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  font-weight: bold;
  text-transform: uppercase;
  color: #646464;
  margin-bottom: 18px;
}

.c-assistance-modal__call-number {
  line-height: 51px;
  font-size: 70px;
  text-align: center;
  color: #646464;
  font-weight: 500;
}

.is-site-darken {
  .c-assistance-modal__bg {
    background-color: darken(#EDE8DF, 20%);
  }

  .c-assistance-modal__bg-layer {
    background-color: $dk-earth;
  }

  .c-assistance-modal__icon {
    svg {
      path {
        fill: darken(#E4DFD6, 20%);
      }
    }
  }

  .c-assistance-modal__close {
    border: 2px solid $dk-dusty-gray;
    &:hover {
      .c-assistance-modal__close-text {
        color: $dk-white;
      }
    }
  }

  .c-assistance-modal__close-text {
    color: $dk-dusty-gray;
  }

  .c-assistance-modal__close-circle {
    background-color: $dk-dusty-gray;
  }

  .c-assistance-modal__title {
    color: $dk-dust;
  }

  .c-assistance-modal__text {
    color: $dk-dust;
  }

  .c-assistance-modal__call-claim {
    color: $dk-dust
  }

  .c-assistance-modal__call-number {
    color: $dk-dust;
  }
}


@include mq-max(900px) {
  .c-assistance-modal__content {
    width: 60%;
    transform: translate(-50%, -50%) !important;
  }
}

@include mq-max(690px) {
  .c-assistance-modal__content {
    width: 80%;
  }
}

@include mq-max(600px) {
  .c-assistance-modal__close-container {
    top: 5%;
  }

  .c-assistance-modal__title {
    font-size: 50px;
    line-height: 35px;
  }

  .c-assistance-modal__call-number {
    font-size: 50px;
  }

  .c-assistance-modal__call {
    margin-top: 25px;
  }
}

@include mq-max(415px) {
  .c-assistance-modal__title {
    font-size: 40px;
  }

  .c-assistance-modal__call-number {
    font-size: 40px;
  }

}

.c-main-carousel__container {
  margin-top: 70px;
}

.c-main-carousel {
  width: 960px;
  margin: 0 auto;
  position: relative;

  .flickity-viewport {
    z-index: 1;
  }
}

.c-main-carousel__slide {
  width: 100%;
  height: 460px;
  position: relative;
}

.c-main-carousel__bg {
  position: absolute;
  top: 0;
  left: 0;
  @include size(90%, 100%);
  background-color: $earth;
  will-change: transform;
  z-index: 3;
}

.c-main-carousel__main-title {
  line-height: 1;
  font-size: 120px;
  font-family: $font-serif;
  font-weight: 400;
  letter-spacing: -0.02em;
  position: relative;
  color: $brown;
  height: 130px;
  overflow: hidden;
}

.c-main-carousel__fixed-text {
  line-height: 34px;
  font-size: 22;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 22px;
  color: #646464;
}

.c-main-carousel__main-subtitle {
  font-size: 45px;
  font-weight: 400;
  line-height: 1;
  color: $brown;
  position: relative;
  height: 49px;
  overflow: hidden;
}

.c-main-carousel__main-title-text, .c-main-carousel__main-subtitle-text {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
}

.c-main-carousel__main-title-text--next, .c-main-carousel__main-subtitle-text--next {
  top: 100%;
}

.c-main-carousel__main-image {
  position: absolute;
  bottom: -75px;
  right: 0;
  @include size(400px, 250px);
  @include bg();
  background-color: #ccc;
  overflow: hidden;
  box-shadow: -31.4951px 20.9967px 94.4852px 0 rgba(0, 0, 0, 0.25);
  z-index: 10;
}

.c-main-carousel__main-image-slider {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
}

.c-main-carousel__main-image-slide {
  @include size(100%);
  position: relative;
  overflow: hidden;
}

.c-main-carousel__main-image-slide-bg {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
  @include bg();
  background-color: #ccc;
}

.c-main-carousel__fixed-content {
  position: absolute;
  top: 40%;
  left: -60px;
  width: 300px;
  z-index: 10;
}

.c-main-carousel__content {
  position: absolute;
  top: 80px;
  right: 0;
  width: 500px;
  z-index: 10;
}

.c-main-carousel__nav {
  position: absolute;
  top: -40px;
  right: -5%;
  width: 200px;
  overflow: hidden;
  border: 2px solid rgba(136, 114, 93, 0.2);
  z-index: 2;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    background-color: $white;
    transform: scaleX(0);
    transition: transform 0.3s ease(snappy);
    transform-origin: 100% 100%;
  }

  &:hover {
    &:after {
      transform: scaleX(1);
      transform-origin: 0 100%;
    }
  }
}

.c-main-carousel__nav-button {
  float: right;
  height: 80px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 10px;
  transition: all 0.3s ease(snappy);
  backface-visibility: hidden;
  z-index: 1;

  &:last-child {
    margin-left: 40px;
  }

  svg {
    @include size(45px);
    path {
      stroke: $brown;
    }
  }

  &:hover {
    transform: scale(1.1);
  }
}

.c-main-carousel__img-cta {
  position: absolute;
  bottom: -80px;
  right: -70px;
  z-index: 100;
}

.c-main-carousel--no-titles {
  .c-main-carousel__fixed-content {
    top: 50%;
    left: 50px;
    transform: translateY(-50%);
  }

  .c-main-carousel__main-image {
    bottom: -35px;
  }
}

.c-main-carousel--orange {
  .c-main-carousel__bg {
    background-color: #F7BD79;
  }

  .c-main-carousel__fixed-text {
    color: $white;
  }

  .c-section-claim {
    background-color: #E0AD70 !important;
  }
}


@include mq-max(1100px) {
  .c-main-carousel__fixed-content {
    left: 30px;
  }

  .c-main-carousel__nav {
    right: 130px;
    z-index: 3;
    background-color: white;
  }

  .c-main-carousel__img-cta {
    right: -20px;
  }

}

@include mq-max(995px) {
  .c-main-carousel__main-image {
    right: 130px;
  }

  .c-main-carousel__content {
    right: 120px;
  }

  .c-main-carousel__img-cta {
    right: 120px;
  }

  .o-quote {
    width: 100%;
  }

}

@include mq-max(940px) {
  .c-main-carousel__content {
    right: auto;
    left: 30px;
  }

  .c-main-carousel {
    width: 100%;
  }

  .c-main-carousel__fixed-text {
    display: none;
  }

  .c-main-carousel__fixed-content {
    top: auto;
    bottom: -165px;
    left: auto;
    right: 0;
  }

  .c-main-carousel {
    margin-bottom: 80px;
  }
}

@include mq-max(875px) {
  .c-social-wall__row {
    margin-top: 0 !important;
  }
}

@include mq-max(767px) {
  .c-hero__float-content, .c-hero-float-carousel {
    right: 0;
  }

  .c-main-carousel__bg {
    width: 100%;
  }

  .c-main-carousel__main-image {
    right: 0;
  }
}

@include mq-max(640px) {
  .c-main-carousel__main-title {
    height: 49px;
  }

  .c-main-carousel__main-title-text {
    font-size: 40px;
    line-height: 1;
  }

  .c-main-carousel__content {
    width: 88%;
  }

  .c-main-carousel__main-image {
    bottom: -35px;
  }
}

@include mq-max(520px) {
  .c-main-carousel__main-subtitle-text {
    font-size: 20px;
  }
}

@include mq-max(470px) {
  .c-main-carousel__main-image {
    width: 300px;
    height: 200px;
  }
}


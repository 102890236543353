.o-big-text__container {
  position: relative;
  left: -10%;
}

.o-big-title {
  line-height: 290px;
  font-size: 365px;
  color: rgba(136, 114, 93, 0.22);
  font-weight: 300;
  user-select: none;
}

.o-big-subtitle {
  line-height: 40px;
  font-size: 54px;
  letter-spacing: -0.02em;
  font-style: italic;
  color: rgba(136, 114, 93, 0.25);
  padding-top: 16px;
  padding-left: 140px;
  user-select: none;
}

.o-section-center-title {
  font-size: 36px;
  line-height: 1;
  text-align: center;
  font-family: $font-serif;
  margin: 0;
  letter-spacing: 0.04em;
  color: rgba(100, 100, 100, 0.7);
}

.o-section-center-divider {
  width: 120px;
  margin: 30px auto;
  height: 2px;
  background-color: $brown;
}

.o-section-main-title {
  line-height: 59px;
  font-size: 60px;
  letter-spacing: 0.04em;
  color: #646464;
}

.o-section-main-text {
  line-height: 54px;
  font-size: 28px;
  letter-spacing: 0.05em;
  color: #646464;
}

.o-section-mid-title {
  line-height: 44px;
  font-size: 44px;
  letter-spacing: 0.04em;
  color: #646464;
}

.o-section-text {
  line-height: 34px;
  font-size: 18px;
  letter-spacing: 0.05em;
  color: #646464;

  &:last-child {
    margin-top: 8px;
  }
}

.o-section-text--mb {
  margin-bottom: 34px;
}

.o-section-small-title {
  line-height: 30px;
  font-size: 18px;
  letter-spacing: 0.05em;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 22px;
  color: #646464;
}

.o-section-small-title--white {
  color: $white;
}

.o-section-small-title--orange {
  color: $orange;
}

.o-section-small-title--brown {
  color: $brown;
}

.o-section-small-text {
  line-height: 31px;
  font-size: 20px;
  letter-spacing: 0.05em;
  color: #646464;
  margin-bottom: 16px;
  strong {
    color: #4F4F4F;
    text-decoration-line: underline;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.o-section__hero-text {
  line-height: 43px;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #646464;
  text-align: center;
  width: 90%;
  margin: 0 auto;
}

// .c-section-claim {
//   display: inline-block;
//   padding: 13px 50px;
//   text-align: center;
//   color: $white;
//   line-height: 25px;
//   font-size: 16px;
//   letter-spacing: 0.05em;
//   background-color: $dusty-gray;
//   text-transform: uppercase;
//   font-weight: bold;
//   max-width: 250px;
//   margin-bottom: 16px;
// }

// .c-section-claim--red {
//   background-color: $red;
// }

// .c-section-claim--blue {
//   background-color: $moon-blue;
// }

// .c-section-claim--olive {
//   background-color: #8D9C40;
// }

// .c-section-claim--green-light {
//   background-color: #A6B2AA;
// }

// .c-section-claim--dark-yellow {
//   background-color: #E9A658;
// }

.c-section-claim--small {
  padding: 8px 42px;
}

.c-section-claim--auto {
  max-width: none;
}

.c-section__qt-quote-container {
  position: relative;
  z-index: 1;
}

.c-section__qt-content-container {
  position: relative;
  z-index: 2;
}

.c-section__qt-quote {
  background-color: $red;
  width: 60%;
  float: right;
  position: relative;
  padding: 80px 0;
}

.c-section__qt-quote--gray {
  background-color: #A6A6B2;
}

.c-section__qt-quote--orange {
  background-color: #F7BD79;
}

.c-section__qt-quote--green {
  background-color: #80BC00; //#51BD89;
}

.c-section__qt-quote-text {
  color: #EDE8DF;
  font-family: $font-serif;
  line-height: 60px;
  width: 80%;
  margin-left: 10%;
  font-size: 60px;
}

.c-section__qt-content {
  width: 85%;
  background-color: #EDE8DF;
  padding-top: 280px;
  top: -200px;
  padding-bottom: 120px;
  padding-left: 60px;
  padding-right: 60px;
  position: relative;
}

.c-section__qt-content-image {
  position: absolute;
  width: 400px;
  height: 400px;
  @include bg();
  background-color: #ccc;
  box-shadow: -31.5034px 21.0023px 94.5102px 0 rgba(0, 0, 0, 0.25);
  bottom: 0;
  left: 40px;
}

.c-section__qt-content-image2 {
  width: 100%;
  height: auto;
  // @include bg();
  // background-color: #ccc;
  margin-bottom: 30px;
}

.c-section__qt-quote-csr-image {
  position: absolute;
  top: 50%;
  right: 95%;
  transform: translateY(-50%);

}

.c-section__qt-quote-csr-machine-image {
  position: absolute;
  top: 50%;
  right: 97%;
  transform: translateY(-50%);
  max-width: 65%;
}

.c-section__qt-content-title-zone {
  @include col(1/2);
}

.c-section__qt-content-text-zone {
  @include col(1/2);
}

@include mq-max(1100px) {
  .o-big-title {
    font-size: 300px;
  }

  .o-big-subtitle {
    font-size: 42px;
    padding-top: 0;
  }
}

@include mq-max(1060px) {
  .c-section__qt-content-title-zone {
    width: 100%;
  }

  .c-section__qt-content-text-zone {
    width: 100%;
  }

  .c-section__qt-content-image {
    display: none;
  }
}

@include mq-max(1023px) {
  .c-section__qt-quote {
    padding: 45px 0;
  }
  .c-section__qt-quote-text {
    line-height: 40px;
    width: 90%;
    margin-left: 5%;
    font-size: 40px;
  }

  .o-section__hero-text {
    line-height: 28px;
    font-size: 18px;
  }

  .o-section-main-title {
    line-height: 40px;
    font-size: 40px;
  }

  .c-section__qt-quote-csr-image {
    display: none;
  }

  .c-section__qt-content {
    margin-bottom: -200px;
  }
}

@include mq-max(997px) {
  .o-big-text__container {
    display: none;
  }
}

@include mq-max(892px) {
  .o-section-main-text {
    line-height: 40px;
  }
}

@include mq-max(767px) {
  .c-section__qt-quote {
    width: 100%;
  }

  .c-section__qt-content {
    width: 100%;
    padding-top: 40px;
    top: auto;
    padding-bottom: 60px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .c-section__qt-quote-csr-machine-image {
    position: relative;
    top: auto;
    right: auto;
    transform: none;
  }

  .c-section__qt-content-title-zone, .c-section__qt-content-text-zone {
    width: 100%;
    margin: 0;
  }

  .o-big-text__container {
    left: 0;
    // text-align: center;
    margin-bottom: 40px;
  }

  .o-section__hero-text {
    line-height: 24px;
    font-size: 16px;
  }

  .o-section-main-title {
    line-height: 32px;
    font-size: 32px;
  }

  .o-section-mid-title {
    line-height: 30px;
    font-size: 30px;
  }
}

@include mq-max(640px) {
  .o-section-main-text {
    line-height: 25px;
    font-size: 18px;
  }

  .c-section__qt-quote {
    padding: 35px 0;
  }

  .c-section__qt-quote-text {
    line-height: 33px;
    width: 95%;
    margin-left: 5%;
    font-size: 30px;
  }

  .o-section-small-title {
    line-height: 25px;
    font-size: 14px;
    margin-bottom: 16px;
  }

  .o-section-small-text {
    line-height: 25px;
    font-size: 14px;
    margin-bottom: 14px;
  }

  .o-section__hero-text {
    line-height: 31px;
    font-size: 16px;
  }

  .o-section-text {
    line-height: 25px;
    font-size: 16px;
  }
}


.c-video-preview {
  width: 960px;
  margin: 0 auto;
  position: relative;
}

.c-video-preview__bg {
  width: 90%;
  height: 480px;
  background-color: $earth;
}

.c-video-preview__main-title {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 180px;
  font-family: $font-serif;
  z-index: 10;
}

.c-video-preview__main-image {
  position: absolute;
  bottom: -50px;
  right: 0;
  cursor: pointer;
  @include size(400px, 250px);
  @include bg();
  background-color: #ccc;
  box-shadow: -31.4951px 20.9967px 94.4852px 0 rgba(0, 0, 0, 0.25);
  z-index: 10;

  &:hover {
    .c-video-preview__main-image-button {
      opacity: 0.4;
    }
  }
}

.c-video-preview__main-image-button {
  @include size(70px);
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  @include align();
  border: 2px solid $white;
  z-index: 10;
  transition: opacity 0.3s;

  svg {
    display: block;
    margin: 0 auto;
    @include size(25px);
    path {
      fill: $white;
    }
  }
}

.c-video-preview__main-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
  background: linear-gradient(180deg, #333333 0%, rgba(51, 51, 51, 0.5) 100%);
  z-index: 5;
}

.c-video-preview__side-text {
  position: absolute;
  top: 50%;
  left: -30px;
  width: 350px;
  line-height: 34px;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transform: translateY(-50%);
  color: #646464;
  z-index: 10;
}

.c-video-preview__cta {
  position: absolute;
  bottom: -50px;
  left: 20%;
  z-index: 10;
}

.c-video-preview--blue {
  .c-video-preview__bg {
    background-color: $pastel-blue;
  }

  .c-video-preview__main-title {
    color: #7FA4B2;
  }
}

.c-video-preview--green {
  .c-video-preview__bg {
    background-color: #C8D1C9;
  }

  .c-video-preview__main-title {
    color: #A6B2AA;
  }
}

.c-video-preview--orange {
  .c-video-preview__bg {
    background-color: #E9A658;
  }

  .c-video-preview__main-title {
    color: #C48945;
  }
}

.c-video-preview--red {
  .c-video-preview__bg {
    background-color: #F69179;
  }

  .c-video-preview__main-title {
    color: $red;
  }
}

.c-video-preview--yellow {
  .c-video-preview__bg {
    background-color: #FDE2AD;
  }

  .c-video-preview__main-title {
    color: darken(#FDE2AD, 20%);
  }
}

@include mq-max(1023px) {
  .c-video-preview__side-text {
    left: 12px;
    width: 300px;
    font-size: 16px;
    line-height: 25px;
  }

  .c-video-preview {
    width: 100%;
  }

  .c-video-preview__main-title {
    font-size: 140px;
    right: 18%;
  }

  .c-video-preview__cta {
    display: none;
  }

  .c-video-preview__bg {
    width: 100%;
    height: 400px;
  }

  .c-video-preview__main-image {
    right: 10%;
  }
}

@include mq-max(767px) {
  .c-video-preview__side-text {
    display: none;
  }

  .c-video-preview__main-title {
    font-size: 110px;
    right: auto;
    left: 10%;
    width: 85%;
  }

  .c-video-preview__main-image {
    width: 90%;
    height: 300px;
    right: 0;
  }
}

@include mq-max(540px) {
  .c-video-preview__main-title {
    font-size: 80px;
  }
}

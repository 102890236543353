.c-timeline {
  position: relative;
  padding: 40px 0;
}

.c-timeline__ball-zone {
  position: relative;

  &:before, &:after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @include size(50%, 4px);
    background-color: $brown;
    z-index: 1;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 100%;
  }
}

.c-timeline__ball {
  @include size(150px);
  border-radius: 50%;
  background-color: $brown;
  text-align: center;
  position: relative;
  margin: 0 auto 24px auto;
  transition: transform 0.3s ease(snappy), background-color 0.3s;
  z-index: 10;
}

.c-timeline__title {
  line-height: 187px;
  font-family: $font-serif;
  color: #EDE8DF;
  line-height: 29px;
  font-size: 40px;
  @include align();
  text-align: center;
  width: 90%;
}

.c-timeline__text {
  line-height: 18px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  opacity: 0;
  transform: translateY(-12px);
  transition: all 0.3s;
  color: #88725D;
}

.c-timeline__slide {
  width: 20%;
  padding: 40px 0;
  position: relative;
}

.c-timeline__nav {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  @include size(60px);
  cursor: pointer;
  background-color: $white;
  border-radius: 50%;
  box-shadow: -30px 20px 90px rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
  z-index: 100;

  svg {
    @include align();
    @include size(35px);
  }

  &:hover {
    transform: translateY(-50%) scale(1.1);
  }
}

.c-timeline__nav--prev {
  left: 0;
}

.c-timeline__nav--next {
  right: 0;
}

@include mq-min(1024px) {
  .c-timeline__slide {
    &:hover {
      .c-timeline__text {
        opacity: 1;
        transform: translateY(0);
      }

      .c-timeline__ball {
        transform: scale(1.1);
        background-color: $red;
      }
    }
  }
}

@include mq-max(1023px) {
  .c-timeline__slide {
    width: 33.33333%;
    &.is-selected {
      .c-timeline__text {
        opacity: 1;
        transform: translateY(0);
      }

      .c-timeline__ball {
        transform: scale(1.1);
        background-color: $red;
      }
    }
  }

  .c-timeline__nav {
    display: none;
  }
}

@include mq-max(767px) {
  .c-timeline__slide {
    width: 100%;
  }

  .c-timeline__ball {
    @include size(220px);
  }
}

.c-gsb {
  position: relative;
  margin-bottom: 100px;
}

.c-gsb__image-zone {
  @include col(1/2);
  position: relative;
}

.c-gsb__image {
  @include size(600px, 450px);
  @include bg();
  background-color: #ccc;
  z-index: 2;
}

.c-gsb__image-block {
  position: absolute;
  bottom: -60px;
  right: -30px;
  @include size(200px);
  background: $brown;
  box-shadow: -31.5034px 21.0023px 94.5102px rgba(0, 0, 0, 0.25);
  z-index: -1;
}

.c-gsb__image-block--blue {
  background-color: #7488A1;
}

.c-gsb__image-block--olive {
  background-color: $olive;
}

.c-gsb__content-zone {
  @include col(1/2);
  position: relative;
  padding-left: 40px;
  top: 80px;
}

.c-gsb--right {
  .c-gsb__image-block {
    right: auto;
    left: -30px;
  }
}

@include mq-max(1220px) {
  .c-gsb__content-zone {
    padding-right: 20px;
  }

  .c-gsb__image {
    width: 550px;
  }
}

@include mq-max(1110px) {
  .c-gsb__image {
    width: 500px;
  }
}

@include mq-max(1000px) {
  .c-gsb__image {
    width: 450px;
  }
}

@include mq-max(900px) {
  .c-gsb__image {
    width: 400px;
  }
}

@include mq-max(800px) {
  .c-gsb__image {
    width: 350px;
  }
}

@include mq-max(767px) {
  .c-gsb__image {
    width: 100%;
    height: 400px;
  }

  .c-gsb__image-zone {
    width: 100%;
  }

  .c-gsb--right {
    .c-gsb__content-zone {
      top: auto;
      margin-bottom: 30px;
    }
  }

  .c-gsb__content-zone {
    width: 100%;
    padding: 0;
    padding-left: 5%;
    padding-right: 5%;
  }
}

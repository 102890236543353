.c-page-transition {
  position: fixed;
  top: 100%;
  left: 0;
  @include size(100%);
  overflow: hidden;
  background-color: $white;
  z-index: -1;
}

.c-page-transition__content {
  @include align();
  @include size(250px, 300px);
  overflow: hidden;
}

.c-page-transition__logo {
  margin-bottom: 35px;
  position: relative;
  top: 30px;
  z-index: 1;
  svg {
    @include size(150px, 116px);
    display: block;
    margin: 0 auto;
  }
  img {
    width: 150px;
    display: block;
    margin: 0 auto;
  }
}

.c-page-transition__logo-overlay {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
  background-color: $white;
  z-index: 2;
}

/* General layout */

#page-wrap {
  width: 100%;
  min-height: 100%;
  @include clearfix();
}

.o-container {
  width: 90%;
  margin: 0 auto;
  @include clearfix();
  @include mq-min(1440px) {
    width: 1280px;
  }
  @include mq-max(bk("desk")) {
    width: 90%;
  }
}

.o-container-block {
  width: 95%;
  max-width: 960px;
  margin: 0 auto;
  @include clearfix();
  @include mq-max(bk("desk")) {
    width: 90%;
    overflow-x: hidden;
  }
}

.o-container-full {
  width: 100%;
  margin: 0 auto;
  @include clearfix();
  @include mq-min(1440px) {
    width: 1280px;
  }
}

.o-container-fluid, .c-main {
  width: 100%;
  position: relative;
  @include clearfix();
}

.o-section {
  padding: 60px 0;
}

.o-section--org {
  margin-bottom: 120px;
}

.o-section--with-float-b {
  padding-bottom: 200px;
}

.o-section--pt-0 {
  padding-top: 0 !important;
}

.o-section--pb-0 {
  padding-bottom: 0 !important;
}

.o-section--pb-1 {
  padding-bottom: 20px !important;
}

.o-section--pb-2 {
  padding-bottom: 40px !important;
}

.o-section-cta {
  margin-top: 40px;
}

.c-main--with-float {
  padding-top: 10vh;
}

.o-section--light-brown {
  background-color: $pampas;
}

.o-section-main-divider {
  width: 200px;
  margin: 30px 0;
  height: 2px;
  background-color: $dusty-gray;
}

.o-home-row {
  position: relative;
}

.o-home-col {
  @include col(1/2);
}

.o-home-col--text-float {
  position: relative;
  top: 50px;
}

.o-home-col--vending-float {
  position: relative;
  top: -200px;
}

.o-home-text-container {
  width: 60%;
  margin-top: 80px;
}

.o-home__history {
  padding-top: 100px;
  padding-bottom: 140px;
}

.o-chi-row {
  position: relative;
}

.o-chi-col {
  @include col(1/2);

  .o-section-main-text {
    padding-top: 80px;
  }
}

.o-chi-col--vending-float {
  position: relative;
  top: -200px;
}

.o-chi-text-container {
  width: 90%;
}

.o-chi__history {
  position: relative;
  padding-top: 140px;
  margin-top: 120px;
}

.o-chi__history-years {
  position: absolute;
  top: 0; left: 0;
  z-index: -1;
  width: 100%;
  font-family: Harriet Display;
  line-height: 239px;
  font-size: 220px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #EDE8DF;
}

.o-chi__history-info-cta {
  display: block;
  position: relative;
  @include size(200px);
  background-color: $orange;
  border-radius: 50%;
}

.o-chi__history-tappo {
  position: absolute;
  bottom: -105px; left: -105px;
  width: 240px;
}

.o-chi__history-description {
  font-family: Apercu;
  line-height: 40px;
  font-size: 24px;
  letter-spacing: 0.05em;
  color: #646464;
  margin-top: 80px;
}

.o-chi__history-info-cta-content {
  @include align();
  width: 70%;
  text-align: center;
}

.o-chi__history-info-cta-title {
  line-height: 20px;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-weight: bold;
  color: #EDE8DF;
}

.o-chi__history-info-button {
  position: absolute;
  bottom: -45px;
  right: -40px;
  opacity: 0.8;
  z-index: 10;
}

.o-csr-row {
  padding: 40px 0;
}

.o-csr-col, .o-project-col {
  @include col(1/2);
}

.o-csr-image-col {
  @include col(4/12);
}

.o-csr-image {
  @include size(300px);
  @include bg();
  background-color: #c4c4c4;
  margin-top: 100px;
}

.o-csr-info-col {
  @include col(8/12);
}

.o-sede-lounge {
  position: relative;
  top: 150px;
  left: 40%;
  @include size(200px);
  border-radius: 50%;
  background: #C7B7A6;
  box-shadow: -30px 20px 90px rgba(0, 0, 0, 0.25);
}

.o-sede-lounge__image {
  @include align();
  width: 70%;
}

.o-story-col {
  @include col(1/2);
}

.o-story-first-section {
  padding-top: 120px;
}

.o-story__trophy {
  @include size(300px);
  border-radius: 50%;
  margin: 0 auto;
  @include bg();
  background-color: #ccc;
}

.o-story-title-section {
  padding: 110px 0;
}

.o-story-title {
  margin-bottom: 22px;
}

.o-home-big-text {
  line-height: 127px;
  font-size: 174.274px;
  letter-spacing: -0.02em;
  color: rgba(136, 114, 93, 0.1);
  pointer-events: none;
  user-select: none;
  position: absolute;
  font-style: italic;
  top: 0;
  left: 0;
  z-index: 0;

  span {
    display: inline-block;
    position: relative;
    left: 60px;
    top: 40px;
  }
}

.o-chi-anim-image {
  @include size(350px);
  @include bg();
  background-size: contain;
  position: relative;
}

.o-home-product-cta {
  position: relative;
  width: 100%;
  height: 500px;
  top: -105px;
}

.o-home-product-cta__round {
  @include size(250px);
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 30%;
  background-color: #729971;
}

.o-home-product-cta__round-text {
  @include align();
  text-align: center;
  font-size: 24px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-weight: bold;
  color: #FFFFFF;
}

.o-home-product-cta__round-button {
  position: absolute;
  bottom: -40px;
  right: 0;
}

.o-home-product-cta__first-leaf {
  position: absolute;
  top: -40px;
  left: 20%;
  background-repeat: no-repeat;
  background-size: contain;
  @include size(160px);
  transform: rotate(-50deg);
  z-index: 2;
}

.o-home-product-cta__second-leaf {
  position: absolute;
  bottom: 20px;
  right: 20%;
  background-repeat: no-repeat;
  background-size: contain;
  @include size(210px);
  z-index: 3;
}

.o-home-product-cta__strawberry {
  position: absolute;
  top: 20px;
  right: 5%;
  background-repeat: no-repeat;
  background-size: contain;
  @include size(120px, 200px);
  z-index: 2;
}

.o-home-product-cta__first-more {
  position: absolute;
  top: 190px;
  right: 17%;
  background-repeat: no-repeat;
  background-size: contain;
  @include size(50px);
  z-index: 2;
}

.o-home-product-cta__second-more {
  position: absolute;
  top: 235px;
  right: 7%;
  background-repeat: no-repeat;
  background-size: contain;
  @include size(50px);
  z-index: 2;
}

.o-home-product-cta__lemon {
  position: absolute;
  bottom: 20px;
  right: 34%;
  background-repeat: no-repeat;
  background-size: contain;
  @include size(210px);
  z-index: 2;
}

.o-home-coffee {
  position: relative;
  width: 80%;
  top: -40px;
  height: 320px;
  margin: 0 auto;
}

.o-home-coffee__first-el {
  position: absolute;
  top: 17%;
  left: 5%;
  @include size(110px);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.o-home-coffee__second-el {
  position: absolute;
  top: -15%;
  right: 38%;
  @include size(110px);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.o-home-coffee__third-el {
  position: absolute;
  top: 63%;
  left: 10%;
  @include size(110px);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.o-home-coffee__fourth-el {
  position: absolute;
  top: 36%;
  left: 35%;
  @include size(110px);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.o-small-coffee-ill {
  position: relative;
  width: 50%;
  float: left;
  top: -40px;
  height: 200px;
}

.o-small-coffee-ill--right {
  float: right;
}

.o-small-coffee-ill__first-el {
  position: absolute;
  top: 32%;
  left: 25%;
  @include size(90px);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.o-small-coffee-ill__second-el {
  position: absolute;
  top: 80%;
  left: 44%;
  @include size(90px);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.o-small-coffee-ill__third-el {
  position: absolute;
  top: 39%;
  left: 61%;
  @include size(90px);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.o-story-video-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  @include size(100%);
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    margin: auto;
  }
}

@include mq-max(1100px) {
  .o-home-text-container {
    width: 90%;
  }

  .o-home-product-cta__strawberry {
    display: none;
  }

  .o-home-product-cta__first-more {
    display: none;
  }

  .o-home-product-cta__second-more {
    display: none;
  }
}

@include mq-max(1023px) {
  .o-chi__history-years {
    font-size: 160px;
  }
}

@include mq-max(997px) {
  .o-no-tablet {
    display: none;
  }

  .o-full-tablet {
    width: 100%;
  }

  .o-home-col--vending-float {
    top: 0;
  }

  .o-home-col--text-float {
    top: 0;
    margin-bottom: 80px;
  }

  .o-home-big-text {
    font-size: 140px;
  }

  .o-chi-col {
    .o-section-main-text {
      padding-top: 0;
    }
  }

  .o-chi-col--vending-float {
    top: 0;
  }

  .o-story-first-section {
    .c-image-carousel__slider {
      width: 100%;
      height: 400px;
      bottom: 0;
      right: 0;
    }

    .c-image-carousel__navigation {
      left: auto;
      right: 5%;
    }
  }
}

@include mq-max(892px) {
  .o-home-text-container {
    width: 70%;
  }
  .o-chi-text-container {
    width: 70%;
  }
}

@include mq-max(800px) {
  .o-csr-section {
    padding-top: 0 !important;
  }
}

@include mq-max(767px) {
  .o-no-mobile {
    display: none;
  }
  .o-story-col {
    width: 100%;
    margin-bottom: 30px;
    &:last-child {
      margin-top: 50px;
    }
  }
  .o-full-mobile {
    width: 100%;
  }

  .c-main--with-float {
    padding-top: 15vh;
  }

  .o-home-col {
    width: 100%;
    margin: 0;
  }

  .o-home-coffee {
    display: none;
  }

  .o-home-text-container {
    margin-top: 40px;
    margin-bottom: 80px;
    width: 100%;
  }

  .o-home-col--coffee-shop {
    margin-left: 185px;
    margin-bottom: 80px;
    width: auto;
  }

  .o-home-product-cta {
    top: auto;
    margin: 70px 0 40px 0;
  }

  .o-home__history {
    display: none;
  }

  .o-chi-text-container {
    margin-top: 40px;
    margin-bottom: 80px;
    width: 100%;
  }

  .o-chi-col {
    width: 100%;
    margin: 0;
  }

  .o-chi__history {
    display: none;
  }

  .o-story-first-section {
    padding-top: 0;
    .c-image-carousel {
      margin-top: 0;
      margin-bottom: 0;
    }
    .c-image-carousel__slider {
      top: 0;
    }
    .c-image-carousel__navigation {
      left: auto;
      right: 5%;
      top: -20px;
    }
  }

  .o-org-section-noi {
    padding-bottom: 0 !important;
  }
}

@include mq-max(560px) {
  .o-home-big-text {
    display: none;
  }

  .o-home-product-cta__first-leaf {
    left: 10%;
  }

  .o-home-product-cta__lemon, .o-home-product-cta__second-leaf {
    bottom: 0;
  }

  .o-home-product-cta__round {
    left: 25%;
    @include size(230px);
  }

  .o-home-product-cta {
    height: 430px;
  }
}

@include mq-max(500px) {
  .o-home-col--vending-float {
    margin-bottom: 100px;
  }

  .o-home-col--coffee-shop {
    margin-left: 0;
    margin-top: 60px;
  }

  .o-section {
    padding: 40px 0;
  }

  .o-section--with-float-b {
    padding-bottom: 140px;
  }
}

@include mq-max(490px) {
  .o-home-product-cta__strawberry {
    display: none;
  }

  .o-home-product-cta__second-leaf {
    right: 0;
  }

  .o-home-product-cta__round {
    left: 15%;
  }
}

@include mq-max(767px) {
  .o-csr-col {
    width: 100%;
  }

  .o-section--mobile-no-padding {
    padding: 0;
  }
}

// @include mq-max(1100px) {
//   .o-home-text-container {
//     width: 80%;
//   }
// }

// @include mq-max(1023px) {
//   .o-full-tablet {
//     width: 100% !important;
//   }

//   // .o-section {
//   //   padding: 40px 0;
//   // }

//   .o-chi__history-years {
//     line-height: 230px;
//     font-size: 130px;
//   }

//   .o-chi__history-description {
//     line-height: 29px;
//     font-size: 18px;
//     margin-top: 60px;
//   }

//   .o-chi-history-preview-col {
//     float: left;
//     clear: none;
//     width: 48.5% !important;
//     margin-left: 0;
//     margin-right: 3% !important;
//     &:last-child {
//       margin-right: 0 !important;
//     }
//   }

//   .o-story-desc {
//     margin-bottom: 45px;
//   }

//   .o-story-trophy {
//     margin-top: 45px;
//   }

//   .o-sede-lounge {
//     left: 20%;
//   }
// }

// @include mq-max(997px) {
//   .o-home-product-cta__first-more, .o-home-product-cta__second-more {
//     display: none;
//   }
// }

// @include mq-max(810px) {
//   .o-home-text-container {
//     margin-top: 0px;
//     width: 60%;
//   }
// }

// @include mq-max(767px) {
//   .o-home-text-container {
//     margin-top: 100px;
//     width: 40%;
//   }

//   .o-section {
//     padding: 30px 0;
//   }

//   .o-no-mobile, .o-home-coffee {
//     display: none;
//   }

//   .o-sede-lounge {
//     display: none;
//   }

//   .o-home-col, .o-chi-col, .o-story-col, .o-csr-col {
//     width: 100%;
//   }

//   .o-chi-col--vending-float {
//     top: auto;
//   }

//   .o-chi-text-container {
//     margin-bottom: 40px;
//   }

//   .o-story__trophy {
//     margin-bottom: 30px;
//   }

//   .o-small-coffee-ill--right {
//     float: none;
//   }

//   .o-chi__history {
//     margin-top: 60px;
//     margin-bottom: 60px;
//   }

//   .o-chi__history-years {
//     line-height: 200px;
//     font-size: 90px;
//   }
// }

// @include mq-max(570px) {
//   .o-home-text-container {
//     margin-top: 50px;
//     width: 100%;
//   }

//   .o-home-col {
//     width: 100%;
//     float: none;
//     margin:0;

//     .c-box-cta {
//       float: none;
//       margin-bottom: 250px;
//       margin-top: 80px;
//     }
//   }

//   .o-home-col--text-float {
//     top: 0;
//   }

//   .o-home-col--vending-float {
//     top: 0;
//     float: none;
//     margin: auto;
//   }

//   .o-chi-history-preview-col {
//     width: 100% !important;
//     margin-right: 0 !important;
//     &:last-child {
//       display: none;
//     }
//   }

//   .o-chi__history-info-cta {
//     margin: 0 auto;
//   }

//   .o-chi__history-tappo {
//     left: 0;
//   }

//   .o-chi__history-years {
//     line-height: 1;
//     font-size: 60px;
//   }

// }


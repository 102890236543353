.c-box-cta {
  position: relative;
  float: right;
}

.c-box-cta--interactive {
  cursor: pointer;
}

.c-box-cta__image {
  @include size(450px, 300px);
  @include bg();
  background-color: $dusty-gray;
  box-shadow: -31.4951px 20.9967px 94.4852px 0 rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 2;

  &:hover {
    .c-box-cta__video-overlay {
      opacity: 1;
    }
    .c-box-cta__video-button {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }
}

.c-box-cta__video-button {
  @include size(70px);
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  @include align();
  border: 2px solid $white;
  transform: translate(-50%, -65%);
  opacity: 0;
  transition: transform 0.3s ease(snappy), opacity 0.3s ease;
  z-index: 10;
  svg {
    display: block;
    margin: 0 auto;
    @include size(25px);
    path {
      fill: $white;
    }
  }
}

.c-box-cta__video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  @include size(100%);
  background: linear-gradient(180deg, #333333 0%, rgba(51, 51, 51, 0.5) 100%);
  transition: opacity 0.3s;
  z-index: 5;
}

.c-box-cta__content {
  @include size(400px);
  position: absolute;
  top: 70%;
  left: -30%;
  transform-style: preserve-3d;
  z-index: 1;

  &:hover {
    .c-box-cta__button {
      &:before, &:after {
        transform: scale(1);
      }

      &:after {
        transition-delay: 0.2s;
      }
    }
  }
}

.c-box-cta__square {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
  border: 10px solid $dusty-gray;
}

.c-box-cta__text {
  @include align();
  font-family: $font-serif;
  line-height: 90px;
  font-size: 90px;
  text-align: center;
  color: #E4E4E4;
}

.c-box-cta__inner-image {
  @include align();
  @include size(80%);
  @include bg();
  background-size: contain;
}

.c-box-cta__button {
  @include size(60px);
  position: absolute;
  cursor: pointer;
  bottom: -25px;
  right: 50px;
  border-radius: 50%;
  background-color: $dusty-gray;
  &:before, &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.3s ease(snappy) 0s;
    z-index: 1;
  }

  &:before {
    background-color: $white;
  }
}

.c-box-cta__button-icon {
  @include align();
  width: 80%;
  z-index: 10;
  svg {
    display: block;
    @include size(17px, 27px);
    margin: 0 auto;
    path {
      fill: $white;
    }
  }
}

.c-box-cta__button-text {
  position: absolute;
  bottom: -25px;
  left: 50%;
  width: 150%;
  text-align: center;
  transform: translate(-50%);
  font-family: $font-sans;
  line-height: 24px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.04em;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #88725D;

  a {
    color: #88725D;
  }
}

.c-box-cta__button-text-csr {
  font-size: 14px;
  bottom: -80px;
}

.c-box-cta--orange {
  .c-box-cta__square {
    border-color: #C48945;
  }

  .c-box-cta__button {
    background-color: #C48945;

    &:after {
      background-color: darken(#C48945, 10%);
    }
  }
}

.c-box-cta--red {
  .c-box-cta__square {
    border-color: $red;
  }

  .c-box-cta__button {
    background-color: $red;

    &:after {
      background-color: darken($red, 10%);
    }
  }
}

.c-box-cta--yellow {
  .c-box-cta__square {
    border-color: darken(#FDE2AD, 20%);
  }

  .c-box-cta__button {
    background-color: darken(#FDE2AD, 20%);

    &:after {
      background-color: darken(#FDE2AD, 40%);
    }
  }
}

.c-box-cta--blue {
  .c-box-cta__square {
    border-color: #7FA4B2;
  }

  .c-box-cta__button {
    background-color: #7FA4B2;

    &:after {
      background-color: darken(#7FA4B2, 10%);
    }
  }
}

.c-box-cta--green {
  .c-box-cta__square {
    border-color: #A6B2AA;
  }

  .c-box-cta__button {
    background-color: #A6B2AA;

    &:after {
      background-color: darken(#A6B2AA, 10%);
    }
  }
}

.o-general-box {
  @include size(300px);
  padding: 30px;
  display: flex;
  align-items: center;
  border: 10px solid $brown;
}

.o-general-box--center {
  margin: 0 auto;
}

.o-general-box__content {
  line-height: 27px;
  font-size: 20px;
  letter-spacing: 0.05em;
  font-weight: bold;
  text-transform: uppercase;
  color: #646464;
}

.o-general-box--olive {
  border-color:#8D9C40;
}

.o-general-box--green {
  border-color:#739872;
}

.o-general-box--red {
  border-color: $red;
}

.o-general-box--orange {
  border-color: $orange;
}

.o-general-box--aqua {
  border-color: #78CFEB;
}

.o-general-box--terra {
  border-color: #9B655E;
}

@include mq-max(1100px) {
  .c-box-cta__content {
    @include size(250px);
  }

  .c-box-cta__image {
    width: 350px;
    height: 200px;
  }

  .c-box-cta__text {
    line-height: 70px;
    font-size: 70px;
  }
}

@include mq-max(767px) {
  .c-box-cta {
    float: none;
  }

  .c-box-cta__image {
    margin: auto;
    position: relative;
    left: -40px;
  }

  .c-box-cta__content {
    top: 40px;
    left: calc(50% - 40px);
  }

  .c-box-cta__text {
    display: none;
  }

  .c-box-cta__inner-image {
    display: none;
  }
}

@include mq-max(460px) {
  .c-box-cta__image {
    left: 0;
  }

  .c-box-cta__content {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .c-box-cta__text {
    display: none;
  }
}

@include mq-max(385px) {
  .c-box-cta__image {
    width: 300px;
  }

}

// @include mq-max(570px) {
//   .c-box-cta {
//     margin-bottom: 110px;
//     float: none;
//   }
// }

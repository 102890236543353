$saturation: 40%;

.c-saturation__color {

  // sfondo principale pagina
  // ************************************************
  background-color: darken($body-bg, 0% + $saturation);

  .o-cookie-bar__main {
    background-color: darken(#E4E0E0, 0% + $saturation);
  }
  .o-cookie-bar__main-text, .o-cookie-bar__main-link {
    color: lighten(#646464, 0% + $saturation);
  }
  .c-loader {
    background-color: darken(#ffffff, 0% + $saturation);
  }

  // header
  // ************************************************
  .c-nav {
    background-color: darken($alabaster, 0% + $saturation);
  }
  .c-subnav__bg {
    background-color: darken(#F3F1EC, 0% + $saturation);
  }
  .c-nav__main-link-submenu {
    background-color: darken(#ffffff, 0% + $saturation);

    &:before {
      border-color: transparent transparent darken(#ffffff, 0% + $saturation) transparent
    }
  }
  .c-assistance-modal__bg {
    background-color: darken(#EDE8DF, 0% + $saturation);
  }
  .c-assistance-modal__icon {
    svg, path, g {
      fill: darken(#E6E1D8, 0% + $saturation);
    }
  }
  .c-nav__main-link {
    color: lighten(#88725D, 0% + $saturation);

    &:before {
      background-color: darken(#e7e0d9, 0% + $saturation);
    }
  }
  .c-nav__main-link-icon {
    color: lighten(#88725D, 0% + $saturation)
  }
  .c-nav__main-link-submenu-link {
    color: lighten(#88725D, 0% + $saturation);
    &:before {
      background-color: darken(#e7e0d9, 0% + $saturation);
    }
  }
  .c-nav__action-bubble {
    border: 1px solid lighten(#80BC00, 0% + $saturation);

    svg path {
      fill: lighten(#fff, 0% + $saturation);
    }

    &:after {
      background-color: lighten(#80BC00, 0% + $saturation);
    }

    &:hover {
      svg path {
        fill: #fff;
      }
    }

    &--active {
      svg path {
        fill: #fff;
      }

      &:after {
        background-color: lighten(#80BC00, 0%) !important;
      }
    }
  }
  .c-subnav__support-link {
    color: lighten(#646464, 0% + $saturation);
  }

  // hero
  // ************************************************
  .c-hero__scroll-down {
    background-color: darken($white, 0% + $saturation);

    &:before {
      background-color: darken($brown, 10%);
    }
  
    &:after {
      background-color: darken($brown, 15%);
    }
  }
  .c-hero__float-content-cta {
    background-color: darken($white, 0% + $saturation);
  }
  .c-hero__float-content-title, .c-hero__float-content-text {
    color: darken($white, 0% + $saturation);
  }
  .c-hero-float__slide {
    &:after {
      background-color: darken($white, 0% + $saturation);
    }
  }
  .c-hero-float__slide--orange {
    background-color: darken($orange, 0% + $saturation);
    .c-hero__float-content-cta {
      svg path {
        fill: darken($orange, 0% + $saturation);
      }
    }
  
    .c-hero__float-content-cta {
      &:before {
        background-color: darken($orange, 0% + $saturation);
      }
      &:after {
        background-color: darken($orange, 10% + $saturation);
      }
    }
    &:hover {
      .c-hero__float-content-title, .c-hero__float-content-text {
        color: darken($orange, 0% + $saturation);
      }
      svg path {
        fill: $white;
      }
    }
  }
  .c-hero-float__slide--brown {
    background-color: darken($brown, 10%);
    .c-hero__float-content-cta {
      svg path {
        fill: darken($brown, 10%);
      }
    }
  
    .c-hero__float-content-cta {
      &:before {
        background-color: darken($brown, 10%);
      }
      &:after {
        background-color: darken($brown, 10%);
      }
    }
    &:hover {
      .c-hero__float-content-title, .c-hero__float-content-text {
        color: darken($brown, 10%);
      }
      svg path {
        fill: $white;
      }
    }
  }
  .c-hero-float__slide--pink {
    background-color: darken($pink, 0% + $saturation);
    .c-hero__float-content-cta {
      svg path {
        fill: darken($pink, 0% + $saturation);
      }
    }
  
    .c-hero__float-content-cta {
      &:before {
        background-color: darken($pink, 0% + $saturation);
      }
      &:after {
        background-color: darken($pink, 0% + $saturation);
      }
    }
    &:hover {
      .c-hero__float-content-title, .c-hero__float-content-text {
        color: darken($pink, 0% + $saturation);
      }
      svg path {
        fill: $white;
      }
    }
  }
  .c-hero__bg-light-brown {
    background-color: darken(#A4917F, 0% + $saturation);
  }
  .c-hero__float-content--brown {
    background-color: darken(#88725D, 10%);

    &:after {
      background-color: darken(#ffffff, 0% + $saturation);
    }
  }
  .c-hero__bg--red {
    background-color: darken(#E76A5C, 0% + $saturation);
  }
  .c-hero__bg--green {
    background-color: darken(#739872, 0% + $saturation);
  }
  .c-hero__bg--olive {
    background-color: darken(#B2BE70, 0% + $saturation);
  }
  .c-hero__scroll-down-icon {
    svg path {
      fill: darken(#88725D, 10%);
    }
  }

  // home -> box think con video
  // ************************************************
  .o-section-main-text {
    color: lighten(#646464, 0% + $saturation);
  }
  .c-box-cta__text {
    color: darken(#E4E4E4, 0% + $saturation) !important;
  }
  .o-big-title {
    color: rgba(136, 114, 93, 0.32);
  }
  .o-big-subtitle {
    color: rgba(136, 114, 93, 0.35);
  }
  .o-section-small-title--orange {
    color: lighten(#E7A55F, 10%);
  }
  .o-section-small-text {
    color: #cacaca;

    b, strong {
      color: #cacaca;
    }
  }
  .c-box-cta__button-text {
    color: lighten(#88725D, 0% + $saturation);
  }

  // home -> box coffee shop
  // ************************************************
  .o-section--light-brown {
    background-color: darken($pampas, 0% + $saturation);
  }
  .c-main-carousel__nav {
    background-color: darken($white, 0% + $saturation);

    &:after {
      background-color: darken($white, 0% + $saturation);
    }
  }
  .c-main-carousel__bg {
    background-color: darken($earth, 0% + $saturation);
  }
  .c-button--fill-red {
    background-color: darken($red, 0% + $saturation);
    &:after {
      background-color: darken($red, 5% + $saturation);
    }
  }
  .o-section-main-title {
    color: lighten(#646464, 0% + $saturation);
  }
  .o-section-text {
    color: lighten(#646464, 0% + $saturation);
  }
  .o-section-main-divider {
    background-color: #cacaca;
  }
  .c-main-carousel__main-title {
    color: ligthen(#88725D, 0% + $saturation);
  }
  .c-main-carousel__main-subtitle {
    color: ligthen(#88725D, 0% + $saturation);
  }
  .c-main-carousel__fixed-text {
    color: lighten(#646464, 0% + $saturation);
  }

  // home -> every break
  // ************************************************
  .o-home-product-cta__round {
    background-color: darken(#729971, 0% + $saturation);
  }
  .c-button-round--olive {
    background-color: darken($olive, 0% + $saturation);
  
    &:after {
      background-color: darken($olive, 15% + $saturation);
    }
  }
  .o-quote__text {
    color: lighten(#646464, 0% + $saturation);
  }
  .o-quote__author {
    color: darken(#9E8B8B, 0% + $saturation);
  }

  // home -> history
  // ************************************************
  .o-chi__history-years {
    color: darken(#EDE8DF, 0% + $saturation);
  }
  .o-chi__history-info-cta {
    background-color: darken(#E7A55F, 0% + $saturation);
  }
  .c-button-round--orange {
    background-color: darken($orange, 0% + $saturation);
  
    &:after {
      background-color: darken($orange, 15% + $saturation);
    }
  }

  // home -> sezione csr
  // ************************************************
  .c-main-block--olive {
    background-color: darken(#B2BE70, 0% + $saturation);

    .c-main-block__main-title {
      color: lighten(#8e9b46, 0% + $saturation);
    }
  }
  .o-arrow-cta__text {
    color: lighten(#88725D, 0% + $saturation);

    &:before {
      background-color: darken(#e7e0d9, 0% + $saturation);
    }
  }
  .o-arrow-cta__icon {
    svg {
      path {
        fill: lighten(#88725D, 0% + $saturation);
      }
    }
  }
  .o-home-big-text {
    color: rgba(136, 114, 93, 0.5);
  }

  // home -> slider progetti
  // ************************************************
  .c-projects-slider__nav {
    background-color: darken($white, 0% + $saturation);

    &:after {
      background-color: darken($white, 0% + $saturation);
    }
  }
  .c-button-round--white {
    background-color: darken($white, 0% + $saturation);
  }

  // home -> blocco di contatto
  // ************************************************
  .o-contact-block__info-cta {
    background-color: darken(#E7A55F, 0% + $saturation);
  }

  // home -> sezione gioco
  // ************************************************
  .c-quiz__question-container {
    background-color: darken(#5A9CB8, 0% + $saturation);
  }
  .c-quiz__answers-container {
    background-color: darken(#AAD2E3, 0% + $saturation);
  }
  .c-quiz__question-cta {
    background-color: darken(#4B839C, 0% + $saturation);
  }

  // home -> blocco assistenza
  // ************************************************
  .o-assistance-block {
    background-color: darken(#EDE8DF, 0% + $saturation);
  }
  .o-assistance-block__phone-block {
    background-color: darken(#C7B7A6, 0% + $saturation);
  }
  .o-assistance-block__ill {
    svg, path, g {
      fill: darken(#E6E1D8, 0% + $saturation);
    }
  }
  .o-assistance-block__phone-block-icon {
    svg, path, g {
      fill: darken(#B7A899, 0% + $saturation);
    }
  }
  .c-assistance-modal__close {
    border: 2px solid lighten(#9E8B8B, 0% + $saturation);

    &:hover .c-assistance-modal__close-text {
      color:#9E8B8B;
    }
  }
  .c-assistance-modal__close-text {
    color: lighten(#9E8B8B, 0% + $saturation);
  }
  .c-assistance-modal__close-circle {
    background-color: lighten(#9E8B8B, 0% + $saturation);
  }
  .c-assistance-modal__title, .c-assistance-modal__text, .c-assistance-modal__call-claim, .c-assistance-modal__call-number {
    color: lighten(#646464, 0% + $saturation);
  }

  // chia siamo vario

  .c-section__qt-quote {
    background-color: darken(#E76A5C, 0% + $saturation);
  }
  .c-image-carousel__main-title {
    color: lighten(#88725D, 0% + $saturation);
  }
  .c-video-preview--orange .c-video-preview__bg {
    background-color: darken(#E9A658, 0% + $saturation);
  }
  .c-video-preview__side-text {
    color: lighten(#646464, 0% + $saturation);
  }

  // Azienda tabs

  .c-tabs__item {
    color: lighten(#C7B7A7, 10%);
  }
  .c-tabs__item.is-active, .c-tabs__item:hover {
    color: ligten(#88725D, 10%);
  }

  // azienda -> palline statistiche
  // ************************************************
  .o-section__hero-text {
    color: lighten(#646464, 0% + $saturation);
  }
  .o-stats {
    border-color: darken(#769FB9, 0% + $saturation);
  }
  .o-stats__title, .o-stats__subtitle {
    color: darken(#769FB9, 0% + $saturation);
  }

  // azienda -> la flotta amica
  // ************************************************
  .msc__bg {
    background-color: darken(#E6E2C4, 0% + $saturation);
  }
  .msc__cta-text {
    color: darken(#E4E4E4, 0% + $saturation);
  }
  .c-button-round--green {
    background-color: rgba(71, 165, 120, 0.7); // TODO

    &:after {
      background-color: darken(#358c62, 0% + $saturation);
    }
  }

  // azienda -> linkedin
  // ************************************************
  .c-linkedin-section {
    background-color: darken(#ACC6D0, 0% + $saturation);
  }
  .c-linkedin-section__big-text {
    svg, path, g {
      fill: darken(#759FBA, 0% + $saturation);
    }
  }

  // history -> slider
  // ************************************************
  .c-image-carousel__navigation:after {
    background-color: darken($white, 0% + $saturation);
  }

  // history -> timeline
  // ************************************************
  .c-timeline__ball {
    background-color: darken(#88725D, 0% + $saturation);

    &:hover {
      background-color: darken(#E76A5C, 0% + $saturation);
    }
  }

  // sede
  // ************************************************
  .c-image-carousel__main-content {
    background-color: darken(#C7B7A7, 0% + $saturation);
  }
  .o-sede-lounge {
    background: darken(#C7B7A6, 0% + $saturation);
  }
  .c-gsb__image-block {
    background: darken(#88725D, 0% + $saturation);
  }

  // organizzazione
  // ************************************************
  .msc__bg--brown {
    background-color: darken(#C7B7A7, 0% + $saturation);
  }

  // vending
  // ************************************************
  .c-solution-block__navigation {
    background-color: darken(white, 0% + $saturation);

    &:before {
      background-color: darken(white, 0% + $saturation);
    }
  }

  // prodotti
  // ************************************************
  .c-product-block__bg--blue {
    background-color: darken(#007BB5, 0% + $saturation);
    .c-product-block__icon {
      background-color: darken(#5eacd0, 0% + $saturation);
    }
  } 
  .c-product-block__bg--green {
    background-color: darken(#88BF7A, 0% + $saturation);
    .c-product-block__icon {
      background-color: darken(#b4d7ab, 0% + $saturation);
    }
  }
  .c-product-block__bg--brown {
    background-color: darken(#96643F, 0% + $saturation);
    .c-product-block__icon {
      background-color: darken(#bd9d86, 0% + $saturation);
    }
  }
  .c-product-block__bg--navy {
    background-color: darken(#385B7A, 0% + $saturation);
    .c-product-block__icon {
      background-color: darken(#8298ab, 0% + $saturation);
    }
  }
  .c-product-block__bg--red {
    background-color: darken(#E04C4C, 0% + $saturation);
    .c-product-block__icon {
      background-color: darken(#eb8e8e, 0% + $saturation);
    }
  }
  .c-product-block__bg--aqua {
    background-color: darken(#78CFEB, 0% + $saturation);
    .c-product-block__icon {
      background-color: darken(#aae1f2, 0% + $saturation);
    }
  }
  .c-section__qt-content {
    background-color: darken(#EDE8DF, 0% + $saturation);
  }
  .c-section__qt-quote--gray {
    background-color: darken(#A6A6B2, 0% + $saturation);
  }
  
  // CSR
  // ************************************************
  .o-csr-section__bg {
    background-color: darken(#B2BE70, 0% + $saturation);
  }
  .o-csr-section__title {
    color: darken(#8D9C40, 0% + $saturation);
  }
  .c-section__qt-quote--green {
    background-color: darken(#51BD89, 0% + $saturation);
  }
  .c-round-slider__ball--red {
    background-color: darken(#DE5757, 0% + $saturation);
  }
  .c-round-slider__ball--green {
    background-color: darken(#31B6BE, 0% + $saturation);
  }
  .c-round-slider__navigation {
    &:after {
      background-color: darken(white, 0% + $saturation);
    }
  }
  .o-csr-anima {
    background-color: darken(#D5D9DB, 0% + $saturation);
  }
  .c-section__qt-quote--orange {
    background-color: darken(#F7BD79, 0% + $saturation);
  }
  .c-gsb__image-block--olive {
    background-color: darken(#B2BE70, 0% + $saturation);
  }
  .c-gsb__image-block--blue {
    background-color: darken(#7488A1, 0% + $saturation);
  }
  .o-csr-section__subtitle {
    background-color: darken(#8D9C40, 0% + $saturation);
  }

  // progetti
  // ************************************************
  .c-project-block__bg {
    background-color: darken(#9E8B8B, 0% + $saturation);
  }
  .c-project-block__cta-icon {
    background-color: darken(#9E8B8B, 0% + $saturation);
  }
  .c-project-block--green .c-project-block__bg, .c-project-block--green .c-project-block__cta-icon {
    background-color: darken(#51BD89, 0% + $saturation);
  }
  .c-project-block--orange .c-project-block__bg, .c-project-block--orange .c-project-block__cta-icon {
    background-color: darken(#F7BD79, 0% + $saturation);
  }
  .c-project-block--terra .c-project-block__bg, .c-project-block--terra .c-project-block__cta-icon {
    background-color: darken(#9B655E, 0% + $saturation);
  }

  // community
  // ************************************************
  .c-button-round--red {
    background-color: darken(#E76A5C, 0% + $saturation);
    &:after {
      background-color: darken(#d73220, 0% + $saturation);
    }
  }
  .c-community-dw__bg {
    background-color: rgba(217, 181, 160, 0.61); // TODO
  }
  .c-video-preview--green .c-video-preview__bg {
    background-color: darken(#C8D1C9, 0% + $saturation);
  }
  .c-video-preview--green .c-video-preview__main-title {
    color: darken(#A6B2AA, 0% + $saturation);
  }
  .c-cvideo__video-bg-container--orange {
    background-color: darken(#E9A658, 0% + $saturation);
  }

  // footer -> blocco contatti
  // ************************************************
  .o-contact-block {
    border-color: darken(#88725D, 10%);
  }
  .o-contact-block__claim {
    color: darken(#88725D, 10%);
  }
  .o-contact-block__title, .o-contact-block__text, .o-section-small-title {
    color: lighten(#646464, 0% + $saturation);
  }

  // footer -> 3 box
  // ************************************************
  .c-footer__box {
    background-color: darken(#eee, 0% + $saturation);
  }
  .c-footer__box-claim, .c-footer__box-text {
    color: lighten(#646464, 0% + $saturation);
  }
  .c-footer__overlay {
    background-color: darken(#C7B7A7, 0% + $saturation);
  }
  .c-footer__box--green .c-footer__overlay {
    background-color: darken(#86CEAC, 0% + $saturation);
  }
  .c-footer__box--red .c-footer__overlay {
    background-color: darken(#E76A5C, 0% + $saturation);
  }
  .c-footer__box-icon {
    svg, path, g {
      fill: darken(#DCDCDC, 0% + $saturation);
    }
  }
  .c-footer__text, .c-footer__link, .c-footer__sub-link {
    color: lighten(#646464, 0% + $saturation);

    a {
      color: lighten(#646464, 0% + $saturation);
    }

    &:before {
      background-color: darken(#e7e0d9, 0% + $saturation);
    }
  }
  .c-footer__assistance-button-text {
    color: lighten(#646464, 0% + $saturation);
  }
  .c-quiz__question-claim {
    color: darken(#ffffff, 0% + $saturation);
  }
  .c-quiz__question-text {
    color: darken(#ffffff, 0% + $saturation);
  }
  .c-quiz__question-cta {
    color: darken(#eee, 0% + $saturation);
  }
  .c-hero__title {
    color: darken(#ffffff, 0% + $saturation);
  } 
  .c-hero__claim {
    color: darken(#E1DCD7, 0% + $saturation);
  }
  .c-products__index-block-icon path {
    fill: darken(#ffffff, 0% + $saturation);
  }
  .c-projects-slider__main-title {
    color: darken(#E1DCD7, 0% + $saturation);
  }
  .c-projects-slider__main-text {
    color: darken(#E1DCD7, 0% + $saturation);
  }
  .c-product-block__bg {
    color: darken(#ffffff, 0% + $saturation);
  }
  .c-product-block__icon-content  path {
    fill: darken(#ffffff, 0% + $saturation);
  }
  .c-section__qt-quote-text {
    color: darken(#EDE8DF, 0% + $saturation);
  }
  .c-round-slider__text {
    color: darken(#ffffff, 0% + $saturation);
  }
  .o-contact-block__info-cta-icon, .msc__cta-button, .c-button-round {
    path {
      fill: darken(#EDE8DF, 0% + $saturation);
    }
  } 
  .o-contact-block__info-cta-title {
    color: darken(#EDE8DF, 0% + $saturation);
  }
  .c-button-round--orange path {
    fill: darken(#fff, 0% + $saturation);
  }
  .c-contacts__recap-title {
    color: darken(#ffffff, 0% + $saturation);
  }
  .c-contacts__recap-text, .c-contacts__recap-text a {
    color: darken(#ffffff, 0% + $saturation);
  }
  .c-hero__scroll-down:hover .c-hero__scroll-down-icon svg path {
    fill: darken(#fff, 0% + $saturation);
  }
  .c-footer__social-link:hover .c-footer__social-icon svg path {
    fill: darken(#fff, 0% + $saturation);
  }
  .c-loader__overlay {
    background-color: darken(rgba(217, 181, 160, 0.61), 0% + $saturation);
  }
  .c-page-transition {
    background-color: darken(#fff, 0% + $saturation);
  }
  .c-page-transition__logo-overlay {
    background-color: darken(#fff, 0% + $saturation);
  }
}
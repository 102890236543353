.c-breadcrumb {
  float: left;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-right: 16px;
  transition: all 0.3s;
  opacity: 0;
  transform: translateY(-12px);
  &:after {
    content: "|";
    display: inline-block;
    margin-left: 16px;
    color: mix($dust, $white, 40%);
  }
}

.c-breadcrumb__item {
  display: inline-block;
  margin-right: 8px;
  &:after {
    content: ">";
    display: inline-block;
    margin-left: 8px;
    color: $dust;
  }

  &:last-child {
    margin-right: 0;
    &:after {
      content: "";
      display: none;
    }
    .c-breadcrumb__link {
      &:before {
        transform: scaleX(1);
        transform-origin: 0 100%;
      }
    }
  }
}

.c-breadcrumb__link {
  font-size: 14px;
  color: $dust;
  letter-spacing: 1px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px;
    transform: scaleX(0);
    background-color: mix($earth, $white, 43%);
    transform-origin: 100% 100%;
    transition: transform 0.3s ease(snappy);
    z-index: -1;
  }

  &:hover {
    &:before {
      transform: scaleX(1);
      transform-origin: 0 100%;
    }
  }
}

.c-contacts {
  padding-top: 160px;
}

.c-contacts__recap-title {
  line-height: 34px;
  font-size: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #FFFFFF;
  font-weight: bold;
  margin-top: 65px;
}

.c-contacts__recap-text {
  line-height: 30px;
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  a {
    color: $white;
  }
}

.c-contacts__main-col {
  @include col(1/2);
  position: relative;
}

.c-contacts__main-text {
  line-height: 36px;
  font-size: 22px;
  letter-spacing: 0.05em;
  color: rgba(100, 100, 100, 0.9);
}

.c-contacts-boxes {
  margin-top: 120px;
}

.c-contacts-box {
  @include col(1/3, $cycle: 3);
  border: 7px solid #cbbbac;
  backface-visibility: hidden;
  position: relative;
  margin-bottom: 40px;
  &:before, &:after {
    content: "";
    position: absolute;
    top: -7px;
    left: -7px;
    transition: all 0.3s;
  }
  &:before {
    width: 0%;
    height: calc(100% + 14px);
    border-top: 7px solid $brown;
    border-bottom: 7px solid $brown;
  }
  &:after {
    height: 0%;
    width: calc(100% + 14px);
    border-left: 7px solid $brown;
    border-right: 7px solid $brown;
  }
}

.c-contacts-box__content {
  @include align();
  width: 80%;
  text-align: center;
  transition: transform 0.3s ease(snappy);
}

.c-contacts-box__title {
  font-family: $font-serif;
  line-height: 49px;
  font-size: 42px;
  text-align: center;
  margin-bottom: 14px;
  color: #88725D;
}

.c-contacts-box__text {
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #646464;
}

.c-contacts-box__cta {
  margin-top: 22px;
  opacity: 0;
  transform: scale(0.8);
  transition: all 0.3s;
}

.c-contacts-box:hover {
  &:before {
    width: calc(100% + 14px);
  }
  &:after {
    height: calc(100% + 14px);
  }

  .c-contacts-box__cta {
    opacity: 1;
    transform: scale(1);
  }
  .c-contacts-box__content {
    transform: translate(-50%, -50%) scale(1.1);
  }
}

.c-contacts-box--olive {
  &:before, &:after {
    border-color: $olive;
  }
}

.c-contacts-box--red {
  &:before, &:after {
    border-color: $red;
  }
}

.c-contacts-box--dark-green {
  &:before, &:after {
    border-color: $green;
  }
}

.c-contacts-box--yellow {
  &:before, &:after {
    border-color: $orange;
  }
}

.c-contacts-box--aqua {
  &:before, &:after {
    border-color: $moon-blue;
  }
}

@include mq-max(1023px) {
  .c-contacts {
    padding-top: 200px;
  }
  .c-contacts__main-text {
    font-size: 18px;
    line-height: 26px;
  }
  .c-contacts-box__cta {
    display: none;
  }
}

@include mq-max(767px) {
  .c-contacts__main-col {
    width: 100%;
  }
  .c-contacts__recap-text {
    margin-bottom: 20px;
  }

  .c-contacts-box {
    @include col(1/2, $uncycle: 3, $cycle: 2);
  }

  .c-contacts-box__title {
    font-size: 32px;
    line-height: 39px;
  }

  .c-contacts__main-text {
    font-size: 14px;
    line-height: 24px;
  }

  .c-contacts-boxes {
    margin-top: 60px;
  }

  .c-contacts {
    padding-top: 60px;
  }
}

@include mq-max(540px) {
  .c-contacts-box {
    width: 100%;
  }
}
